import { GetReportedEventsResponse, PutReportedEventRequest, ReportedEvent } from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';

const flowApiUrl = config.getApiUrl();

export const reportApi = {
  sendReports: async (reports: PutReportedEventRequest[]): Promise<boolean> => {
    const { data } = await hermes.put(`${flowApiUrl}/events`, { events: reports });
    return !!data;
  },
  getExecutionReports: async (executionId: string): Promise<ReportedEvent[]> => {
    const { data } = await hermes.get<GetReportedEventsResponse>(
      `${flowApiUrl}/events?flowExecutionId=${executionId}`,
      {
        fallback: { reportedEvents: [] },
      },
    );
    return data.reportedEvents;
  },
};
