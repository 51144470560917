import { Flex, Badge, Text } from '@mantine/core';
import classes from './DynamicTemplateDrawer.module.css';

export const testIds = {
  getTemplateItem: (id: string) => `dynamicContainerTemplateItem-${id}`,
  templateItemBadge: 'dynamicContainerTemplateItemBadge',
  templateItemText: 'dynamicContainerTemplateItemText',
};

interface TemplateItemProps {
  id: string;
  value: string;
  onClick: () => void;
}

export const TemplateItem = ({ id, value, onClick }: TemplateItemProps) => (
  <Flex
    align='center'
    px={20}
    py={24}
    className={classes.templateItem}
    onClick={onClick}
    data-testid={testIds.getTemplateItem(id)}
  >
    <Badge
      color='gray.2'
      mr='sm'
      radius='xl'
      classNames={{ root: '!h-[22px]' }}
      data-testid={testIds.templateItemBadge}
    />
    <Text size='lg' data-testid={testIds.templateItemText}>
      {value}
    </Text>
  </Flex>
);
