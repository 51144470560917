import { useState, useEffect } from 'react';
import { Button, Drawer, Divider, Text, ScrollArea } from '@mantine/core';
import { EventId } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';
import { names, useSpy } from 'services/espionage';
import { useUiEventStore, FilterValue, ValidationFilter } from 'stores/uiEvent';
import { useAppStore } from 'stores/app';
import { EventFilterItem } from './EventFilterItem/EventFilterItem';

export const testIds = {
  drawerContainer: 'filters-drawer-container',
  drawerContent: 'filters-drawer-content',
  closeDrawerBtn: 'filters-drawer-close',
  applyFiltersBtn: 'filters-apply',
  resetFiltersBtn: 'filters-reset',
};

const { LEFT_SECTION_WIDTH } = INSPECTION_PAGE_LANDSCAPE_PERCENTAGE;

interface FiltersDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const FiltersDrawer = ({ open, onClose }: FiltersDrawerProps) => {
  const { t } = useTranslation();
  const spy = useSpy();
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [values, setValues] = useState<FilterValue[]>([]);
  const { isLandscape } = useAppStore(['isLandscape']);
  const { setFilterValues, filterValues, filters } = useUiEventStore(['filters', 'filterValues', 'setFilterValues']);

  const toggleOpenFilter = (eventId: string) => () => {
    setOpenFilters((prevState) =>
      prevState.includes(eventId) ? prevState.filter((id) => id !== eventId) : [...prevState, eventId],
    );
  };

  const aggregateFilterValues = (data: FilterValue[] = []) => {
    if (data.length) {
      return data.reduce<Record<EventId, string[]>>((acc, { eventId, valueFilters, validationFilters }) => {
        acc[eventId] = [...validationFilters, ...valueFilters];
        return acc;
      }, {});
    }
    return [];
  };

  const close = () => {
    spy(names.InspectionList.FilterClose);
    setValues(filterValues);
    onClose();
  };

  const reset = () => {
    spy(names.InspectionList.FilterReset);
    setValues([]);
  };

  const apply = () => {
    const prevSelections = aggregateFilterValues(filterValues);
    const currentSelectios = aggregateFilterValues(values);
    spy(names.InspectionList.FilterApply, undefined, {
      old_filter_selection: prevSelections,
      new_filter_selection: currentSelectios,
    });
    setFilterValues(values);
    onClose();
  };

  const handleUpdateValues =
    (key: keyof FilterValue, eventId: string, eventFilter?: FilterValue) => (data: (string | ValidationFilter)[]) => {
      setValues((prevValues) =>
        eventFilter
          ? prevValues.map((item) => (item.eventId === eventId ? { ...item, [key]: data } : item))
          : [
              ...prevValues,
              {
                eventId,
                valueFilters: [],
                validationFilters: [],
                [key]: data,
              },
            ],
      );
    };

  useEffect(() => {
    if (open) spy(names.InspectionList.FilterDrawer);
  }, [open]);

  return (
    <Drawer.Root
      position={isLandscape ? 'right' : 'bottom'}
      size={isLandscape ? LEFT_SECTION_WIDTH : '90%'}
      opened={open}
      onClose={close}
      data-testid={testIds.drawerContainer}
    >
      <Drawer.Overlay />
      <Drawer.Content data-testid={testIds.drawerContent}>
        <Drawer.Header>
          <Drawer.CloseButton data-testid={testIds.closeDrawerBtn} ml={0} />
          <Text c='gray.6' size='lg' fw={500}>
            {t('inspection.filter.drawerTitle')}
          </Text>
          <Text c='blue.6' size='lg' fw={500} data-testid={testIds.resetFiltersBtn} onClick={reset}>
            {t('common.reset')}
          </Text>
        </Drawer.Header>
        <Divider size='xs' />
        <Drawer.Body className='flex flex-col h-[90%]'>
          <ScrollArea type='scroll' className='flex-1' mb='md'>
            {filters.map((filter) => {
              const eventFilter = values.find((item) => item.eventId === filter.eventId);
              return (
                <EventFilterItem
                  key={filter.eventId}
                  open={openFilters.includes(filter.eventId)}
                  filter={filter}
                  filterValues={eventFilter?.valueFilters || []}
                  validationFilterValues={eventFilter?.validationFilters || []}
                  onClick={toggleOpenFilter(filter.eventId)}
                  onFilterChanged={handleUpdateValues('valueFilters', filter.eventId, eventFilter)}
                  onValidationFilterChanged={handleUpdateValues('validationFilters', filter.eventId, eventFilter)}
                />
              );
            })}
          </ScrollArea>
          <Button size='md' fullWidth onClick={apply} data-testid={testIds.applyFiltersBtn}>
            {t('common.apply')}
          </Button>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
