import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { User, SessionId } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { useShallow } from 'zustand/react/shallow';
import { networkStore } from 'stores/network';
import { authApi } from './auth.api';

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'aiola-current-user';

interface AuthState {
  currentUser: User | null;
  sessionId: SessionId | null;
}

interface AuthActions {
  login: () => void;
  logout: () => Promise<void>;
  getCurrentUser: () => Promise<void>;
  createSession: () => Promise<void>;
  getSession: () => Promise<void>;
  reset: () => void;
}

const initialState: AuthState = {
  currentUser: null,
  sessionId: null,
};

export const authStore = create(
  immer<AuthState & AuthActions>((set, get) => ({
    ...initialState,
    login: () => {
      if (process.env.NODE_ENV === 'development') get().getCurrentUser();
      else authApi.login();
    },
    logout: async () => {
      try {
        await authApi.logout();
        localStorage.removeItem(CURRENT_USER_LOCAL_STORAGE_KEY);
        set((state) => {
          state.currentUser = null;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getCurrentUser: async () => {
      const { online } = networkStore.getState();
      try {
        if (online) {
          const user = await authApi.fetchCurrentUser();
          if (!user) throw new Error('User not found');
          localStorage.setItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
          set({ currentUser: user });
        } else {
          const user = localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY);
          set({ currentUser: user ? JSON.parse(user) : null });
        }
      } catch (error) {
        console.error(error);
        set({ currentUser: null });
      }
    },
    createSession: async () => {
      const response = await authApi.createSession();
      if (response?.sessionId) {
        set((state) => {
          state.sessionId = response.sessionId;
        });
      }
    },
    getSession: async () => {
      const response = await authApi.getSession();
      if (response?.sessionId) {
        set((state) => {
          state.sessionId = response.sessionId;
        });
      }
    },
    reset: () => set(initialState),
  })),
);

export const useAuthStore = createStoreHook({ store: authStore, useShallow });
