import { create, StateCreator } from 'zustand';
import { nanoid } from 'nanoid';
import { immer } from 'zustand/middleware/immer';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { enableMapSet } from 'immer';
import {
  Container,
  ContainerId,
  ContainerTemplate,
  ContainerTemplateId,
  ExecutionId,
  DynamicContainer,
  PutContainersDoneRequest,
  GeneratedSource,
  GenerationMethod,
} from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { focusStore } from 'stores/focus';
import { flowStore } from 'stores/flow';
import { db } from 'services/db';
import { ChildrenToParentMap, ContainersMap, PutDynamicContainerBody } from './container.types';
import {
  buildUIContainer,
  childToParentMap,
  getAllParentsRecursively,
  getDynamicContainersCountByTemplateId,
} from './container.utils';

enableMapSet();

const devtoolsOptions: DevtoolsOptions = {
  name: 'containers',
  store: 'containers',
  enabled: process.env.NODE_ENV === 'development',
};

interface ContainerDataSliceActions {
  setContainersData: (
    rootContainerIds: ContainerId[],
    containers: Record<ContainerId, Container>,
    containerTemplates?: Record<ContainerTemplateId, ContainerTemplate>,
    dynamicContainerTemplateIds?: ContainerTemplateId[],
  ) => void;
  setContainers: (containers: ContainersMap) => void;
  setRootContainersIds: (rootContainersIds: ContainerTemplateId[]) => void;
  setContainerTemplates: (containerTemplates?: Record<ContainerTemplateId, ContainerTemplate>) => void;
  setDynamicContainerTemplateIds: (dynamicContainerTemplateIds?: ContainerTemplateId[]) => void;
  createDynamicContainer: (templateId: ContainerTemplateId, flowExecutionId: ExecutionId) => Promise<boolean>;
  removeContainer: (containerId: ContainerId) => void;
  onContainerAdded: (dynamicContainer: PutDynamicContainerBody | DynamicContainer) => Promise<void>;
  finishContainerEditing: (containerScheme: PutContainersDoneRequest) => void;
}

interface ContainerDataSliceState {
  containers: ContainersMap;
  rootContainerIds: string[];
  containerTemplates: Record<ContainerTemplateId, ContainerTemplate>;
  dynamicContainerTemplateIds: ContainerTemplateId[];
}

interface ContainerUISliceActions {
  toggleParentContainerOpen: (containerId: string) => void;
  openContainerParents: (containerId: string) => void;
  openContainerLog: (containerId: string) => void;
  closeContainerLog: () => void;
  openFilters(): void;
  closeFilters(): void;
  openTemplatesList(): void;
  closeTemplatesList(): void;
  closeAndClear: () => void;
}

interface ContainerUISliceState {
  openedLogContainerId: string | undefined;
  childParentMap: ChildrenToParentMap;
  openParentContainersMap: Set<string>;
  isFiltersOpen: boolean;
  isTemplatesListOpen: boolean;
}

type ContainerState = ContainerDataSliceState &
  ContainerDataSliceActions &
  ContainerUISliceState &
  ContainerUISliceActions & { reset: () => void };

const dataSliceInitialState: ContainerDataSliceState = {
  containers: {},
  rootContainerIds: [],
  containerTemplates: {},
  dynamicContainerTemplateIds: [],
};
const containerDataSlice: StateCreator<
  ContainerState,
  [['zustand/immer', never]],
  [],
  ContainerDataSliceState & ContainerDataSliceActions
> = (set, get) => ({
  ...dataSliceInitialState,
  setContainersData: (rootContainerIds, containers, containerTemplates, dynamicContainerTemplateIds) => {
    const { setRootContainersIds, setContainers, setContainerTemplates, setDynamicContainerTemplateIds } = get();
    const uiContainerMap: ContainersMap = Object.values(containers)
      .map((container) => {
        const containerTemplate = containerTemplates?.[container.templateId];
        return buildUIContainer(container, containerTemplate);
      })
      .reduce((acc, current) => ({ ...acc, [current.id]: current }), {});
    setDynamicContainerTemplateIds(dynamicContainerTemplateIds);
    setContainerTemplates(containerTemplates);
    setRootContainersIds(rootContainerIds);
    setContainers(uiContainerMap);
  },
  setContainers: (containers: ContainersMap) => {
    set({ containers, childParentMap: childToParentMap(containers) });
  },
  setRootContainersIds: (rootContainerIds: string[]) => set({ rootContainerIds }),
  setContainerTemplates: (containerTemplates = {}) => set({ containerTemplates }),
  setDynamicContainerTemplateIds: (dynamicContainerTemplateIds = []) => set({ dynamicContainerTemplateIds }),
  createDynamicContainer: async (templateId, flowExecutionId) => {
    const { containerTemplates, containers, onContainerAdded } = get();
    const dynamicContainerIndex = getDynamicContainersCountByTemplateId(Object.values(containers), templateId);
    const newContainerId = nanoid();
    const dynamicContainerData: PutDynamicContainerBody = {
      id: newContainerId,
      title: `${containerTemplates[templateId]?.typeTitle} ${dynamicContainerIndex + 1}`,
      creationTimestampClient: Date.now(),
      templateId,
      flowExecutionId,
      generatedSource: GeneratedSource.UI,
      generationMethod: GenerationMethod.USER_ACTION,
    };
    await onContainerAdded(dynamicContainerData);
    focusStore.getState().focusContainer(newContainerId);
    await db.storePendingAction({
      type: 'putDynamicContainers',
      payload: {
        dynamicContainers: [dynamicContainerData],
      },
    });
    return true;
  },
  onContainerAdded: async (dynamicContainer) => {
    const { currentExecutionId } = flowStore.getState();
    const isDifferentExecution = dynamicContainer.flowExecutionId !== currentExecutionId;
    const { id, templateId, creationTimestampClient } = dynamicContainer;
    const { containerTemplates, rootContainerIds, containers } = get();
    const containerTemplate = containerTemplates[templateId];
    const containerAlreadyExists = rootContainerIds.includes(id) || containers[id];
    if (isDifferentExecution || !containerTemplate || containerAlreadyExists) return;
    const newContainer = buildUIContainer(
      {
        ...dynamicContainer,
        order: 0 - creationTimestampClient,
      },
      containerTemplate,
    );
    await db.storeDynamicContainers(currentExecutionId, [newContainer]);
    set((state) => {
      state.containers[id] = newContainer;
      state.rootContainerIds.unshift(id);
    });
  },
  finishContainerEditing: (containerScheme) => {
    db.storePendingAction({
      type: 'Done',
      payload: containerScheme,
    });
  },
  removeContainer: (containerId) => {
    const { rootContainerIds, containers } = get();
    if (!containers[containerId]) return;
    set((state) => {
      state.rootContainerIds = rootContainerIds.filter((id) => id !== containerId);
      delete state.containers[containerId];
    });
  },
});

const uiSliceInitialState: ContainerUISliceState = {
  openedLogContainerId: undefined,
  childParentMap: {},
  openParentContainersMap: new Set(),
  isFiltersOpen: false,
  isTemplatesListOpen: false,
};

const containerUISlice: StateCreator<
  ContainerState,
  [['zustand/immer', never]],
  [],
  ContainerUISliceState & ContainerUISliceActions
> = (set, get) => ({
  ...uiSliceInitialState,
  toggleParentContainerOpen: (containerId: string) => {
    const { openParentContainersMap } = get();
    if (openParentContainersMap.has(containerId)) {
      set((state) => {
        state.openParentContainersMap.delete(containerId);
      });
    } else {
      set((state) => {
        state.openParentContainersMap.add(containerId);
      });
    }
  },
  openContainerParents: (containerId) => {
    const parents = getAllParentsRecursively(get().childParentMap, containerId);
    set((state) => {
      const container = state.containers[containerId];
      parents.forEach((id) => state.openParentContainersMap.add(id));
      if (container.childrenIds?.length) {
        state.openParentContainersMap.add(containerId);
      }
    });
  },
  openContainerLog: (containerId: string) => {
    set({ openedLogContainerId: containerId });
  },
  closeContainerLog: () => set({ openedLogContainerId: undefined }),
  openFilters: () => set({ isFiltersOpen: true }),
  closeFilters: () => set({ isFiltersOpen: false }),
  openTemplatesList: () => set({ isTemplatesListOpen: true }),
  closeTemplatesList: () => set({ isTemplatesListOpen: false }),
  closeAndClear: () => {
    focusStore.getState().blurContainer();
    set((state) => {
      state.openParentContainersMap.clear();
    });
  },
});

export const containerStore = create(
  devtools(
    immer<ContainerState>((...args) => ({
      ...containerDataSlice(...args),
      ...containerUISlice(...args),
      reset: () => {
        const [set] = args;
        set({ ...dataSliceInitialState, ...uiSliceInitialState });
      },
    })),
    devtoolsOptions,
  ),
);

export const useContainerStore = createStoreHook({ store: containerStore, useShallow });
