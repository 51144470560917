import { nanoid } from 'nanoid';
import { User, UserRole } from '@flow/flow-backend-types';

export const getMockUser = (overrides?: Partial<User>): User => ({
  userId: nanoid(),
  preferredUsername: 'Vlad the Impaler',
  name: 'Vlad Dracula',
  givenName: 'Vlad',
  familyName: 'Dracula',
  email: 'iluvblood@gmail.com',
  roles: [UserRole.USER],
  ...overrides,
});

export const MOCK_USER = getMockUser();
