import {
  ExecutionId,
  FlowId,
  GetRenderModelResponse,
  PutDataSyncRequest,
  PutDataSyncResponse,
} from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';

export const appApi = {
  fetchRenderModel: async (
    flowId: FlowId,
    version: string,
    flowExecutionId?: ExecutionId,
  ): Promise<GetRenderModelResponse | undefined> => {
    const url = `${config.getApiUrl()}/flows/${flowId}/${version}/render-model${
      flowExecutionId ? `?flowExecutionId=${flowExecutionId}` : ''
    }`;
    const { data } = await hermes.get<GetRenderModelResponse>(url);
    return data;
  },
  syncPendingActions: async (syncRequest: PutDataSyncRequest): Promise<PutDataSyncResponse> => {
    const { data } = await hermes.post<PutDataSyncResponse>(`${config.getApiUrl()}/sync-bulk`, syncRequest, {
      config: {
        timeout: 30000,
      },
    });
    return data;
  },
};
