import { useEffect } from 'react';
import { ActionIcon, Flex, Title, useMantineTheme } from '@mantine/core';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { IconMicrophone } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useVoiceStore } from 'stores/voice';
import useDisableButton from 'hooks/useDisableButton';

export const testIds = {
  pauseIcon: 'pause-icon',
  standByGuide: 'stand-by-guide',
  standByMessage: 'stand-by-message',
};

export const Standby = () => {
  const { colors, primaryColor } = useMantineTheme();
  const { close } = useVoiceStore(['close']);
  const [disabled, disableButton] = useDisableButton(1000);
  const { t } = useTranslation();
  const spy = useSpy();

  useEffect(() => spy(names.AiolaWidget.ChangeStatus, BiEventValueStatus.STANDBY), []);

  const onClose = () => {
    disableButton();
    spy(names.AiolaWidget.Deactivated);
    close();
  };

  return (
    <Flex className='flex-1' align='center' justify='center' pos='relative' gap={60}>
      <ActionIcon
        pos='absolute'
        bg='white'
        left={0}
        size={48}
        radius='xl'
        variant='filled'
        className='shadow-md'
        disabled={disabled}
        onClick={onClose}
        data-testid={testIds.pauseIcon}
      >
        <IconMicrophone color={colors[primaryColor][5]} />
      </ActionIcon>
      <Flex direction='column' align='center'>
        <Title order={4} fw={600} c='white' data-testid={testIds.standByMessage}>
          {t('voice.standbyMessage')}
        </Title>
        <Title order={6} c='gray.1' fw={400} mt={-4} data-testid={testIds.standByGuide}>
          {t('voice.standbyGuide')}
        </Title>
      </Flex>
    </Flex>
  );
};
