import { useEffect } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useAppStore } from 'stores/app';
import { LANDSCAPE_MEDIA_QUERY_RANGE } from 'consts';
import { DeviceOrientation } from 'stores/app/app.types';

export const useOrientationListener = () => {
  const isLandscapeWidth = useMediaQuery(LANDSCAPE_MEDIA_QUERY_RANGE);
  const { setDeviceOrientation } = useAppStore(['setDeviceOrientation']);
  const { Landscape, Portrait } = DeviceOrientation;

  useEffect(() => {
    setDeviceOrientation(isLandscapeWidth ? Landscape : Portrait);
  }, [isLandscapeWidth]);
};
