import { Chip, Flex } from '@mantine/core';
import { EventId } from '@flow/flow-backend-types';
import { IconX } from '@tabler/icons-react';
import { ValidationFilter } from 'stores/uiEvent';
import { useTranslation } from 'react-i18next';
import classes from '../EventItem.module.css';

export const testIds = {
  getEventValidationFilter: (groupId: string) => `event-Validation-filter-${groupId}`,
  getEventValidationFilterItem: (filterId: ValidationFilter) => `event-Validation-filter-item-${filterId}`,
  filterRegex: /^event-Validation-filter-item/,
};

interface EventValidationFilterProps {
  eventId: EventId;
  eventHasValidations: boolean;
  validationFilterValues: ValidationFilter[];
  onFilterChanged: (values: ValidationFilter[]) => void;
}

const STATUS_OPTIONS = [ValidationFilter.Checked, ValidationFilter.Unchecked];
const VALIDATION_OPTIONS = [ValidationFilter.Valid, ValidationFilter.Invalid];

export const EventValidationFilter = ({
  eventId,
  eventHasValidations,
  validationFilterValues,
  onFilterChanged,
}: EventValidationFilterProps) => {
  const { t } = useTranslation();

  const eventValidationOptions = eventHasValidations ? [...VALIDATION_OPTIONS, ...STATUS_OPTIONS] : STATUS_OPTIONS;

  return (
    <Flex gap='sm' wrap='wrap' data-testid={testIds.getEventValidationFilter(eventId)}>
      <Chip.Group
        multiple
        value={validationFilterValues}
        onChange={(values) => onFilterChanged(values as ValidationFilter[])}
      >
        {eventValidationOptions.map((value) => (
          <Chip
            key={value}
            className={classes.item}
            radius='md'
            size='lg'
            icon={<IconX size={20} />}
            value={value}
            data-testid={testIds.getEventValidationFilterItem(value)}
          >
            {t(`inspection.filter.validationFilter.${value}`)}
          </Chip>
        ))}
      </Chip.Group>
    </Flex>
  );
};
