import { Flex, Button, Text, Badge } from '@mantine/core';
import { IconFilter, IconPlus } from '@tabler/icons-react';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUiEventStore } from 'stores/uiEvent';
import { names, useSpy } from 'services/espionage';
import { useContainerStore } from 'stores/container';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { useAppStore } from 'stores/app';

export const testIds = {
  createDynamicContainerBtn: 'create-dynamic-container-btn',
  filterBtn: 'filter-btn',
  filterCounter: 'filter-counter',
};

export const InspectionActionButtons = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const { filterValues } = useUiEventStore(['filterValues']);
  const { openFilters, openTemplatesList } = useContainerStore(['openFilters', 'openTemplatesList']);
  const { isDynamicContainers, hasMultipleDynamicContainers, createNewDynamicContainer } =
    useDynamicContainers(executionId);
  const filtersCount = useMemo(
    () =>
      filterValues.reduce(
        (acc, filterValue) => acc + (filterValue.valueFilters.length + filterValue.validationFilters.length),
        0,
      ),
    [filterValues],
  );
  const { t } = useTranslation();
  const spy = useSpy();

  const onClickFilter = () => {
    spy(names.InspectionList.FilterSelect);
    openFilters();
  };

  const onClickNewDynamicContainer = () => {
    spy(names.InspectionList.NewDynamicItemContainer, BiEventValueStatus.DC_ADD_NEW);
    if (hasMultipleDynamicContainers) openTemplatesList();
    else createNewDynamicContainer();
  };

  const displayCreateDynamicContainer = isDynamicContainers && !inspectionDataLoading;

  return (
    <Flex pb='lg' justify='space-between'>
      {displayCreateDynamicContainer && (
        <Button
          leftSection={<IconPlus size={16} />}
          variant='default'
          onClick={onClickNewDynamicContainer}
          data-testid={testIds.createDynamicContainerBtn}
        >
          <Text fw={500}>{t('inspection.reportIncidentBtn')}</Text>
        </Button>
      )}
      <Button
        leftSection={<IconFilter size={14} />}
        rightSection={
          !!filtersCount && (
            <Badge px={7} color='green.7' radius='xl' data-testid={testIds.filterCounter}>
              {filtersCount}
            </Badge>
          )
        }
        variant='default'
        onClick={onClickFilter}
        data-testid={testIds.filterBtn}
      >
        <Text size='lg' fw={500} tt='uppercase'>
          {t('inspection.filterBtn')}
        </Text>
      </Button>
    </Flex>
  );
};
