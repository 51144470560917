import { useMemo } from 'react';
import { Skeleton, Flex, FlexProps } from '@mantine/core';

export const testIds = {
  skeleton: 'skeleton',
  skeletonList: 'skeleton-list',
};

interface SkeletonListProps extends FlexProps {
  height?: number;
  itemHeight?: number;
}

export const SkeletonList = ({ height = 200, itemHeight = 40, gap = 10, ...props }: SkeletonListProps) => {
  const skeletonCount = Math.floor(height / itemHeight);

  const skeletonItems = useMemo(
    () =>
      [...Array(skeletonCount).keys()].map((idx) => (
        <Skeleton key={idx} height={itemHeight} data-testid={testIds.skeleton} />
      )),
    [skeletonCount],
  );

  return (
    <Flex direction='column' h={height} gap={gap} data-testid={testIds.skeletonList} {...props}>
      {skeletonItems}
    </Flex>
  );
};
