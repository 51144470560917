import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { osName } from 'react-device-detect';
import { NumberInput } from '@mantine/core';
import { NumericElementData, ReportValueType } from '@flow/flow-backend-types';
import { exists, getNumericRangeText, parseNumberIfValid } from 'utils';
import { getNumberColor, validateNumber } from 'stores/uiEvent';
import { getHotkeyHandler } from '@mantine/hooks';
import { InputEventProps } from '../types';

export const testIds = {
  getInputTestId: (id: string) => `numeric-event-input-${id}`,
  getEditButtonTestId: (id: string) => `numeric-event-edit-button-${id}`,
};

export const NumericEvent = ({
  title,
  disabled,
  reportKey,
  eventUiMeta,
  validation,
  isEditing,
  lastEventReport,
  triggerReport,
  triggerFocus,
  triggerBlur,
}: InputEventProps<NumericElementData>) => {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLInputElement>(null);
  const lastReportedValue = parseNumberIfValid(lastEventReport?.reportedValue);
  const [value, setValue] = useState<number | undefined>(lastReportedValue);
  const eventColor = getNumberColor(validation, value);

  const reportEvent = () => {
    const valid = validateNumber(value, validation);
    triggerReport(
      {
        reportedValue: exists(value) ? String(value) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      valid,
    );
  };

  const blurElement = () => targetRef.current?.blur();

  const onBlur = () => {
    reportEvent();
    triggerBlur(targetRef.current?.value);
  };

  const onChange = (newValue: string | number) => {
    setValue(parseNumberIfValid(String(newValue)));
  };

  useEffect(() => {
    if (!isEditing) setValue(lastReportedValue);
  }, [lastReportedValue]);

  return (
    <NumberInput
      key={reportKey}
      ref={targetRef}
      styles={{ input: { borderColor: eventColor } }}
      classNames={{ input: '!border-2 transition-colors' }}
      allowDecimal
      inputMode={osName === 'Android' ? 'numeric' : undefined}
      description={validation && getNumericRangeText(validation)}
      disabled={disabled}
      label={title}
      placeholder={eventUiMeta?.placeHolder ?? t('placeholders.number')}
      maxLength={10}
      decimalSeparator=','
      decimalScale={eventUiMeta?.decimalPrecision}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={triggerFocus}
      onKeyDown={getHotkeyHandler([['Enter', blurElement]])}
      data-testid={testIds.getInputTestId(reportKey)}
    />
  );
};
