import i18n from 'services/i18n';
import { VoiceErrorReason } from 'stores/voice';

export function getErrorMessage(error?: VoiceErrorReason): [string, string] {
  switch (error) {
    case VoiceErrorReason.UserPermissionDenied:
      return [i18n.t('voice.errors.browserPermissionDenied'), i18n.t('voice.errors.browserPermissionDeniedGuide')];
    case VoiceErrorReason.SystemPermissionDenied:
      return [i18n.t('voice.errors.osPermissionDenied'), i18n.t('voice.errors.osPermissionDeniedGuide')];
    case VoiceErrorReason.NetworkError:
      return [i18n.t('voice.errors.networkError'), i18n.t('voice.errors.networkErrorGuide')];
    case VoiceErrorReason.Other:
    default:
      return [i18n.t('voice.errors.voiceError'), i18n.t('voice.recordNotStarted')];
  }
}
