import { Chip, Flex, Stack, Title } from '@mantine/core';
import { ValueItem, EventId } from '@flow/flow-backend-types';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useUiEventStore } from 'stores/uiEvent';
import classes from '../EventItem.module.css';

export const testIds = {
  eventValuesFilterTitle: 'event-values-filter-title',
  getFilterItemTestId: (filterId: string) => `filter-item-${filterId}`,
  getFilterItemsGroupTestId: (groupId: string) => `filter-items-group-${groupId}`,
  filterRegex: /^filter-item-/,
};

interface GroupEventItemsProps {
  eventValues: ValueItem[];
  filterValues: string[];
  eventId: EventId;
  onFilterChanged: (values: string[]) => void;
}

export const GroupEventItems = ({ eventValues, filterValues, eventId, onFilterChanged }: GroupEventItemsProps) => {
  const { t } = useTranslation();
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const event = uiEvents[eventId];

  return (
    !!eventValues.length && (
      <Stack>
        <Title size={16} fw={500} data-testid={testIds.eventValuesFilterTitle}>
          {`${event.title} ${t('inspection.filter.valuesFilterTitle')}`}
        </Title>
        <Flex gap='sm' wrap='wrap' data-testid={testIds.getFilterItemsGroupTestId(eventId)}>
          <Chip.Group multiple value={filterValues} onChange={onFilterChanged}>
            {eventValues.map(({ valueId, title, valueName }) => (
              <Chip
                key={valueId}
                className={classes.item}
                radius='md'
                size='lg'
                icon={<IconX size={20} />}
                value={valueName}
                data-testid={testIds.getFilterItemTestId(valueId)}
              >
                {title}
              </Chip>
            ))}
          </Chip.Group>
        </Flex>
      </Stack>
    )
  );
};
