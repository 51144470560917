import { RouterProvider } from 'react-router-dom';
import { useCurrentUser } from 'stores/auth';
import { protectedRoutes } from './routes.protected';
import { publicRoutes } from './routes.public';

export const AppRoutes = () => {
  const currentUser = useCurrentUser();
  const router = currentUser ? protectedRoutes : publicRoutes;

  return <RouterProvider router={router} />;
};
