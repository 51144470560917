import { ActionIcon, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { IconMicrophoneOff } from '@tabler/icons-react';
import { useVoiceStore } from 'stores/voice';
import { useOnline } from 'stores/network';
import { getErrorMessage } from './VoiceError.utils';

export const testIds = {
  errorTitle: 'error-title',
  errorGuide: 'error-guide',
  recordErrBtn: 'record-error-btn',
};

export const VoiceError = () => {
  const { colors } = useMantineTheme();
  const { voiceError, activate } = useVoiceStore((state) => ({
    voiceError: state.voiceError,
    activate: state.activate,
  }));
  const online = useOnline();

  const [errorTitle, errorGuide] = getErrorMessage(voiceError);

  return (
    <Flex h='100%' gap={20} align='center'>
      <ActionIcon
        bg='white'
        size={48}
        radius='xl'
        variant='outline'
        className='shadow-md'
        disabled={!online}
        onClick={() => activate()}
        data-testid={testIds.recordErrBtn}
      >
        <IconMicrophoneOff color={colors.gray[6]} />
      </ActionIcon>
      <Flex direction='column'>
        <Title order={5}>
          <span data-testid={testIds.errorTitle}>{errorTitle}</span>
        </Title>
        <Text size='tiny' color='gray.1' data-testid={testIds.errorGuide}>
          {errorGuide}
        </Text>
      </Flex>
    </Flex>
  );
};
