import { Flex, Button } from '@mantine/core';
import { useState } from 'react';
import { IconCheck } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { BackHeaderButton } from 'components';
import { names, useSpy } from 'services/espionage';
import { ExecutionRouteParams, ROUTES } from 'routes/routes.config';
import { useAppStore } from 'stores/app';
import { LandscapeNavbar, PortraitExecutionDetails, PortraitNavbar } from 'layouts';
import { useFlowStore } from 'stores/flow';
import { useOnline } from 'stores/network';
import { modalManager } from 'services/modalManager';
import { testIds } from '../../SummaryPage';

interface SummaryNavbarProps {
  rating: number;
  comment?: string;
}

export const SummaryNavbar = ({ rating, comment }: SummaryNavbarProps) => {
  const [awaitingFinish, setAwaitingFinish] = useState(false);
  const { isLandscape } = useAppStore(['isLandscape']);
  const { executionId } = useParams() as ExecutionRouteParams;
  const { finishExecution } = useFlowStore(['finishExecution']);
  const online = useOnline();
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const spy = useSpy();

  const finishInspection = async () => {
    const response = await finishExecution(executionId);
    if (response) {
      spy(names.Summary.Rating, rating || undefined, rating ? { comment } : undefined);
      navigate(ROUTES.FLOWS);
    } else setAwaitingFinish(false);
  };

  const onFinishInspection = async () => {
    setAwaitingFinish(true);
    spy(names.Summary.Submit);
    if (online) {
      finishInspection();
    } else {
      modalManager.info({
        title: t('inspection.offlineSubmitModal.title'),
        message: t('inspection.offlineSubmitModal.message'),
        labels: { confirm: t('common.confirm'), cancel: '' },
        cancelProps: { display: 'none' },
        onConfirm: finishInspection,
      });
    }
  };

  return (
    <Flex w='100%' direction='column'>
      {isLandscape ? (
        <LandscapeNavbar
          leftSection={
            <BackHeaderButton biEventName={names.Summary.Back} destination={ROUTES.REVIEW_INSPECTION(executionId)}>
              <PortraitExecutionDetails flowTitle={t('summary.title')} order={4} />
            </BackHeaderButton>
          }
          rightSection={
            <Button
              size='md'
              rightSection={<IconCheck />}
              loading={awaitingFinish}
              onClick={onFinishInspection}
              data-testid={testIds.submitButton}
            >
              {t('common.submit')}
            </Button>
          }
        />
      ) : (
        <Flex justify='space-between' gap='xs' direction='column'>
          <PortraitNavbar />
          <Flex justify='space-between' gap='xs' pr='tn'>
            <BackHeaderButton biEventName={names.Summary.Back} destination={ROUTES.REVIEW_INSPECTION(executionId)}>
              <PortraitExecutionDetails flowTitle={t('summary.title')} order={4} />
            </BackHeaderButton>
            <Button
              size='md'
              rightSection={<IconCheck />}
              loading={awaitingFinish}
              onClick={onFinishInspection}
              data-testid={testIds.submitButton}
            >
              {t('common.submit')}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
