import { ContainerId, ReportedEvent } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { useContainerById } from 'stores/container';
import { useContainerReports } from 'stores/report';
import { useUiEventStore } from 'stores/uiEvent';

export function useLogItems(containerId?: ContainerId) {
  const container = useContainerById(containerId);
  const reports = useContainerReports(containerId ?? '');
  const { uiEvents } = useUiEventStore(['uiEvents']);

  return useMemo(() => {
    if (!container) return [];

    const eventMap = new Map(container.uiEvents?.eventIds.map((eventId) => [eventId, uiEvents[eventId]]));
    /**
     * Sift through reports by the following rules:
     * 1. For multi-select reports, show every single report. {ReportId, Report}
     * 2. If a context exists, keep the latest report for the context. {ContextId, Report}
     * 3. Otherwise, keep the latest report for the event. {ReportId, Report}
     */
    const reportMap = new Map<string, ReportedEvent>();
    reports.forEach((report) => {
      const { id, eventDefId, eventContextId } = report;
      const event = eventMap.get(eventDefId);
      const isMultiSelect = event?.type === 'MultiSelectEvent';
      const key = isMultiSelect ? id : (eventContextId ?? id);
      reportMap.set(key, report);
    });
    return Array.from(reportMap.values()).sort((r1, r2) => r2.creationDateClientTime - r1.creationDateClientTime);
  }, [reports]);
}
