import { useState } from 'react';

const useDisableButton = (duration = 1000): [boolean, () => void] => {
  const [disabled, setDisabled] = useState(false);

  const disableButton = () => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), duration);
  };

  return [disabled, disableButton];
};

export default useDisableButton;
