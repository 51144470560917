import { ComponentProps, useEffect } from 'react';
import { useEventVisibility } from 'stores/uiEvent';
import { useLastReportedValue } from 'stores/report';
import { RenderEvent } from '../RenderEvent/RenderEvent';

interface ChildEventProps extends ComponentProps<typeof RenderEvent> {
  onHide: () => void;
  onShow: (hasValue: boolean) => void;
}

export const ChildEvent = ({ onHide, onShow, ...props }: ChildEventProps) => {
  const show = useEventVisibility(props.uiEvent.eventId, props.container.id);
  const value = useLastReportedValue(props.container.id, props.uiEvent.eventId);

  useEffect(() => {
    if (show) onShow(!!value);
    else onHide();
  }, [show, value]);

  return show && <RenderEvent {...props} />;
};
