import { FlowMetadataFieldType } from '@flow/flow-backend-types';

export enum MetaType {
  PRE = 'pre',
  POST = 'post',
}

export const copyableFlowMetadataFieldTypes = [
  FlowMetadataFieldType.Text,
  FlowMetadataFieldType.String,
  FlowMetadataFieldType.Number,
  FlowMetadataFieldType.Date,
];
