import { MantineThemeOverride, rem } from '@mantine/core';

export const sizeTheme: Pick<MantineThemeOverride, 'spacing' | 'radius' | 'shadows'> = {
  spacing: {
    zero: rem(0),
    teeny: rem(2),
    tiny: rem(4),
    tn: rem(8),
    sm: rem(12),
  },
};
