import { Flex, ScrollArea } from '@mantine/core';
import { InspectionVoice } from '..';
import { useVoiceActions } from '../../InspectionPage.hooks';
import classes from '../../InspectionPage.module.css';
import { InspectionActionButtons } from '../InspectionActionButtons/InspectionActionButtons';
import { InspectionListContent } from '../InspectionListContent/InspectionListContent';

export const testIds = {
  inspectionMainContent: 'inspection-main-content',
  containerListContainer: 'container-list-container',
};

export interface InspectionMainContentProps {
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
  showEmptyState?: boolean;
}

export const InspectionMainContent = ({
  collapseContainerDetails,
  displayContainerContent,
  showEmptyState = false,
}: InspectionMainContentProps) => {
  useVoiceActions();

  return (
    <>
      <Flex direction='column' gap='lg' className={classes.headerBox} data-testid={testIds.inspectionMainContent}>
        <InspectionVoice />
        <InspectionActionButtons />
      </Flex>
      <ScrollArea
        type='scroll'
        className='grow'
        classNames={{ viewport: 'scroll-p-3 scroll-smooth' }}
        data-testid={testIds.containerListContainer}
      >
        <InspectionListContent
          collapseContainerDetails={collapseContainerDetails}
          displayContainerContent={displayContainerContent}
          showEmptyState={showEmptyState}
        />
      </ScrollArea>
    </>
  );
};
