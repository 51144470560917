import { Card, CardProps, Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from 'stores/auth';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useFlowByExecutionId } from 'stores/flow';
import { testIds } from '../SummaryPage';
import { Information } from '../Information/Information';

export const FlowCard = (props: CardProps) => {
  const { t } = useTranslation();
  const { executionId } = useParams() as ExecutionRouteParams;
  const flow = useFlowByExecutionId(executionId);
  const currentUser = useCurrentUser();

  return (
    <Card data-testid={testIds.flowInfo} {...props}>
      <Information label={t('summary.items.userName')} data-testid={testIds.items.userName}>
        {`${currentUser?.givenName} ${currentUser?.familyName}`}
      </Information>
      <Divider my='tn' />
      <Information label={t('summary.items.flowName')} data-testid={testIds.items.flowName}>
        {flow?.name}
      </Information>
    </Card>
  );
};
