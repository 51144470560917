import { MultiSelectElementData } from '@flow/flow-backend-types';

export function formatValues(values: string[], eventUiMeta?: MultiSelectElementData): string | null {
  const reportedOptions = values.map(
    (value) => eventUiMeta?.eventValues.find(({ valueName }) => valueName === value)?.title,
  );
  return reportedOptions.length ? `[${reportedOptions.length}]${reportedOptions.join(', ')}` : null;
}

export function formatOptions(eventUiMeta?: MultiSelectElementData): { value: string; label: string }[] {
  return (
    eventUiMeta?.eventValues.map(({ valueName, title }) => ({
      value: valueName,
      label: title ?? valueName,
    })) ?? []
  );
}
