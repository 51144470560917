import { Flex, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { LandscapeExecutionDetails, LandscapeNavbar, PortraitExecutionDetails, PortraitNavbar } from 'layouts';
import { BackHeaderButton } from 'components';
import { useFlowStore } from 'stores/flow';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useAppStore } from 'stores/app';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { testIds } from '../../ReviewPage';

interface ReviewNavbarNavbarProps {
  executionId: string;
  flowName?: string;
  uniqueIdentifier?: string;
}

export const ReviewNavbar = ({ executionId, flowName, uniqueIdentifier }: ReviewNavbarNavbarProps) => {
  const navigate = useForceNavigate();
  const { t } = useTranslation();
  const { continueExecution } = useFlowStore(['continueExecution']);
  const { isLandscape } = useAppStore(['isLandscape']);
  const spy = useSpy();

  const onClickNext = () => {
    spy(names.Review.Continue);
    navigate(ROUTES.SUMMARY_INSPECTION(executionId));
  };

  const onClickPrevious = async () => !!(await continueExecution(executionId));

  return (
    <Flex w='100%' direction='column'>
      {isLandscape ? (
        <LandscapeNavbar
          leftSection={
            <BackHeaderButton
              biEventName={names.Review.Back}
              destination={ROUTES.INSPECTION(executionId)}
              onClick={onClickPrevious}
            />
          }
          rightSection={
            <Button size='md' onClick={onClickNext} data-testid={testIds.finishInspection}>
              {t('review.submitBtn')}
            </Button>
          }
          underLogoSection={<LandscapeExecutionDetails flowTitle={flowName} uniqueIdentifier={uniqueIdentifier} />}
        />
      ) : (
        <Flex justify='space-between' gap='xs' direction='column'>
          <PortraitNavbar />
          <Flex justify='space-between' gap='xs' pr='tn'>
            <BackHeaderButton
              biEventName={names.Review.Back}
              destination={ROUTES.INSPECTION(executionId)}
              onClick={onClickPrevious}
            >
              <PortraitExecutionDetails flowTitle={flowName} uniqueIdentifier={uniqueIdentifier} />
            </BackHeaderButton>
            <Button className='shrink-0' size='md' onClick={onClickNext} data-testid={testIds.finishInspection}>
              {t('review.submitBtn')}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
