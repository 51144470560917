import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { PutReportedEventRequest, ReportedEvent } from '@flow/flow-backend-types';
import { flowStore } from 'stores/flow';
import { createStoreHook } from '@aiola/frontend';
import { db } from 'services/db';
import { networkStore } from 'stores/network';
import { uiEventStore } from 'stores/uiEvent';
import { containerStore } from 'stores/container';
import { reportApi } from './report.api';
import { ReportDynamicData, ReportValidityMap, ReportedEventsMap } from './report.types';
import {
  getReportCollectionKey,
  filterDuplicatesAndEnrichReports,
  updateEventValidity,
  buildReportsMap,
  splitReportCollectionKey,
} from './report.utils';

interface ReportActions {
  send: (reportSlice: ReportDynamicData[]) => Promise<void>;
  receive: (reportedEvents: ReportedEvent[]) => void;
  loadExecutionReports: (flowExecutionId: string) => Promise<void>;
  internal: {
    addReport: (report: PutReportedEventRequest) => void;
    removeReport: (report: PutReportedEventRequest) => void;
    reset: () => void;
  };
}

interface ReportState {
  reports: ReportedEventsMap;
  validity: ReportValidityMap;
}

const reportInitialState: ReportState = { reports: {}, validity: {} };
export const reportStore = create(
  immer<ReportState & ReportActions>((set, get) => ({
    ...reportInitialState,
    send: async (reportsData) => {
      const { reports, internal } = get();
      const { online } = networkStore.getState();
      const { addReport } = internal;

      const uniqueReportsWithMetadata = filterDuplicatesAndEnrichReports(reportsData, reports, !online);
      uniqueReportsWithMetadata.forEach(addReport);

      db.storePendingActionBulk(uniqueReportsWithMetadata.map((report) => ({ type: 'reportEvent', payload: report })));
    },
    receive: (reportedEvents) => {
      const { currentExecutionId } = flowStore.getState();
      const { reports, internal } = get();
      const { addReport } = internal;
      reportedEvents.forEach((report) => {
        const isDifferentExecution = report.flowExecutionId !== currentExecutionId;
        if (isDifferentExecution) return;
        const collectionKey = getReportCollectionKey(report.containerId, report.eventDefId);
        const eventReports = reports[collectionKey] ?? [];
        const reportExists = eventReports.some(({ id }) => id === report.id);
        if (!reportExists) addReport(report);
      });
    },
    loadExecutionReports: async (flowExecutionId) => {
      const { online } = networkStore.getState();
      const { containers } = containerStore.getState();
      const { uiEvents, validations } = uiEventStore.getState();
      let reports: PutReportedEventRequest[] = [];
      if (online) {
        reports = await reportApi.getExecutionReports(flowExecutionId);
        await db.reportedEvents.bulkPut(reports);
      } else {
        reports = await db.reportedEvents.where('flowExecutionId').equals(flowExecutionId).toArray();
      }
      const reportMap = buildReportsMap(reports);
      const validityMap: ReportValidityMap = Object.keys(reportMap).reduce((acc, key) => {
        const [containerId, eventId] = splitReportCollectionKey(key);
        return updateEventValidity({
          uiEvent: uiEvents[eventId],
          container: containers[containerId],
          reports: reportMap,
          validityMap: acc,
          validations,
        });
      }, {});
      set({ reports: reportMap, validity: validityMap });
    },
    internal: {
      addReport: (report) => {
        const { containers } = containerStore.getState();
        const { uiEvents, validations } = uiEventStore.getState();
        const { eventDefId: eventId, containerId } = report;

        set((state) => {
          const collectionKey = getReportCollectionKey(containerId, eventId);
          if (!state.reports[collectionKey]) state.reports[collectionKey] = [report as ReportedEvent];
          else state.reports[collectionKey].push(report as ReportedEvent);
          const { reports, validity: validityMap } = state;
          state.validity = updateEventValidity({
            uiEvent: uiEvents[eventId],
            container: containers[containerId],
            reports,
            validityMap,
            validations,
          });
          db.reportedEvents.add(report);
        });
      },
      removeReport: (report) => {
        const { containers } = containerStore.getState();
        const { uiEvents, validations } = uiEventStore.getState();
        const { id, eventDefId: eventId, containerId } = report;
        set((state) => {
          const collectionKey = getReportCollectionKey(containerId, eventId);
          state.reports[collectionKey] = state.reports[collectionKey].filter((r) => r.id !== id);
          const { reports, validity: validityMap } = state;
          state.validity = updateEventValidity({
            uiEvent: uiEvents[eventId],
            container: containers[containerId],
            reports,
            validityMap,
            validations,
          });
        });
      },
      reset: () => set(reportInitialState),
    },
  })),
);

export const useReportStore = createStoreHook<ReportState & ReportActions>({ store: reportStore, useShallow });
