import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UIContainer, filterLeafContainers, orderContainers, useContainerStore } from 'stores/container';
import { isEventValid, useUiEventStore } from 'stores/uiEvent';
import { useReportStore } from 'stores/report';
import { useGetMainEvent } from 'stores/uiEvent/uiEvent.selectors';
import { isApplicableByReport, pullLastReport } from 'stores/report/report.utils';
import { ContainerItemSummaryProps } from 'pages';

interface ReviewStepsContainerIds {
  invalidContainerSummaries: ContainerItemSummaryProps[];
  validContainerSummaries: ContainerItemSummaryProps[];
  nonApplicableContainerSummaries: ContainerItemSummaryProps[];
}

export function useContainersByStep(): ReviewStepsContainerIds {
  const { validations } = useUiEventStore(['validations']);
  const { reports } = useReportStore(['reports']);
  const { containers, rootContainerIds } = useContainerStore(['containers', 'rootContainerIds']);
  const getMainEvent = useGetMainEvent();

  const placeSummary = (summaries: ReviewStepsContainerIds, summary: ContainerItemSummaryProps, isValid?: boolean) => {
    if (summary.notApplicable) summaries.nonApplicableContainerSummaries.push(summary);
    else if (isValid) summaries.validContainerSummaries.push(summary);
    else summaries.invalidContainerSummaries.push(summary);
  };

  const filterContainer = (summaries: ReviewStepsContainerIds, container: UIContainer) => {
    const mainEvent = getMainEvent(container);
    if (!mainEvent) return;
    const { id: containerId } = container;
    const applicabilityReport = pullLastReport(reports, containerId, container.uiEvents?.applicabilityEventId ?? '');
    const eventValidations = containers[containerId]?.uiEvents?.eventValidations;
    const validationId = eventValidations?.[mainEvent.eventId];
    const validation = validations?.[validationId ?? ''];
    const notApplicable = !isApplicableByReport(applicabilityReport);
    const containerSummary: ContainerItemSummaryProps = {
      container,
      uiEvent: mainEvent,
      notApplicable,
    };
    const isValid = isEventValid(mainEvent, containerId, reports, validation) !== false;
    placeSummary(summaries, containerSummary, isValid);
  };

  return useMemo(() => {
    const summaries: ReviewStepsContainerIds = {
      invalidContainerSummaries: [],
      validContainerSummaries: [],
      nonApplicableContainerSummaries: [],
    };

    const rootContainers = orderContainers(rootContainerIds.map((id) => containers[id]));
    rootContainers.forEach(({ id }) => {
      const leafContainers = filterLeafContainers(containers, id, []);
      leafContainers.forEach((container) => filterContainer(summaries, container));
    });

    return summaries;
  }, [containers, rootContainerIds, validations]);
}

export function useReviewSteps({
  invalidContainerSummaries,
  validContainerSummaries,
  nonApplicableContainerSummaries,
}: ReviewStepsContainerIds) {
  const { t } = useTranslation();
  const invalidTitle = t('review.invalidStepTitle');
  const validTitle = t('review.validStepTitle');
  const nonApplicableTitle = t('review.notApplicableStepTitle');
  return [
    {
      stepTitle: invalidTitle,
      containerSummaries: invalidContainerSummaries,
    },
    {
      stepTitle: validTitle,
      containerSummaries: validContainerSummaries,
    },
    {
      stepTitle: nonApplicableTitle,
      containerSummaries: nonApplicableContainerSummaries,
    },
  ];
}
