import { ActionIcon, ActionIconProps, Divider, Drawer, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDots } from '@tabler/icons-react';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { VoiceMode, useVoiceStore } from 'stores/voice';
import { VoiceModeOption } from './VoiceModeOption';
import classes from './VoiceModeSelector.module.css';

export const testIds = {
  button: 'voice-mode-selector-button',
  drawer: 'voice-mode-menu-drawer',
  overlay: 'voice-mode-menu-overlay',
  title: 'voice-mode-menu-title',
};

export const VoiceModeSelector = (props: ActionIconProps) => {
  const { t } = useTranslation();
  const spy = useSpy();
  const { mode, setMode, pause, resume } = useVoiceStore(['mode', 'setMode', 'pause', 'resume']);
  const [opened, { open, close }] = useDisclosure(false, {
    onOpen: () => pause(true),
    onClose: resume,
  });

  const onOpen = () => {
    spy(names.AiolaWidget.VoiceModeOpen);
    open();
  };

  const onClose = () => {
    spy(names.AiolaWidget.VoiceModeClose, BiEventValueStatus.VOICE_MODE_NOT_CHANGED);
    close();
  };

  const onSelectMode = async (selectedMode: VoiceMode) => {
    const modeStatus =
      mode === selectedMode ? BiEventValueStatus.VOICE_MODE_NOT_CHANGED : BiEventValueStatus.VOICE_MODE_CHANGED;
    spy(names.AiolaWidget.VoiceModeClose, modeStatus, { old_voice_mode: mode, new_voice_mode: selectedMode });
    await setMode(selectedMode);
    close();
  };

  return (
    <>
      <ActionIcon
        className={classes.button}
        size='xl'
        variant='transparent'
        color='white'
        onClick={onOpen}
        data-testid={testIds.button}
        {...props}
      >
        <IconDots />
      </ActionIcon>
      <Drawer.Root opened={opened} onClose={onClose} position='bottom' size={230}>
        <Drawer.Overlay data-testid={testIds.overlay} />
        <Drawer.Content data-testid={testIds.drawer}>
          <Drawer.Header bg='gray.1' py='xs'>
            <Title order={5} c='gray.6' bg='gray.1' data-testid={testIds.title}>
              {t('voice.modes.voiceMenuTitle')}
            </Title>
          </Drawer.Header>
          <Drawer.Body px={0}>
            <VoiceModeOption
              value={VoiceMode.FREE_SPEECH}
              checked={mode === VoiceMode.FREE_SPEECH}
              title={t('voice.modes.freeSpeech.title')}
              description={t('voice.modes.freeSpeech.description')}
              onClick={onSelectMode}
            />
            <Divider />
            <VoiceModeOption
              value={VoiceMode.TAP_SPEECH}
              checked={mode === VoiceMode.TAP_SPEECH}
              title={t('voice.modes.tapSpeech.title')}
              description={t('voice.modes.tapSpeech.description')}
              onClick={onSelectMode}
            />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  );
};
