import { Modal } from '@mantine/core';
import { DynamicPopupHeader } from '@flow/flow-backend-types';
import { testIds } from './DynamicModal';

interface ModalHeaderProps {
  data: DynamicPopupHeader;
}

export const ModalHeader = ({ data }: ModalHeaderProps) => (
  <Modal.Header p='md'>
    <Modal.Title data-testid={testIds.header.title}>{data.title}</Modal.Title>
    <Modal.CloseButton data-testid={testIds.header.xButton} />
  </Modal.Header>
);
