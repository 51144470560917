import { useMemo } from 'react';
import { Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface EventTitleProps {
  eventTitle?: string;
  isMandatory: boolean;
}

export const testIds = { optionalEvent: 'optional-event' };

export const EventTitle = ({ eventTitle, isMandatory }: EventTitleProps) => {
  const { t } = useTranslation();
  return useMemo(
    () => (
      <Group gap={5}>
        {eventTitle}
        {!isMandatory && (
          <Text size='sm' data-testid={testIds.optionalEvent}>
            ({t('common.optional')})
          </Text>
        )}
      </Group>
    ),
    [eventTitle, isMandatory],
  );
};
