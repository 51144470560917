import { HermesSocket } from '@aiola/frontend';
import { ClientEvents, ServerEvents } from 'services/audioSocket';

const CHUNK_SIZE = 4096;
const SAMPLE_RATE = 16000;
export const ACCEPTED_AUDIO_FORMATS = ['mp3', 'wav', 'ogg', 'flac', 'x-m4a', 'mp4', 'aac']
  .map((type) => `audio/${type}`)
  .join(',');

export const decodeAudioFile = async (file: File | null): Promise<AudioBuffer | null> => {
  if (!file) return null;

  const audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
  const arrayBuffer = await file.arrayBuffer();

  try {
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer;
  } catch (error) {
    console.error('Error decoding audio file:', error);
    return null;
  }
};

const pcmEncode = (input: Float32Array) => {
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);

  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true); // Little endian
  }

  return buffer;
};

export const streamAudioData = (audioBuffer: AudioBuffer, ws: HermesSocket<ServerEvents, ClientEvents>) => {
  const inputChannelData = audioBuffer.getChannelData(0);
  const pcmBuffer = pcmEncode(inputChannelData);

  const chunkSize = CHUNK_SIZE * 2;

  for (let i = 0; i < pcmBuffer.byteLength; i += chunkSize) {
    const chunk = pcmBuffer.slice(i, i + chunkSize);
    ws.emit('binary_data', {
      id: i,
      audioFrame: chunk,
      timestamp: Date.now(),
    });
  }
};
