import { db } from 'services/db';
import { inspectionMetaStore } from 'stores/inspectionMeta';
import { reportApi } from 'stores/report/report.api';
import { Execution } from 'stores/flow';
import { ExecutionId, FailedAction, Flow, PendingAction, StartExecutionRequest } from '@flow/flow-backend-types';
import { arrayToMap } from 'utils';
import { appStore } from './app.store';
import { FailedActionWithReason } from './app.types';

export const invalidateCache = async (
  serverFlows: Flow[],
  serverExecutions: Execution[],
  currentExecutionId?: ExecutionId,
) => {
  try {
    // Fetch data from local cache
    const [cachedFlows, cachedExecutions, pendingExecutions] = await Promise.all([
      db.getFlows(),
      db.getExecutions(),
      db
        .listPendingActions()
        .then((actions) =>
          actions
            .filter((action) => action.type === 'startExecution')
            .map((action) => action.payload as StartExecutionRequest),
        ),
    ]);

    const serverFlowIds = new Set(serverFlows.map((flow) => flow.id));
    const serverExecutionIds = new Set(serverExecutions.map((execution) => execution.id));
    const pendingExecutionIds = new Set(pendingExecutions.map((execution) => execution.id));
    // Identify outdated flows in cache
    const outdatedFlowIds = cachedFlows.filter((flow) => !serverFlowIds.has(flow.id)).map((flow) => flow.id);

    // Identify outdated executions in cache
    const outdatedExecutionIds = cachedExecutions
      .filter(({ id, flowRef }) => {
        const isCurrent = id === currentExecutionId;
        const existsOnServer = serverExecutionIds.has(id);
        const flowExistsOnServer = serverFlowIds.has(flowRef.id);
        const isCreatedOffline = pendingExecutionIds.has(id);

        return !isCurrent && !isCreatedOffline && (!existsOnServer || !flowExistsOnServer);
      })
      .map((execution) => execution.id);

    // Remove outdated flows and their related data
    if (outdatedFlowIds.length > 0) await db.deleteFlowData(outdatedFlowIds);
    // Remove outdated executions and their related data
    if (outdatedExecutionIds.length > 0) await db.deleteExecutionData(outdatedExecutionIds);
  } catch (error) {
    console.error('Error invalidating cache:', error);
  }
};

export const updateMetadataAndRenderModel = async (idsAndVersions: string[]) => {
  const promises = idsAndVersions.map(async (flowIdAndVersion) => {
    const [id, version, flowExecutionId] = flowIdAndVersion.split(':');

    const [cachedFlowMetadata, cachedRenderModel] = await Promise.all([
      db.flowMetadata.get({ id, version }),
      db.renderModels.get({ flowId: id, version }),
    ]);

    const updateCachePromises: Promise<string | void>[] = [];

    if (!cachedFlowMetadata) {
      updateCachePromises.push(inspectionMetaStore.getState().updateCache(id, version));
    }

    if (!cachedRenderModel) {
      updateCachePromises.push(appStore.getState().updateCache(id, version, flowExecutionId));
    }

    return Promise.all(updateCachePromises);
  });

  await Promise.all(promises);
};

export const updateLastReports = async (executions: Execution[]) => {
  await Promise.all(
    executions.map(({ id }) =>
      reportApi.getExecutionReports(id).then((reports) => {
        if (reports.length) db.reportedEvents.bulkPut(reports);
      }),
    ),
  );
};

export const categorizePendingActions = (pendingActions: PendingAction[], failedActions: FailedAction[]) => {
  const failedMap = arrayToMap(failedActions, 'id');
  const failedActionsWithReason: FailedActionWithReason[] = [];
  const actionIdsToClear = [];

  for (const action of pendingActions) {
    const failedAction = failedMap[action.id];
    if (failedAction) failedActionsWithReason.push({ action, reason: failedAction });
    else actionIdsToClear.push(action.id);
  }

  return { failedActionsWithReason, actionIdsToClear };
};
