import { Input, ComboboxItem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownElementData, ReportValueType } from '@flow/flow-backend-types';
import { getSingleChoiceValueColor } from 'stores/uiEvent';
import { OptionListDrawer } from 'pages/InspectionPage/components';
import { EventProps } from '../types';

export const testIds = {
  getInputTestId: (id: string) => `list-event-input-${id}`,
};

export const DropdownEvent = ({
  eventUiMeta,
  validation,
  lastEventReport,
  title,
  reportKey,
  disabled,
  triggerReport,
}: EventProps<DropdownElementData>) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const eventColor = useMemo(
    () => getSingleChoiceValueColor(validation, lastEventReport?.reportedValue),
    [lastEventReport?.reportedValue],
  );
  const options = useMemo(
    () =>
      eventUiMeta?.eventValues.map<ComboboxItem>(({ valueName, title: optionTitle }) => ({
        value: valueName,
        label: optionTitle ?? valueName,
      })) ?? [],
    [eventUiMeta],
  );
  const value = useMemo(() => {
    const option = eventUiMeta?.eventValues.find(({ valueName }) => valueName === lastEventReport?.reportedValue);
    return option ? (option.title ?? option.valueName) : undefined;
  }, [eventUiMeta, lastEventReport?.reportedValue]);

  const onUpdate = (optionValue: string | null) => {
    const option = options.find(({ label }) => label === value);
    const reportedValue = option?.value !== optionValue ? optionValue : null;
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
    setOpened(false);
  };

  return (
    <>
      <Input.Wrapper label={title}>
        <Input
          value={value ?? ''}
          placeholder={eventUiMeta.placeHolder ?? t('placeholders.list')}
          disabled={disabled}
          rightSection={<IconChevronDown size={22} />}
          pointer
          readOnly
          classNames={{ input: '!border-2 transition-colors' }}
          styles={{ input: { borderColor: eventColor } }}
          onClick={() => setOpened(true)}
          data-testid={testIds.getInputTestId(reportKey)}
        />
      </Input.Wrapper>
      <OptionListDrawer
        opened={opened}
        data={[options?.find(({ value: optionValue }) => optionValue === lastEventReport?.reportedValue)?.value ?? '']}
        options={options}
        reportKey={reportKey}
        onClose={() => setOpened(false)}
        onUpdate={onUpdate}
        onClear={() => onUpdate(null)}
      />
    </>
  );
};
