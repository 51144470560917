import { ActionIcon, Button, Flex, Group } from '@mantine/core';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IconCheck, IconReport } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { ApplicabilityEvent, ImageCapturer } from 'components';
import { ExecutionRouteParams } from 'routes/routes.config';
import { isApplicableByReport } from 'stores/report/report.utils';
import { useFocusStore } from 'stores/focus';
import { UIContainer, useContainerStore } from 'stores/container';
import { useExecution } from 'stores/flow';
import { useReporter } from 'stores/report';
import { usePhotoContext } from 'contexts/ItemPhotoContext';
import { useNetworkStore } from 'stores/network';
import classes from './ContainerItem.module.css';

export interface ContainerItemFooterProps {
  container: UIContainer;
}

export const testIds = {
  wrapper: 'container-item-footer-wrapper',
  doneButton: 'container-item-footer-done-button',
  logButton: 'container-item-footer-log-button',
};

export const ContainerItemFooter = ({ container }: ContainerItemFooterProps) => {
  const { id: containerId, title: containerTitle } = container;
  const { t } = useTranslation();
  const spy = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  const { blurContainer } = useFocusStore(['blurContainer']);
  const execution = useExecution(executionId);
  const { openedLogContainerId, openContainerLog, closeContainerLog, finishContainerEditing } = useContainerStore([
    'openedLogContainerId',
    'openContainerLog',
    'closeContainerLog',
    'finishContainerEditing',
  ]);
  const applicabilityEventId = container.uiEvents?.applicabilityEventId;
  const imageEventId = container.uiEvents?.imageEventId;
  const { online } = useNetworkStore(['online']);
  const { loadingUpload, uploadImage } = usePhotoContext();
  const { lastReport: lastApplicabilityReport, triggerReport: triggerApplicabilityReport } = useReporter({
    executionId,
    containerId,
    eventId: applicabilityEventId ?? '',
  });

  const onCapture = (base64Image: string) => {
    spy(names.Camera.TakePhoto, containerTitle, { item_id: containerId });
    uploadImage(base64Image);
  };

  const onDone = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    spy(names.ItemContainer.Focus, { Null: 'Null' }, { item_id: containerId, item_name: containerTitle });
    const containersDone = [
      {
        containerId,
        containerName: containerTitle,
        flowExecutionId: executionId,
        flowId: execution?.flowRef.id,
        version: execution?.flowRef.version,
        clientTimestamp: Date.now(),
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    ];
    finishContainerEditing({ containersDone });
    blurContainer();
  };

  const onCameraOpen = () => spy(names.ItemContainer.OpenCamera, containerTitle, { item_id: containerId });
  const cameraDisabled = !online || !isApplicableByReport(lastApplicabilityReport);

  const onToggleContainerLog = () => {
    if (openedLogContainerId) {
      spy(names.ItemContainer.LogOfEventsClose, containerTitle);
      closeContainerLog();
    } else {
      spy(names.ItemContainer.LogOfEvents, containerTitle);
      openContainerLog(containerId);
    }
  };

  return (
    <Flex component='footer' align='center' justify='space-between' data-testid={testIds.wrapper}>
      <Group gap='zero'>
        {imageEventId && (
          <ImageCapturer
            disabled={cameraDisabled}
            loading={loadingUpload}
            onCapture={onCapture}
            onClick={onCameraOpen}
          />
        )}
        <ActionIcon
          size='xl'
          variant={openedLogContainerId ? 'light' : 'transparent'}
          onClick={onToggleContainerLog}
          data-testid={testIds.logButton}
        >
          <IconReport size={24} strokeWidth={1.6} />
        </ActionIcon>
        {applicabilityEventId && (
          <ApplicabilityEvent
            eventId={applicabilityEventId}
            lastEventReport={lastApplicabilityReport}
            triggerReport={triggerApplicabilityReport}
          />
        )}
      </Group>
      <Button
        size='sm'
        pr='zero'
        variant='light'
        classNames={{ section: classes.doneButtonSections }}
        rightSection={<IconCheck size={24} className='mr-2' />}
        onClick={onDone}
        data-testid={testIds.doneButton}
      >
        {t('common.done')}
      </Button>
    </Flex>
  );
};
