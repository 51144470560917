/* eslint-disable no-use-before-define */

interface Entries {
  [key: string]: readonly (string | Entries)[];
}

export type CypherEntry = string | Entries;

type StrToObj<T> = T extends string ? { [P in T]: undefined } : T;

type UnionToIntersection<T> = (T extends infer U ? (x: U) => void : never) extends (x: infer I) => void ? I : never;

type BuildCypherFromEntryElements<T> = T extends readonly any[]
  ? BuildCypherFromEntries<UnionToIntersection<StrToObj<T[number]>>>
  : string;

type BuildCypherFromEntries<T> = {
  -readonly [K in keyof T]: BuildCypherFromEntryElements<T[K]>;
} extends infer O
  ? { [K in keyof O]: O[K] }
  : never;

/**
 * This file is used to generate the cypher query for the espionage service, given a list of entries.
 * @example const cypher = buildCypher([{ key: ['value1', 'value2'] }]);
 * The cypher is a tree-like object which outputs concatenated names of its branches.
 * @example
 * - key
 *   - value1
 *   - value2
 * console.log(cypher.key.value1); // key_value1
 * console.log(cypher.key.value2); // key_value2
 */
export function buildCypher<T extends readonly CypherEntry[]>(
  entries: T,
  parentName = '',
  cypher: Record<string, any> = {},
) {
  entries.forEach((entry) => {
    if (typeof entry === 'string') {
      cypher[entry] = parentName ? `${parentName}_${entry}` : entry;
    } else {
      Object.entries(entry).forEach(([key, furtherEntries]) => {
        cypher[key] = {};
        const furtherParentName = parentName ? `${parentName}_${key}` : key;
        buildCypher(furtherEntries, furtherParentName, cypher[key]);
      });
    }
  });
  return cypher as typeof cypher & BuildCypherFromEntryElements<T>;
}
