import { ActionIcon, CheckIcon, Flex, Radio, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { VoiceMode } from 'stores/voice';
import classes from './VoiceModeSelector.module.css';

interface VoiceModeOptionProps {
  value: VoiceMode;
  checked: boolean;
  title: string;
  description: string;
  onClick: (value: VoiceMode) => void;
}

export const testIds = {
  getModeOptionTestId: (mode: VoiceMode) => `voice-mode-option-${mode}`,
  getRadioTestId: (mode: VoiceMode) => `voice-mode-option-radio-${mode}`,
  getTooltipTestId: (mode: VoiceMode) => `voice-mode-option-tooltip-${mode}`,
  getTitleTestId: (mode: VoiceMode) => `voice-mode-option-title-${mode}`,
  getInfoButtonTestId: (mode: VoiceMode) => `voice-mode-option-info-${mode}`,
};

export const VoiceModeOption = ({ value, checked, title, description, onClick, ...props }: VoiceModeOptionProps) => {
  const [opened, { open, close }] = useDisclosure();
  const spy = useSpy();

  const onPointerDown = () => {
    spy(names.AiolaWidget.VoiceModeInfo, value);
    open();
  };

  return (
    <Flex
      justify='space-between'
      align='center'
      py='lg'
      px='sm'
      className='cursor-pointer'
      onClick={() => onClick(value)}
      data-testid={testIds.getModeOptionTestId(value)}
      {...props}
    >
      <Radio
        value={value}
        checked={checked}
        label={
          <Text size='lg' data-testid={testIds.getTitleTestId(value)}>
            {title}
          </Text>
        }
        icon={CheckIcon}
        className={classes.radioButton}
        color='green.6'
        readOnly
        onClick={(e) => e.stopPropagation()}
        data-testid={testIds.getRadioTestId(value)}
      />
      <Tooltip
        opened={opened}
        color='green.9'
        p='md'
        multiline
        label={
          <>
            <Text size='xl'>{title}</Text>
            <Text size='md'>{description}</Text>
          </>
        }
        data-testid={testIds.getTooltipTestId(value)}
      >
        <ActionIcon
          onClick={(e) => e.stopPropagation()}
          size='xl'
          variant='transparent'
          onPointerDown={onPointerDown}
          onPointerUp={close}
          data-testid={testIds.getInfoButtonTestId(value)}
        >
          <IconInfoCircle />
        </ActionIcon>
      </Tooltip>
    </Flex>
  );
};
