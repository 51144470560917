import { config } from 'services/config';

const fileApiUrl = `${config.getApiUrl()}/file`;

export function b64toBlob(base64String: string) {
  const base64DataString = base64String.split(',')[1]; // remove format prefix
  const byteCharacters = atob(base64DataString);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'image/jpeg' });
}

export function getImageUrl(imageId: string, executionId: string, containerId?: string): string {
  return containerId
    ? `${fileApiUrl}/${executionId}/${containerId}/${imageId}`
    : `${fileApiUrl}/${executionId}/${imageId}`;
}

export function getImageCreationUrl(executionId: string, containerId?: string, originalFileId?: string): string {
  const queryParams = new URLSearchParams();
  let url = `${fileApiUrl}/${executionId}`;
  if (containerId) url += `/${containerId}`;
  if (originalFileId) queryParams.append('originalFileId', originalFileId);
  if (queryParams.toString()) url += `?${queryParams}`;
  return url;
}
