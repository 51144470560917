import { Flex } from '@mantine/core';
import { PropsWithChildren, ReactNode } from 'react';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';

interface LandscapeLayoutProps extends PropsWithChildren {
  leftSection: ReactNode;
  rightSection: ReactNode;
}

export const testIds = {
  landscapeContainer: 'landscape-container',
};

export const InspectionLandscapeLayout = ({ leftSection, rightSection }: LandscapeLayoutProps) => (
  <Flex className='flex-1 overflow-auto' px={28} gap={40} data-testid={testIds.landscapeContainer}>
    <Flex w={INSPECTION_PAGE_LANDSCAPE_PERCENTAGE.LEFT_SECTION_WIDTH} direction='column'>
      {leftSection}
    </Flex>
    <Flex w={INSPECTION_PAGE_LANDSCAPE_PERCENTAGE.RIGHT_SECTION_WIDTH} h='95%'>
      {rightSection}
    </Flex>
  </Flex>
);
