import { MantineThemeOverride, rem } from '@mantine/core';

export const fontTheme: Pick<MantineThemeOverride, 'fontFamily' | 'fontFamilyMonospace' | 'fontSizes' | 'headings'> = {
  fontFamily: 'Poppins, sans-serif',
  fontSizes: {
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    lg: rem(16),
    xl: rem(18),
  },
  headings: {
    fontWeight: '500',
    sizes: {
      h1: { fontSize: rem(32) },
      h2: { fontSize: rem(28) },
      h3: { fontSize: rem(24) },
      h4: { fontSize: rem(22) },
      h5: { fontSize: rem(16) },
      h6: { fontSize: rem(14) },
    },
  },
};
