import { useEffect, useMemo } from 'react';
import { useAppStore } from 'stores/app';
import { UIContainer, useContainerStore } from 'stores/container';
import { useAuthStore } from 'stores/auth';
import { Execution, useExecution, useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { ReportCollectionKey, useReportStore } from 'stores/report';
import { aggregateMultiSelectReports, EventUIData, isEventVisibleByBinding, useUiEventStore } from 'stores/uiEvent';
import { useVoiceStore, VoiceErrorReason, VoiceMode } from 'stores/voice';
import { ROUTES } from 'routes/routes.config';
import { useFocusData } from 'stores/focus';
import { toaster } from 'services/toaster';
import { useTranslation } from 'react-i18next';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useOnline } from 'stores/network';
import { names, useSpy } from 'services/espionage';
import { getReportCollectionKey, pullLastReport } from 'stores/report/report.utils';

export const useVoiceActions = () => {
  const { status, listen, pause, close, mode, pausedOn, voiceError, clearVoiceError } = useVoiceStore([
    'status',
    'listen',
    'pause',
    'close',
    'mode',
    'pausedOn',
    'voiceError',
    'clearVoiceError',
  ]);
  const { focusedContainerId } = useFocusData();
  const { editingEventId } = useUiEventStore(['editingEventId']);
  const online = useOnline();

  useEffect(() => {
    const isEditing = Boolean(focusedContainerId && editingEventId);
    const isFocusedAndNotEditing = Boolean(focusedContainerId && !editingEventId);
    const isFreeSpeechMode = mode === VoiceMode.FREE_SPEECH;
    const shouldIgnoreVoiceActions = Boolean(pausedOn);
    const shouldPause = Boolean(isEditing || (!focusedContainerId && !isFreeSpeechMode));
    const shouldListen = Boolean(isFocusedAndNotEditing || !focusedContainerId);
    const isOffline = !online;

    if (isOffline) {
      close(VoiceErrorReason.NetworkError);
      return;
    }

    if (online && voiceError === VoiceErrorReason.NetworkError) {
      clearVoiceError();
      return;
    }

    if (shouldIgnoreVoiceActions) return;

    if (shouldPause) pause();
    else if (shouldListen) listen();
  }, [status, editingEventId, focusedContainerId, mode, online]);

  useEffect(
    () => () => {
      close();
    },
    [],
  );
};

export const useExecutionBlocked = (execution?: Execution) => {
  const { currentUser } = useAuthStore(['currentUser']);
  const navigate = useForceNavigate();

  useEffect(() => {
    const executionStatus = execution?.status;
    const isExecutionInReview = executionStatus === 'inReview';
    const isReviewedByCurrentUser = execution?.reviewedBy?.userId === currentUser?.userId;

    if (isExecutionInReview && isReviewedByCurrentUser) {
      navigate(ROUTES.REVIEW_INSPECTION(execution?.id));
    }
  }, [execution]);
};

export const useStartInspection = (executionId: string) => {
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  const navigate = useForceNavigate();
  const spy = useSpy();
  const { loadRenderModel, setInspectionDataLoading } = useAppStore(['loadRenderModel', 'setInspectionDataLoading']);
  const { loadExecutionReports } = useReportStore(['loadExecutionReports']);
  const { currentExecutionId, setCurrentExecutionId } = useFlowStore(['currentExecutionId', 'setCurrentExecutionId']);
  const { rootContainerIds, dynamicContainerTemplateIds, closeAndClear, createDynamicContainer } = useContainerStore([
    'rootContainerIds',
    'dynamicContainerTemplateIds',
    'closeAndClear',
    'createDynamicContainer',
  ]);
  const { setFilterValues } = useUiEventStore(['setFilterValues']);
  const { reset: resetVoice } = useVoiceStore(['reset']);

  useEffect(() => {
    (async () => {
      if (execution && !currentExecutionId) {
        setInspectionDataLoading(true);
        const renderModelResponse = await loadRenderModel(execution.flowRef.id, execution.flowRef.version, executionId);

        if (!renderModelResponse) {
          navigate(ROUTES.FLOWS);
          spy('InspectionPage', names.InspectionList.InspectionDataError, { execution_id: executionId });
          toaster.error({ title: 'Could not load inspection data.', message: null });
          return;
        }
        await loadExecutionReports(executionId);
        setCurrentExecutionId(executionId);
        setInspectionDataLoading(false);
      }
    })();
    return () => {
      closeAndClear();
      setFilterValues([]);
      resetVoice();
    };
  }, [executionId]);

  return {
    flow,
    rootContainerIds,
    execution,
    dynamicContainerTemplateIds,
    createDynamicContainer,
  };
};

export const useDynamicContainers = (executionId: string) => {
  const { dynamicContainerTemplateIds, createDynamicContainer } = useContainerStore([
    'dynamicContainerTemplateIds',
    'createDynamicContainer',
    'openTemplatesList',
  ]);
  const { t } = useTranslation();

  const isDynamicContainers = !!dynamicContainerTemplateIds.length;
  const hasMultipleDynamicContainers = dynamicContainerTemplateIds.length > 1;

  const createNewDynamicContainer = async (templateId?: string) => {
    const newTemplateId = templateId ?? dynamicContainerTemplateIds.at(0);
    if (newTemplateId) {
      const isCreated = await createDynamicContainer(newTemplateId, executionId);
      if (!isCreated) toaster.error({ message: t('inspection.errors.dynamicContainerCreationFailure') });
    }
  };

  return {
    isDynamicContainers,
    hasMultipleDynamicContainers,
    createNewDynamicContainer,
  };
};

/**
 * Calculate amount of containers with at least one event which are:
 * - applicable
 * - mandatory
 * - not reported
 */
export function useMissingRequiredEvents() {
  const { reports, validity } = useReportStore(['reports', 'validity']);
  const { containers } = useContainerStore(['containers']);
  const { uiEvents, visibilityBindings } = useUiEventStore(['uiEvents', 'visibilityBindings']);

  const getChildIdSet = (events: EventUIData[]) => {
    const childIds = new Set<string>();
    events.forEach((event) => {
      if (event.childrenIds) event.childrenIds.forEach((childId) => childIds.add(childId));
    });
    return childIds;
  };

  const isApplicable = (container: UIContainer) => {
    const applicabilityId = container.uiEvents?.applicabilityEventId;
    if (!applicabilityId) return true;
    const applicabilityReport = pullLastReport(reports, container.id, applicabilityId);
    if (!applicabilityReport) return true;
    return Boolean(applicabilityReport.reportedValue);
  };

  const getEvents = (container: UIContainer) => {
    const eventIds = container.uiEvents?.eventIds ?? [];
    return eventIds.map((eventId) => uiEvents[eventId]);
  };

  const getReportedValue = (containerId: string, event: EventUIData) => {
    const { eventId, type } = event;
    const reportKey = getReportCollectionKey(containerId, eventId);
    return type === 'MultiSelectEvent'
      ? aggregateMultiSelectReports(reports[reportKey] ?? [])
      : pullLastReport(reports, containerId, eventId)?.reportedValue;
  };

  const isRequirementFilled = (containerId: string, event: EventUIData) => {
    const { isMandatory } = event;
    if (!isMandatory) return true;
    const value = getReportedValue(containerId, event);
    return Boolean([value].flat().filter(Boolean).length);
  };

  const hasDefaultValue = (event: EventUIData) => Boolean(event.defaultValue);

  const isChildTriggered = (containerId: string, event: EventUIData) => {
    const binding = visibilityBindings[event.visibilityBindingId!];
    if (!binding) return true;
    const { show: binds } = binding;
    return binds.some((bind) => {
      const reportKey = getReportCollectionKey(containerId, bind.triggerEventId);
      const triggerValue = getReportedValue(containerId, uiEvents[bind.triggerEventId]);
      const triggerIsValid = validity[reportKey];
      return isEventVisibleByBinding(bind, triggerValue, triggerIsValid);
    });
  };

  return useMemo(() => {
    const withDefault = new Map<ReportCollectionKey, EventUIData>();
    const withoutDefault = new Map<ReportCollectionKey, EventUIData>();

    Object.values(containers).forEach((container) => {
      if (!isApplicable(container)) return;

      const containerEvents = getEvents(container);
      const childEventIds = getChildIdSet(containerEvents);

      containerEvents.forEach((event) => {
        const isChild = childEventIds.has(event.eventId);
        const key = getReportCollectionKey(container.id, event.eventId);
        if (isChild && !isChildTriggered(container.id, event)) return;
        if (isRequirementFilled(container.id, event)) return;
        (hasDefaultValue(event) ? withDefault : withoutDefault).set(key, event);
      });
    });

    return { withDefault, withoutDefault };
  }, [containers, reports, uiEvents, validity]);
}
