import { useEffect, useRef, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { ReportValueType, DateElementData } from '@flow/flow-backend-types';
import { useUserDateFormat } from 'stores/settings';
import { getDateValidationColor, validateNumber } from 'stores/uiEvent';
import { exists } from 'utils';
import { InputEventProps } from '../types';

export const testIds = {
  getButtonTestId: (id: string) => `date-event-input-${id}`,
  clearButton: 'date-event-clear-button',
};

function getObjectValue(value?: string | null) {
  return exists(value) ? new Date(Number(value)) : null;
}

export const DateEvent = ({
  title,
  disabled,
  reportKey,
  validation,
  isEditing,
  lastEventReport,
  triggerFocus,
  triggerBlur,
  triggerReport,
}: InputEventProps<DateElementData>) => {
  const targetRef = useRef<HTMLButtonElement>(null);
  const dateFormat = useUserDateFormat();
  const lastReportedValue = lastEventReport?.reportedValue;
  const [value, setValue] = useState<Date | null>(getObjectValue(lastReportedValue));
  const eventColor = getDateValidationColor(value ?? undefined, validation);

  const onChangeDate = (newDate: Date | null) => {
    const newValue = newDate?.getTime();
    const valid = validateNumber(newValue, validation);
    triggerReport(
      {
        reportedValue: exists(newValue) ? String(newValue) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      valid,
    );
  };

  const onBlur = () => triggerBlur(targetRef.current?.value);

  useEffect(() => {
    if (!isEditing) setValue(getObjectValue(lastReportedValue));
  }, [lastReportedValue]);

  return (
    <DatePickerInput
      key={reportKey}
      ref={targetRef}
      classNames={{ input: '!border-2' }}
      styles={{ input: { borderColor: eventColor } }}
      allowDeselect
      clearable
      // @ts-ignore
      clearButtonProps={{ 'data-testid': testIds.clearButton }}
      disabled={disabled}
      label={title}
      placeholder={dateFormat}
      popoverProps={{ returnFocus: false, trapFocus: false }}
      value={value}
      valueFormat={dateFormat}
      onBlur={onBlur}
      onChange={onChangeDate}
      onFocus={triggerFocus}
      data-testid={testIds.getButtonTestId(reportKey)}
    />
  );
};
