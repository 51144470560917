import { ActionIcon, Paper } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import cn from 'classnames';

interface ImageMiniatureProps {
  id?: string;
  image: string;
  className?: string;
  disabled?: boolean;
  onDelete: () => void;
  onEdit: () => void;
}

export const testIds = {
  getWrapperTestId: (id?: string) => (id ? `${id}-image-mini-wrapper` : 'image-mini-wrapper'),
  image: 'image',
  edit: 'edit-button',
  delete: 'delete-button',
};

export const ImageMiniature = ({ id, image, className, disabled, onDelete, onEdit }: ImageMiniatureProps) => (
  <Paper
    pos='relative'
    w={100}
    h={100}
    withBorder
    radius='md'
    className={cn('shrink-0 overflow-hidden', className)}
    data-testid={testIds.getWrapperTestId(id)}
  >
    <img
      style={{ filter: disabled ? 'grayscale(0.5)' : 'none', opacity: disabled ? 0.5 : 1 }}
      className='transition-all'
      width='100%'
      height='100%'
      src={image}
      aria-label='captured image'
      data-testid={testIds.image}
    />
    {!disabled && (
      <>
        <ActionIcon
          pos='absolute'
          right={2}
          top={2}
          radius='xl'
          c='gray.7'
          bg='gray.1'
          size='xl'
          className='bg-opacity-50'
          aria-label='edit-button'
          data-testid={testIds.edit}
          onClick={onEdit}
        >
          <IconPencil />
        </ActionIcon>
        <ActionIcon
          pos='absolute'
          left={2}
          top={2}
          radius='xl'
          c='gray.7'
          bg='gray.1'
          size='xl'
          aria-label='delete-button'
          data-testid={testIds.delete}
          onClick={onDelete}
        >
          <IconTrash />
        </ActionIcon>
      </>
    )}
  </Paper>
);
