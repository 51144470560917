import qs from 'qs';
import {
  CancelExecutionResponse,
  FinishExecutionRequest,
  FinishExecutionResponse,
  GetExecutionsResponse,
  GetFlowsResponse,
  JoinExecutionRequest,
  JoinExecutionResponse,
  ReviewExecutionRequest,
  ReviewExecutionResponse,
  StartExecutionRequest,
  StartExecutionResponse,
} from '@flow/flow-backend-types';
import { HermesResponse } from '@aiola/frontend';
import { hermes } from 'services/hermes';
import { config } from 'services/config';
import { getTimeAgo } from './flow.utils';
import { Execution, ExecutionStatus } from './flow.types';

const flowApiUrl = config.getApiUrl();

const executionStatuses: ExecutionStatus[] = ['inProgress', 'inReview', 'done', 'expired', 'cancelled'];

const timeLimitedStatuses: ExecutionStatus[] = ['done', 'expired', 'cancelled'];

export const flowsApi = {
  fetchFlows: async () => {
    const { data } = await hermes.get<GetFlowsResponse>(`${flowApiUrl}/flows`);
    return data.items.filter((flow) => !!flow.activeVersion);
  },

  getExecutionById: async (flowExecutionId: string) => {
    const { data } = await hermes.get<Execution>(`${flowApiUrl}/executions/${flowExecutionId}`);
    return data;
  },

  fetchExecutions: async () => {
    const time = getTimeAgo(config.onGoingExecutionTimeLimit);
    const executions: Execution[] = [];

    const getOnce = async (status: ExecutionStatus, nextContinuationToken?: string) => {
      const queryString = {
        status,
        nextContinuationToken,
        minFinishDate: timeLimitedStatuses.includes(status) ? time.toString() : undefined,
      };

      const url = `${flowApiUrl}/executions?${qs.stringify(queryString)}`;
      const { data } = await hermes.get<GetExecutionsResponse>(url);
      executions.push(...data.executions);
      if (data.nextContinuationToken) await getOnce(status, data.nextContinuationToken);
    };

    await Promise.all(executionStatuses.map((status) => getOnce(status)));
    return executions;
  },

  startExecution: async (executionScheme: StartExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.put<StartExecutionResponse>(`${flowApiUrl}/executions/start`, executionScheme),

  reviewExecution: async (reviewExecutionRequest: ReviewExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.patch<ReviewExecutionResponse>(`${flowApiUrl}/executions/review`, reviewExecutionRequest),

  continueExecution: async (reviewExecutionRequest: ReviewExecutionRequest): Promise<HermesResponse<Execution>> =>
    hermes.patch<ReviewExecutionResponse>(`${flowApiUrl}/executions/continue`, reviewExecutionRequest),

  joinExecution: async (joinExecutionRequest: JoinExecutionRequest) => {
    const { data } = await hermes.put<JoinExecutionResponse>(`${flowApiUrl}/executions/join`, joinExecutionRequest);
    return data;
  },

  finishExecution: async (flowExecutionId: string) => {
    const body: FinishExecutionRequest = {
      flowExecutionId,
      postInspectionMetadata: [],
    };
    const { data } = await hermes.patch<FinishExecutionResponse>(`${flowApiUrl}/executions/finish`, body);
    return !!data;
  },

  cancelExecution: async (flowExecutionId: string) => {
    const { data } = await hermes.patch<CancelExecutionResponse>(`${flowApiUrl}/executions/cancel`, {
      flowExecutionId,
    });
    return !!data;
  },
};
