import { nanoid } from 'nanoid';
import { PendingAction } from '@flow/flow-backend-types';
import { PendingActionPayload } from './db.types';

export const createPendingAction = (action: PendingActionPayload): PendingAction => ({
  id: nanoid(),
  type: action.type,
  payload: action.payload,
  timestamp: Date.now(),
  retries: 0,
});
