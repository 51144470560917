import { ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCamera } from '@tabler/icons-react';
import { ImageUploadDrawer, testIds as drawerTestIds } from './ImageUploadDrawer/ImageUploadDrawer';

interface ImageCapturerProps {
  loading?: boolean;
  disabled?: boolean;
  onCapture?: (image: string) => void;
  onClick?: () => void;
}

export const testIds = {
  drawer: drawerTestIds,
  trigger: 'image-capturer-trigger',
};

export const ImageCapturer = ({ disabled, loading, onCapture, onClick }: ImageCapturerProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const onChange = (file: File | null) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      onCapture?.(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onClickTrigger = () => {
    open();
    onClick?.();
  };

  return (
    <>
      <ActionIcon
        size='xl'
        variant='transparent'
        disabled={disabled}
        loading={loading}
        onClick={onClickTrigger}
        data-testid={testIds.trigger}
      >
        <IconCamera size={24} strokeWidth={1.6} />
      </ActionIcon>
      <ImageUploadDrawer opened={opened} onClose={close} onUpload={onChange} />
    </>
  );
};
