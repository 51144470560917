import { Hermes } from '@aiola/frontend';

/**
 * Default global Hermes instance.
 *
 * @class Hermes
 *
 * @example
 * import { hermes } from 'hermes';
 *
 * const { data } = await hermes.get<object>('https://jsonplaceholder.typicode.com/todos/1', {});
 */
export const hermes = new Hermes();
