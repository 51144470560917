import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { SettingsValues } from './settings.types';
import { retrieveSettings, saveSettings } from './settings.utils';

interface SettingsState {
  settings: SettingsValues;
}

interface SettingsActions {
  getCurrentLanguage: () => string;
  updateSettings: (settings: Partial<SettingsValues>) => void;
  reset: () => void;
}

const initialState: SettingsState = {
  settings: retrieveSettings(),
};

export const settingsStore = create(
  immer<SettingsState & SettingsActions>((set, get) => ({
    ...initialState,
    updateSettings: (settings) => {
      const { settings: currentSettings } = get();
      const updatedSettings = { ...currentSettings, ...settings };
      saveSettings(updatedSettings);
      set({ settings: updatedSettings });
    },
    getCurrentLanguage: () => get().settings.language,
    reset: () => {
      set({ settings: retrieveSettings() });
    },
  })),
);

export const useSettingsStore = createStoreHook<SettingsState & SettingsActions>({ store: settingsStore, useShallow });
