import { Navigate, createBrowserRouter } from 'react-router-dom';
import { LoginPage } from 'pages';
import { config } from 'services/config';
import { ROUTES } from './routes.config';

export const publicRoutes = createBrowserRouter(
  [
    { path: '/', element: <Navigate to={ROUTES.LOGIN} /> },
    { path: ROUTES.LOGIN, element: <LoginPage /> },
    { path: '*', element: <Navigate to={ROUTES.ROOT} /> },
  ],
  { basename: config.baseUrl },
);
