import React from 'react';
import cn from 'classnames';
import { Card, Stack, UnstyledButton } from '@mantine/core';
import { GeneratedSource } from '@flow/flow-backend-types';
import { names, useSpy } from 'services/espionage';
import { UIContainer } from 'stores/container';
import { isApplicableByReport, pullLastReport } from 'stores/report/report.utils';
import { useGetMainEvent } from 'stores/uiEvent/uiEvent.selectors';
import { ItemPhotoProvider } from 'contexts/ItemPhotoContext';
import { useVoiceStore, VoiceMode } from 'stores/voice';
import { HiddenContentCollapse } from 'components';
import { useFocusData, useFocusStore } from 'stores/focus';
import { useDelayScroll } from 'hooks';
import { useReportStore } from 'stores/report';
import { ContainerDetails } from './ContainerDetails';
import { ContainerItemSummary } from '../ContainerItemSummary/ContainerItemSummary';
import classes from './ContainerItem.module.css';

interface ContainerItemProps {
  container: UIContainer;
  collapseContainerDetails?: boolean;
  displayContent?: boolean;
}

interface ContainerItemMemoProps extends ContainerItemProps {
  isFocused: boolean;
  displayBorder: boolean;
  isApplicable: boolean;
}

export const testIds = {
  itemCardRegex: /container-item-card-.+/,
  getItemCardId: (containerId: string) => `container-item-card-${containerId}`,
  getItemSummaryButtonId: (containerId: string) => `container-item-summary-${containerId}`,
};

const ContainerItemComponent = ({
  container,
  isFocused,
  displayBorder,
  isApplicable,
  displayContent,
  collapseContainerDetails,
}: ContainerItemMemoProps) => {
  const { focusContainer, blurContainer } = useFocusStore(['focusContainer', 'blurContainer']);
  const spy = useSpy();
  const ref = useDelayScroll<HTMLDivElement>(isFocused);
  const containerId = container.id;
  const containerTitle = container.title;
  const isNotApplicable = !isApplicable;
  const getMainEvent = useGetMainEvent();
  const mainEvent = getMainEvent(container);

  const handleContainerBiEvents = () => {
    if (!isFocused) {
      spy(
        names.ItemContainer.Focus,
        { [containerTitle]: containerTitle },
        { item_id: containerId, item_name: containerTitle },
      );
    }
  };

  const onClickSummary = () => {
    handleContainerBiEvents();
    if (isFocused) blurContainer();
    else focusContainer(containerId);
  };

  return (
    <ItemPhotoProvider containerId={containerId}>
      <Card
        key={containerId}
        ref={ref}
        w='100%'
        p='xs'
        radius={4}
        className={cn(classes.card, {
          [classes.focusedBorder]: displayBorder,
        })}
        data-testid={testIds.getItemCardId(containerId)}
      >
        <Stack h='100%' w='100%' gap={2}>
          <UnstyledButton w='100%' onClick={onClickSummary} data-testid={testIds.getItemSummaryButtonId(containerId)}>
            <ContainerItemSummary container={container} uiEvent={mainEvent} notApplicable={isNotApplicable} />
          </UnstyledButton>
          {displayContent &&
            (collapseContainerDetails ? (
              <HiddenContentCollapse open={isFocused}>
                <ContainerDetails container={container} notApplicable={isNotApplicable} />
              </HiddenContentCollapse>
            ) : (
              <ContainerDetails container={container} notApplicable={isNotApplicable} />
            ))}
        </Stack>
      </Card>
    </ItemPhotoProvider>
  );
};

const ContainerItemMemo = React.memo(ContainerItemComponent);

export const ContainerItem = (props: ContainerItemProps) => {
  const { reports } = useReportStore(['reports']);
  const { container } = props;
  const containerId = container.id;
  const { focusedContainerId, generatedSource } = useFocusData();
  const { mode } = useVoiceStore(['mode']);
  const isUiFocusSource = generatedSource === GeneratedSource.UI;
  const isVoiceFocusSource = generatedSource === GeneratedSource.VOICE;
  const isFocusedAllowedInMode =
    mode === VoiceMode.TAP_SPEECH || (mode === VoiceMode.FREE_SPEECH && (isUiFocusSource || isVoiceFocusSource));
  const isFocused = focusedContainerId === containerId;
  const displayBorder = isFocused && isFocusedAllowedInMode;
  const lastApplicabilityReport = pullLastReport(reports, containerId, container.uiEvents?.applicabilityEventId ?? '');
  const applicable = isApplicableByReport(lastApplicabilityReport);
  return <ContainerItemMemo {...props} isFocused={isFocused} displayBorder={displayBorder} isApplicable={applicable} />;
};
