import { IconCircleX, IconClockX, IconDeviceImacSearch, IconFileCheck, IconProps } from '@tabler/icons-react';
import { ExecutionStatus } from '@flow/flow-backend-types';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from 'stores/auth';
import { Execution } from 'stores/flow';
import { FC } from 'react';

interface BlockingModalProps {
  Icon: FC<IconProps>;
  name?: string;
  title: string;
  text: string;
  warning?: boolean;
}

export function useBlockingModalProps({ reviewedBy, finishedBy, status }: Execution): BlockingModalProps | null {
  const { t } = useTranslation();
  const { userId } = useCurrentUser()!;

  const reviewerName = `${reviewedBy?.givenName} ${reviewedBy?.familyName}`;
  const finisherName = `${finishedBy?.givenName} ${finishedBy?.familyName}`;

  switch (status) {
    case ExecutionStatus.cancelled:
      return {
        Icon: IconCircleX,
        name: finisherName,
        title: t('inspection.executionBlockedModal.canceled.title'),
        text: t('inspection.executionBlockedModal.canceled.text', { name: finisherName }),
        warning: true,
      };
    case ExecutionStatus.done:
      return {
        Icon: IconFileCheck,
        name: finisherName,
        title: t('inspection.executionBlockedModal.completed.title'),
        text: t('inspection.executionBlockedModal.completed.text', { name: finisherName }),
      };
    case ExecutionStatus.expired:
      return {
        Icon: IconClockX,
        title: t('inspection.executionBlockedModal.expired.title'),
        text: t('inspection.executionBlockedModal.expired.text'),
        warning: true,
      };
    case ExecutionStatus.inReview: {
      const isReviewedBySomeoneElse = reviewedBy?.userId !== userId;
      if (isReviewedBySomeoneElse)
        return {
          Icon: IconDeviceImacSearch,
          name: reviewerName,
          title: t('inspection.executionBlockedModal.review.title'),
          text: t('inspection.executionBlockedModal.review.text', { name: reviewerName }),
        };
      return null;
    }
    default:
      return null;
  }
}
