import cn from 'classnames';
import { Flex } from '@mantine/core';

import classes from './InspectionVoice.module.css';
import { VoiceModeSelector } from './VoiceModeSelector/VoiceModeSelector';

interface InspectionVoiceWrapperProps {
  error?: boolean;
  children: React.ReactNode;
}

export const testIds = {
  voiceWrapper: 'voice-wrapper',
};

export const VoiceWrapper = ({ error = false, children }: InspectionVoiceWrapperProps) => (
  <Flex
    pos='relative'
    align='center'
    className={cn(classes.voiceWrapper, {
      [classes.voiceError]: error,
    })}
    h={120}
    px={20}
    py={10}
    data-testid={testIds.voiceWrapper}
  >
    <VoiceModeSelector disabled={error} pos='absolute' top={0} right={5} />
    {children}
  </Flex>
);
