import { create, StoreApi, UseBoundStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { flowStore } from 'stores/flow';
import { createStoreHook } from '@aiola/frontend';
import { AudioMetadata, AudioSocket, ReportingContext, VoiceMode } from 'services/audioSocket';
import { focusStore } from 'stores/focus';
import { authStore } from 'stores/auth';
import { settingsStore } from 'stores/settings';
import { networkStore } from 'stores/network';
import { nanoid } from 'nanoid';
import { CountUpTime, VoiceErrorReason, VoiceStatus } from './voice.types';
import { MINUTES_BEFORE_HOUR, SECONDS_BEFORE_MINUTE } from './voice.const';
import {
  canTransition,
  getMicrophonePermission,
  getSavedVoiceMode,
  getWebSocketUrl,
  saveVoiceMode,
} from './voice.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'voice',
  store: 'voice',
  enabled: process.env.NODE_ENV === 'development',
};

interface VoiceState {
  audioSocket: AudioSocket;
  status: VoiceStatus;
  operationInProgress: boolean;
  pausedOn: VoiceStatus | null;
  activated: boolean;
  transcript: string;
  isMicPermitted?: boolean;
  voiceError?: VoiceErrorReason;
  counting: boolean;
  countUpTime: CountUpTime;
  mode: VoiceMode;
  reportingContext?: ReportingContext;
}

interface VoiceActions {
  listen: (context?: ReportingContext) => Promise<void>;
  activate: (context?: ReportingContext) => Promise<void>;
  close: (error?: VoiceErrorReason) => Promise<void>;
  pause: (savePreviousStatus?: boolean) => Promise<void>;
  resume: () => Promise<void>;
  updateStreamMetadata: (metadata: Partial<AudioMetadata>) => Promise<void>;
  grantMicrophonePermission: () => Promise<boolean>;
  initiateAudioSocket: () => Promise<boolean>;
  incrementTime: () => void;
  setMode: (mode: VoiceMode) => Promise<void>;
  clearVoiceError: () => void;
  /** Generate a new stream ID and update the existing socket. */
  initStream: () => void;
  /** Sets or removes the current recording context, and initiates a new stream. */
  setContext: (context?: ReportingContext) => void;
  reset: () => void;
}

const initialState: VoiceState = {
  audioSocket: AudioSocket.getInstance(),
  status: VoiceStatus.CLOSED,
  operationInProgress: false,
  pausedOn: null,
  activated: false,
  transcript: '',
  isMicPermitted: undefined,
  reportingContext: undefined,
  voiceError: undefined,
  counting: false,
  countUpTime: { hours: 0, minutes: 0, seconds: 0 },
  mode: getSavedVoiceMode(),
};

export const voiceStore = create(
  devtools(
    immer<VoiceState & VoiceActions>((set, get) => ({
      ...initialState,
      activate: async (context) => {
        const { activated, mode, listen, pause, initiateAudioSocket, grantMicrophonePermission } = get();
        if (activated) return;

        set({ voiceError: undefined });

        const micPermissionGranted = await grantMicrophonePermission();
        if (!micPermissionGranted) return;

        const audioSocketInitiated = await initiateAudioSocket();
        if (!audioSocketInitiated) return;

        set({ activated: true });
        if (mode === VoiceMode.FREE_SPEECH) await listen(context);
        else if (focusStore.getState().focusData?.containerId) await listen(context);
        else await pause();
      },
      listen: async (context) => {
        const { activated, audioSocket, status, operationInProgress, close, setContext } = get();
        if (operationInProgress || !activated || !canTransition(status, VoiceStatus.LISTENING)) return;

        set({ operationInProgress: true });

        try {
          audioSocket.onTranscript = (newTranscript) =>
            set(({ transcript }) => ({ transcript: `${transcript} ${newTranscript}` }));
          await audioSocket.startRecording();
          setContext(context);
          set((state) => {
            state.status = VoiceStatus.LISTENING;
            state.counting = true;
          });
        } catch (error) {
          console.error(error);
          close(VoiceErrorReason.Other);
        } finally {
          set({ operationInProgress: false });
        }
      },
      close: async (voiceError) => {
        const { audioSocket, status, operationInProgress, setContext } = get();
        if (!canTransition(status, VoiceStatus.CLOSED) || operationInProgress) return;

        set({ operationInProgress: true });

        try {
          setContext();
          await audioSocket.stopRecording();
          set((state) => {
            state.status = VoiceStatus.CLOSED;
            state.transcript = '';
            state.counting = false;
            state.countUpTime = { hours: 0, minutes: 0, seconds: 0 };
            state.activated = false;
            state.voiceError = voiceError;
          });
        } catch (error) {
          console.error(error);
        } finally {
          set({ operationInProgress: false });
        }
      },
      pause: async (savePreviousStatus) => {
        const { status, audioSocket, activated, operationInProgress } = get();
        if (!canTransition(status, VoiceStatus.PAUSED) || !activated || operationInProgress) return;

        set({ operationInProgress: true });

        try {
          await audioSocket.stopRecording();
          set((state) => {
            state.status = VoiceStatus.PAUSED;
            state.pausedOn = savePreviousStatus ? status : null;
            state.counting = false;
          });
        } catch (error) {
          console.error(error);
        } finally {
          set({ operationInProgress: false });
        }
      },
      resume: async () => {
        const { listen, pausedOn, reportingContext } = get();
        set({ pausedOn: null });
        if (pausedOn === VoiceStatus.LISTENING) await listen(reportingContext);
      },
      grantMicrophonePermission: async () => {
        const { isMicPermitted } = get();
        if (isMicPermitted) return true;
        const { permitted, error } = await getMicrophonePermission();
        set({ isMicPermitted: permitted, voiceError: error });
        return permitted;
      },
      initiateAudioSocket: async () => {
        const { mode, audioSocket, close } = get();
        const { flows, executions, currentExecutionId } = flowStore.getState();

        if (!networkStore.getState().online) {
          close(VoiceErrorReason.NetworkError);
          return false;
        }

        if (!currentExecutionId) {
          close();
          return false;
        }

        const flow = flows[executions[currentExecutionId].flowRef.id];
        const languageCode = settingsStore.getState().getCurrentLanguage();
        const { currentUser, sessionId } = authStore.getState();

        const urlParams = getWebSocketUrl({
          flowSessionId: sessionId!,
          flowId: flow.id,
          version: flow.activeVersion,
          languageCode,
          voiceMode: mode,
          userId: currentUser?.userId ?? '',
          executionId: currentExecutionId,
          frameFormat: 'debug',
        });

        audioSocket.onError = (error) => {
          console.error(error);
        };

        await audioSocket.connectWebSocket(urlParams);

        return true;
      },
      updateStreamMetadata: async (metadata) => {
        const { audioSocket } = get();
        audioSocket.metadata = metadata;
      },
      initStream: async () => {
        get().updateStreamMetadata({ streamId: nanoid() });
      },
      setMode: async (newMode: VoiceMode) => {
        const { mode, activate, activated, audioSocket } = get();
        if (newMode === mode) return;

        await audioSocket.disconnectWebSocket();

        if (activated) {
          set({ activated: false, mode: newMode });
          await activate();
        } else {
          set({ mode: newMode });
        }
        saveVoiceMode(newMode);
      },
      incrementTime: () => {
        const { seconds, minutes } = get().countUpTime;
        set((state) => {
          state.countUpTime.seconds += 1;
          if (seconds === SECONDS_BEFORE_MINUTE) {
            state.countUpTime.seconds = 0;
            state.countUpTime.minutes += 1;
          }
          if (minutes === MINUTES_BEFORE_HOUR && seconds === SECONDS_BEFORE_MINUTE) {
            state.countUpTime.minutes = 0;
            state.countUpTime.hours += 1;
          }
        });
      },
      setContext: (context) => {
        get().initStream();
        get().audioSocket.context = context;
        set({ reportingContext: context });
      },
      clearVoiceError: () => {
        set({ voiceError: undefined });
      },
      reset: () => {
        get().audioSocket.disconnectWebSocket();
        set({ ...initialState });
      },
    })),
    devtoolsOptions,
  ),
);

export const useVoiceStore = createStoreHook<VoiceState & VoiceActions>({
  store: voiceStore as UseBoundStore<StoreApi<VoiceState & VoiceActions>>,
  useShallow,
});
