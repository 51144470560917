import en_us from 'assets/locales/en-us/translation.json';
import fr_ca from 'assets/locales/fr-ca/translation.json';
import zh_cn from 'assets/locales/zh-cn/translation.json';
import pt_br from 'assets/locales/pt-br/translation.json';
import id from 'assets/locales/id/translation.json';
import { retrieveSettings } from 'stores/settings/settings.utils';
import { buildResourcesObject, initI18n } from '@aiola/frontend';

export type FlowAppEnglishTranslations = typeof en_us;
export const resources = buildResourcesObject([
  ['en-us', en_us],
  ['fr-ca', fr_ca],
  ['zh-cn', zh_cn],
  ['pt-br', pt_br],
  ['id', id],
]);

export default initI18n({
  detector: () => retrieveSettings().language,
  resources,
});
