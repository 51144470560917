import {
  EventId,
  DropdownElementData,
  NumericElementData,
  ButtonsElementData,
  DateElementData,
  TextElementData,
  EventUIElement,
  ImageElementData,
  ApplicabilityElementData,
  MultiSelectElementData,
  ValueItem,
  VisibilityBinding,
  VisibilityBindingId,
} from '@flow/flow-backend-types';

// CLIENT INTERFACES
export interface ButtonsEventData extends EventUIElement {
  type: 'ButtonsEvent';
  elementData: ButtonsElementData;
}

export interface DropdownEventData extends EventUIElement {
  type: 'DropdownEvent';
  elementData: DropdownElementData;
}

export interface TextEventData extends EventUIElement {
  type: 'TextEvent';
  elementData: TextElementData;
}

export interface NumericEventData extends EventUIElement {
  type: 'NumericEvent';
  elementData: NumericElementData;
}

export interface DateEventData extends EventUIElement {
  type: 'DateEvent';
  elementData: DateElementData;
}

export interface ImageEventData extends EventUIElement {
  type: 'ImageEvent';
  elementData: ImageElementData;
}

export interface ApplicabilityEventData extends EventUIElement {
  type: 'ApplicabilityEvent';
  elementData: ApplicabilityElementData;
}

export interface MultiSelectEventData extends EventUIElement {
  type: 'MultiSelectEvent';
  elementData: MultiSelectElementData;
}

export interface AppendTextEventData extends EventUIElement {
  type: 'AppendTextEvent';
  elementData: TextElementData;
}

export type EventUIData =
  | ButtonsEventData
  | DropdownEventData
  | TextEventData
  | NumericEventData
  | DateEventData
  | MultiSelectEventData
  | ImageEventData
  | ApplicabilityEventData
  | AppendTextEventData;

export interface ReportedImage {
  id: string;
  original: string;
  edited?: string;
}

export enum ValidationFilter {
  Valid = 'valid',
  Invalid = 'invalid',
  Checked = 'checked',
  Unchecked = 'unchecked',
}

export interface EventFilter {
  eventId: EventId;
  title?: string;
  values: ValueItem[];
  hasValidations: boolean;
}

export interface FilterValue {
  eventId: EventId;
  valueFilters: string[];
  validationFilters: ValidationFilter[];
}

export type UIEventsMap = Record<EventId, EventUIData>;
export type VisibilityBindingsMap = Record<VisibilityBindingId, VisibilityBinding>;
