import { ReactNode } from 'react';
import { Grid, Image } from '@mantine/core';
import { UserAvatar } from 'components';
import { config } from 'services/config';

export const testIds = {
  landscapeNavbar: 'landscape-navbar',
  userAvatar: 'navbar-userAvatar',
  aiolaLogo: 'navbar-aiola-logo',
  clientLogo: 'navbar-client-logo',
};

interface LandscapeNavbarProps {
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  underLogoSection?: ReactNode;
}

export const LandscapeNavbar = ({ leftSection, rightSection, underLogoSection }: LandscapeNavbarProps) => (
  <Grid w='100%' p='tn' classNames={{ inner: 'flex items-center h-[60px]' }} data-testid={testIds.landscapeNavbar}>
    <Grid.Col p={0} span={2} className='flex items-center'>
      {leftSection}
    </Grid.Col>
    <Grid.Col p={0} span={8} className='flex flex-col justify-center items-center'>
      <Image src={`${config.clientLogoUrl}`} h={32} w={64} data-testid={testIds.clientLogo} fallbackSrc='/logo.svg' />
      {underLogoSection}
    </Grid.Col>
    <Grid.Col p={0} span={2} className='flex justify-end'>
      {rightSection}
      <UserAvatar />
    </Grid.Col>
  </Grid>
);
