import { Card, CardProps, ElementProps, Rating, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconMoodCry, IconMoodHappy, IconMoodNeutral } from '@tabler/icons-react';
import { Information } from '../Information/Information';

interface RatingCardProps extends CardProps, Omit<ElementProps<'div', keyof CardProps>, 'onChange'> {
  value?: number;
  comment?: string;
  onChangeValue: (value?: number) => void;
  onChangeComment: (comment?: string) => void;
}

export const testIds = {
  card: 'rating-card',
  title: 'rating-card-title',
  comment: 'rating-card-comment',
  getSelectedTestId: (value: number) => `rating-card-${value}-selected`,
  getUnselectedTestId: (value: number) => `rating-card-${value}-unselected`,
};

const getIconStyle = (color?: string) => ({
  width: 40,
  height: 40,
  color: color ? `var(--mantine-color-${color}-7)` : undefined,
});

const getEmptyIcon = (value: number) => {
  const iconStyle = getIconStyle();

  switch (value) {
    case 1:
      return <IconMoodCry style={iconStyle} data-testid={testIds.getUnselectedTestId(1)} />;
    case 2:
      return <IconMoodNeutral style={iconStyle} data-testid={testIds.getUnselectedTestId(2)} />;
    case 3:
      return <IconMoodHappy style={iconStyle} data-testid={testIds.getUnselectedTestId(3)} />;
    default:
      return null;
  }
};

const getFullIcon = (value: number) => {
  switch (value) {
    case 1:
      return <IconMoodCry style={getIconStyle('red')} data-testid={testIds.getSelectedTestId(1)} />;
    case 2:
      return <IconMoodNeutral style={getIconStyle('blue')} data-testid={testIds.getSelectedTestId(2)} />;
    case 3:
      return <IconMoodHappy style={getIconStyle('green')} data-testid={testIds.getSelectedTestId(3)} />;
    default:
      return null;
  }
};

export const RatingCard = ({ value, comment, onChangeValue, onChangeComment, ...props }: RatingCardProps) => {
  const { t } = useTranslation();

  return (
    <Card data-testid={testIds.card} {...props}>
      <Information label={t('summary.items.rating')} data-testid={testIds.title} />
      <Rating
        value={value}
        count={3}
        className='m-auto gap-5 mt-2'
        emptySymbol={getEmptyIcon}
        fullSymbol={getFullIcon}
        highlightSelectedOnly
        onChange={onChangeValue}
      />
      {Boolean(value) && (
        <TextInput
          value={comment}
          placeholder={t('summary.items.comment')}
          onChange={(e) => onChangeComment(e.currentTarget.value)}
          data-testid={testIds.comment}
        />
      )}
    </Card>
  );
};
