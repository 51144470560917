import { Button, Divider, Input, Text, useMantineTheme, ComboboxItem } from '@mantine/core';
import { Fragment, useEffect, useId, useMemo, useRef, ReactNode } from 'react';
import cn from 'classnames';

export interface ToggleItem extends ComboboxItem {
  defaultColor?: string;
  selectedColor?: string;
}

interface ToggleProps {
  data: ReadonlyArray<ToggleItem>;
  value?: string;
  name?: string;
  label?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (value: string | null) => void;
}

export const testIds = {
  group: 'toggle-button-group',
  getButtonTestId: (value: string) => `toggle-button-${value}`,
  input: 'toggle-input',
};

export const Toggle = ({ data, value, name, label, fullWidth = true, disabled, onChange, ...props }: ToggleProps) => {
  const id = useId();
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useMantineTheme();

  const handleChange = (newValue: string) => {
    const nextValue = newValue === value ? null : newValue;
    onChange?.(nextValue);
  };

  const style = useMemo(
    () => ({
      borderColor: theme.colors.gray[3],
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: (theme.components.Input.styles as any)?.input!.borderRadius,
      overflow: 'hidden',
    }),
    [theme],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value || '';
    }
  }, [value]);

  return (
    <Input.Wrapper id={id} label={label}>
      <input id={id} ref={inputRef} name={name} hidden data-testid={testIds.input} {...props} />
      <Button.Group
        h={(theme.components.Input.styles as any)?.input.minHeight}
        w={fullWidth ? '100%' : 'fit-content'}
        style={style}
        data-testid={testIds.group}
      >
        {data.map((item, index) => (
          <Fragment key={item.value}>
            <Button
              className={cn('transition-colors', fullWidth && 'grow basis-0 shrink-0 min-w-0 px-2')}
              disabled={disabled ?? item.disabled}
              h='100%'
              color={item.value === value ? item.selectedColor : item.defaultColor}
              name={item.value}
              radius={0}
              role='radio'
              variant={item.value === value ? 'filled' : 'subtle'}
              onClick={() => handleChange(item.value)}
              aria-checked={item.value === value}
              data-testid={testIds.getButtonTestId(item.value)}
            >
              <Text size='sm' fw={500} truncate>
                {item.label}
              </Text>
            </Button>
            {index < data.length - 1 && <Divider orientation='vertical' />}
          </Fragment>
        ))}
      </Button.Group>
    </Input.Wrapper>
  );
};
