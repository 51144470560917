import { Flex, Title, Divider, TitleOrder } from '@mantine/core';

export const testIds = {
  getLandscapeExecutionDetailsName: (flowTitle?: string) => `landscape-execution-details-name-${flowTitle}`,
  getLandscapeExecutionDetailsIdentifier: (uniqueIdentifier?: string) =>
    `landscape-execution-details-identifier-${uniqueIdentifier}`,
  getExecutionDetailsDivider: (flowTitle?: string) => `execution-details-divider-${flowTitle}`,
};

export interface ExecutionDetailsProps {
  flowTitle?: string;
  uniqueIdentifier?: string;
  order?: TitleOrder;
}

export const ExecutionDetails = ({ flowTitle, uniqueIdentifier, order = 5 }: ExecutionDetailsProps) => (
  <Flex align='center' gap={8.5}>
    <Title order={order} fw={500} data-testid={testIds.getLandscapeExecutionDetailsName(flowTitle)}>
      {flowTitle}
    </Title>
    <Divider orientation='vertical' color='black' data-testid={testIds.getExecutionDetailsDivider(flowTitle)} />
    <Title
      size='lg'
      pt='3px'
      c='green.9'
      fw={500}
      className='grow break-all'
      data-testid={testIds.getLandscapeExecutionDetailsIdentifier(uniqueIdentifier)}
    >
      {uniqueIdentifier}
    </Title>
  </Flex>
);
