import ReactDOM from 'react-dom/client';
import { AppProviders } from 'providers/app';
import App from './App/App';
import './index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AppProviders>
    <App />
  </AppProviders>,
);
