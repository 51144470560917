import { useEffect, useRef } from 'react';
import { config } from 'services/config';
import { useNetworkStore } from 'stores/network';

export const useConnectionChecker = () => {
  const { checkConnection } = useNetworkStore(['checkConnection']);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const checkAndSchedule = async () => {
      await checkConnection();
      timeoutRef.current = window.setTimeout(checkAndSchedule, config.pingInterval);
    };

    checkAndSchedule();

    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);
};
