import { Box, Title, Text, TitleOrder } from '@mantine/core';

export const testIds = {
  getPortraitExecutionDetailsName: (flowTitle?: string) => `portrait-execution-details-name-${flowTitle}`,
  getPortraitExecutionDetailsIdentifier: (uniqueIdentifier?: string) =>
    `portrait-execution-details-identifier-${uniqueIdentifier}`,
};

export interface ExecutionDetailsProps {
  flowTitle?: string;
  uniqueIdentifier?: string;
  order?: TitleOrder;
}

export const ExecutionDetails = ({ flowTitle, uniqueIdentifier, order = 5 }: ExecutionDetailsProps) => (
  <Box>
    <Title order={order} data-testid={testIds.getPortraitExecutionDetailsName(flowTitle)}>
      {flowTitle}
    </Title>
    <Text
      size='lg'
      c='green.9'
      fw={400}
      lineClamp={1}
      className='grow break-all'
      data-testid={testIds.getPortraitExecutionDetailsIdentifier(uniqueIdentifier)}
    >
      {uniqueIdentifier}
    </Text>
  </Box>
);
