import { Flex, Highlight, Image, MantineColor, MantineTheme, Paper } from '@mantine/core';
import { logoLoopDark } from 'assets';

interface EmptyStateProps {
  highlight?: string;
  highlightColor?: MantineColor | ((theme: MantineTheme) => MantineColor);
  visible: boolean;
  children: string;
}

export const testIds = {
  container: 'empty-state-container',
  logo: 'empty-state-logo',
  title: 'empty-state-title',
};

export const EmptyState = ({
  children,
  highlight = '',
  highlightColor = 'black',
  visible,
  ...props
}: EmptyStateProps) => {
  if (!visible) return null;
  return (
    <Paper withBorder mt='sm' p={48} data-testid={testIds.container} {...props}>
      <Flex direction='column' align='center' gap='tn' py='sm'>
        <Image
          w={80}
          src={logoLoopDark}
          className='animate-[spin_3s_infinite_ease-in-out]'
          data-testid={testIds.logo}
        />
        <Highlight
          className='text-center'
          size='lg'
          c='gray.6'
          fw='500'
          highlight={highlight}
          highlightStyles={(theme) => ({
            c: typeof highlightColor === 'function' ? highlightColor(theme) : theme.colors[highlightColor],
            backgroundColor: 'unset',
          })}
          data-testid={testIds.title}
        >
          {children}
        </Highlight>
      </Flex>
    </Paper>
  );
};
