import { Card, CardProps, Divider, Group } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useFlowStore } from 'stores/flow';
import { useSettingsStore } from 'stores/settings';
import { useUserDateFormat } from 'stores/settings/settings.selectors';
import { testIds } from '../SummaryPage';
import { Information } from '../Information/Information';

export const TimeCard = (props: CardProps) => {
  const { t } = useTranslation();
  const { executionId } = useParams() as ExecutionRouteParams;
  const execution = useFlowStore((state) => state.executions[executionId]);
  const language = useSettingsStore((state) => state.settings.language);
  const dateFormat = useUserDateFormat();

  const [startTime, endTime, totalTime] = useMemo(() => {
    const format = `${dateFormat} HH:mm`;
    const now = Date.now();
    return [
      dayjs(execution.createdAt).format(format),
      dayjs(now).format(format),
      dayjs
        .duration(now - execution.createdAt)
        .locale(language)
        .humanize(),
    ];
  }, [dateFormat, language, execution.createdAt]);

  return (
    <Card data-testid={testIds.executionInfo} {...props}>
      <Information label={t('summary.items.totalTime')} data-testid={testIds.items.totalTime}>
        {totalTime}
      </Information>
      <Divider my='tn' />
      <Group grow>
        <Information label={t('summary.items.startTime')} data-testid={testIds.items.startDate}>
          {startTime}
        </Information>
        <Information label={t('summary.items.endTime')} data-testid={testIds.items.endDate}>
          {endTime}
        </Information>
      </Group>
    </Card>
  );
};
