import { buildCypher } from './espionage.cypher';

const nameEntries = [
  { PageView: ['LoadingComplete', 'RenderingComplete'] },
  { Login: ['Initialization', 'Login'] },
  { Header: ['OpenMenu', 'OpenSettings', 'Logout', 'ResetInspection'] },
  { Settings: ['ChangeLanguage', 'Save', 'Cancel', 'SettingsDrawer', 'LanguagePopup'] },
  {
    AiolaWidget: [
      'Activated',
      'Deactivated',
      'ChangeStatus',
      'VoiceModeOpen',
      'VoiceModeClose',
      'VoiceModeInfo',
      'UploadFile',
      'UploadModalOpen',
      'UploadModalClosed',
      'CancelUpload',
    ],
  },
  { InspectionSelection: ['Tab', 'Select'] },
  { PreInspection: ['Submit', 'Back'] },
  { PostInspection: ['Submit', 'Back'] },
  {
    InspectionList: [
      'Continue',
      'Back',
      'GroupingContainerExpand',
      'GroupingContainerCollapse',
      'FilterSelect',
      'FilterDrawer',
      'FilterApply',
      'FilterReset',
      'FilterClose',
      'NewDynamicItemContainer',
      'MandatoryPopup',
      'DefaultPopup',
      'InspectionDataError',
    ],
  },
  { Review: ['Back', 'Continue'] },
  { Summary: ['Back', 'Submit', 'Rating'] },
  {
    ItemContainer: [
      'Focus',
      'State',
      'OpenCamera',
      'DeletePhoto',
      'EditPhoto',
      'EventValue',
      'ManualEdit',
      'ManualEditDone',
      'LogOfEvents',
      'LogOfEventsDrawer',
      'LogOfEventsClose',
    ],
  },
  { Camera: ['CloseCamera', 'TakePhoto'] },
  { Screen: ['PageOrientation'] },
  { Pages: ['Login', 'InspectionSelection', 'PreInspection', 'InspectionList', 'Review', 'Summary'] },
] as const;

/**
 * A cypher object containing a nested tree of names as required by for BI events.
 */
export const names = buildCypher(nameEntries);
