import { MantineThemeOverride, rem, DEFAULT_THEME } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { DEFAULT_TRANSITION_DURATION } from 'consts';
import { testIds as toasterTestIds } from 'services/toaster/toaster.class';
import { MODAL_TEST_ID } from 'services/modalManager/modalManager.class';

export const componentTheme: Pick<MantineThemeOverride, 'components'> = {
  components: {
    Avatar: {
      defaultProps: {
        radius: 'xl',
      },
      styles: {
        placeholder: {
          fontWeight: 500,
        },
      },
    },
    Card: {
      defaultProps: {
        shadow: 'md',
        withBorder: true,
      },
    },
    Badge: {
      defaultProps: {
        radius: 'xs',
      },
    },
    Popover: {
      defaultProps: {
        withinPortal: true,
        shadow: 'sm',
      },
    },
    Input: {
      styles: {
        input: {
          fontSize: rem(16),
          minHeight: rem(48),
          borderRadius: rem(2),
        },
      },
    },
    InputWrapper: {
      styles: {
        description: {
          display: 'inline-block',
          marginLeft: rem(8),
        },
        label: {
          color: DEFAULT_THEME.colors.gray[6],
        },
      },
    },
    NumberInput: {
      defaultProps: {
        hideControls: true,
        rightSectionWidth: rem(36),
      },
    },
    Select: {
      defaultProps: {
        rightSection: <IconChevronDown />,
      },
    },
    Transition: {
      defaultProps: {
        duration: DEFAULT_TRANSITION_DURATION,
      },
    },
    Overlay: {
      defaultProps: {
        backgroundOpacity: 0.7,
      },
    },
    Notification: {
      defaultProps: {
        withBorder: true,
        'data-testid': toasterTestIds.toast,
        closeButtonProps: { style: { alignSelf: 'start', color: 'black' } },
      },
    },
    Modal: {
      defaultProps: {
        'data-testid': MODAL_TEST_ID,
      },
    },
  },
};
