import { ChangeEvent } from 'react';
import { Checkbox } from '@mantine/core';
import { ReportValueType } from '@flow/flow-backend-types';
import { names, useSpy } from 'services/espionage';
import { isApplicableByReport } from 'stores/report';
import { useTranslation } from 'react-i18next';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { BaseEventProps } from '../types';
import classes from './ApplicabilityEvent.module.css';

export const testIds = {
  applicableSwitch: (eventId?: string) => `applicableSwitch-${eventId}`,
  tooltip: 'applicable-tooltip',
};

export interface ApplicabilityEventProps extends BaseEventProps {
  eventId?: string;
}

export const ApplicabilityEvent = ({ eventId, lastEventReport, triggerReport }: ApplicabilityEventProps) => {
  const { t } = useTranslation();
  const spy = useSpy();
  const value = isApplicableByReport(lastEventReport);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    const reportedValue = newValue ? ApplicabilityReportValue.APPLICABLE : ApplicabilityReportValue.NOT_APPLICABLE;
    spy(names.ItemContainer.State, reportedValue);
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
  };
  return (
    <Checkbox
      classNames={{
        body: classes.body,
        inner: classes.inner,
        label: classes.label,
      }}
      checked={value}
      size='md'
      onChange={onChange}
      label={t('common.applicable')}
      data-testid={testIds.applicableSwitch(eventId)}
    />
  );
};
