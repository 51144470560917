import { useDocumentVisibility } from '@mantine/hooks';
import { useEffect } from 'react';
import { config } from 'services/config';
import { useVoiceStore } from 'stores/voice';
import { exists } from 'utils';

export const SS_BG_TIME_KEY = 'aiola-background-time';
export function useIsAppInBackground() {
  const visibility = useDocumentVisibility();
  const { close } = useVoiceStore(['close']);

  const shouldReload = (lastTimestamp: number) => Date.now() - lastTimestamp > config.backgroundRefreshTime;

  useEffect(() => {
    const lastWentToBackgroundAt = sessionStorage.getItem(SS_BG_TIME_KEY);

    if (visibility === 'hidden') {
      console.info('%cBackground 🙈', 'color: #00ffff');
      close();
      if (!lastWentToBackgroundAt) sessionStorage.setItem(SS_BG_TIME_KEY, String(Date.now()));
    } else if (visibility === 'visible') {
      console.info('%cForeground 🐵', 'color: #00ffff');
      sessionStorage.removeItem(SS_BG_TIME_KEY);
      if (exists(lastWentToBackgroundAt) && shouldReload(Number(lastWentToBackgroundAt))) {
        window.location.reload();
      }
    }
  }, [visibility]);

  useEffect(() => () => sessionStorage.removeItem(SS_BG_TIME_KEY), []);
}
