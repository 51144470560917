import { Transaction } from 'dexie';

/*
 * Migrate to version 2
 * - Create a table for dynamic containers
 * - Change the schema of the flowMetadata table to have a compound primary key
 * - Create a temporary table to store the old data
 * - Copy data from flowMetadata to tempFlowMetadata
 */
export const migrateToV2 = async (trans: Transaction) => {
  // Copy data from flowMetadata to tempFlowMetadata
  const oldItems = await trans.table('flowMetadata').toArray();
  await trans.table('tempFlowMetadata').bulkAdd(oldItems);
};

/*
 * Migrate to version 3
 * - Copy data from tempFlowMetadata to flowMetadata
 * - Remove the temporary table
 */
export const migrateToV3 = async (trans: Transaction) => {
  // Copy data from tempFlowMetadata to flowMetadata
  const tempItems = await trans.table('tempFlowMetadata').toArray();
  await trans.table('flowMetadata').bulkAdd(tempItems);

  // Remove the temporary table
  await trans.table('tempFlowMetadata').clear();
};
