import { useEffect, useRef, useState } from 'react';
import aiolaSplashVideo from 'assets/videos/aiolaSplashVideo.mp4';
import aiolaSplashVideoLoop from 'assets/videos/aiolaSplashVideoLoop.mp4';

const INITIAL_VIDEO_DURATION = 6000;

export const testIds = {
  video: 'splash-video',
  videoLoop: 'splash-video-loop',
};

export const SplashVideo = () => {
  const [overlayed, setOverlayed] = useState(false);
  const videoLoopRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setTimeout(() => videoLoopRef.current?.play(), INITIAL_VIDEO_DURATION);
  }, []);

  return (
    <>
      <video
        autoPlay
        muted
        playsInline
        onEnded={() => setOverlayed(true)}
        className='w-screen h-screen object-cover fixed z-10'
        data-testid={testIds.video}
      >
        <source src={aiolaSplashVideo} type='video/mp4' />
      </video>
      <video
        ref={videoLoopRef}
        muted
        loop
        playsInline
        className={`w-screen h-screen object-cover fixed ${overlayed && 'z-10'}`}
        data-testid={testIds.videoLoop}
      >
        <source src={aiolaSplashVideoLoop} type='video/mp4' />
      </video>
    </>
  );
};
