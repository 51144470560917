import { Button, CloseButton, Drawer, Divider, Title, Flex, Input, ScrollArea } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INSPECTION_PAGE_LANDSCAPE_PERCENTAGE } from 'consts';
import { useAppStore } from 'stores/app';
import { SingleChoiceOptions } from './SingleChoiceOptions';
import { MultiChoiceOptions } from './MultiChoiceOptions';

export const testIds = {
  drawerContainer: 'optionListDrawerContainer',
  drawerFilter: 'optionListFilter',
  drawerTitle: 'optionListTitle',
  drawerClearButton: 'optionListClearButton',
  drawerCloseButton: 'optionListCloseButton',
};

const { LEFT_SECTION_WIDTH } = INSPECTION_PAGE_LANDSCAPE_PERCENTAGE;

interface OptionListDrawerProps {
  opened: boolean;
  data?: string[];
  options: { value: string; label: string }[];
  reportKey: string;
  isMultiOptions?: boolean;
  onUpdate: (data: string | null) => void;
  onClose: () => void;
  onClear: () => void;
}

export const OptionListDrawer = ({
  opened,
  data,
  options,
  reportKey,
  isMultiOptions = false,
  onUpdate,
  onClose,
  onClear,
}: OptionListDrawerProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const { isLandscape } = useAppStore(['isLandscape']);
  const filteredOptions = options?.filter(({ label }) => label.toLowerCase().includes(searchValue.toLowerCase()));
  const resetSearch = () => setSearchValue('');

  const closeOptionList = () => {
    resetSearch();
    onClose();
  };

  return (
    <Drawer.Root
      opened={opened}
      trapFocus={false}
      position={isLandscape ? 'right' : 'bottom'}
      size={isLandscape ? LEFT_SECTION_WIDTH : '90%'}
      onClose={closeOptionList}
      data-testid={testIds.drawerContainer}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Flex direction='column' className='h-full'>
          <Drawer.Header py='xs'>
            <Input
              value={searchValue}
              leftSection={<IconSearch size={22} />}
              rightSectionPointerEvents='all'
              rightSection={searchValue && <CloseButton onClick={resetSearch} />}
              placeholder={t('common.search')}
              variant='filled'
              className='w-full'
              onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
              data-testid={testIds.drawerFilter}
            />
          </Drawer.Header>
          <Divider />
          <Drawer.Body className='flex flex-col flex-grow overflow-hidden'>
            <Title order={5} mt='sm' data-testid={testIds.drawerTitle}>
              {t('inspection.eventName')}
            </Title>
            <ScrollArea type='scroll' mb='md' className='flex-grow'>
              {isMultiOptions ? (
                <MultiChoiceOptions options={filteredOptions} reportKey={reportKey} values={data} onUpdate={onUpdate} />
              ) : (
                <SingleChoiceOptions
                  options={filteredOptions}
                  reportKey={reportKey}
                  value={data}
                  onUpdate={(value) => {
                    onUpdate(value);
                    resetSearch();
                  }}
                />
              )}
            </ScrollArea>
            <Flex justify='space-between'>
              <Button variant='subtle' size='md' onClick={onClear} data-testid={testIds.drawerClearButton}>
                {t('common.clear')}
              </Button>
              <Button variant='subtle' size='md' onClick={closeOptionList} data-testid={testIds.drawerCloseButton}>
                {t('common.close')}
              </Button>
            </Flex>
          </Drawer.Body>
        </Flex>
      </Drawer.Content>
    </Drawer.Root>
  );
};
