import { useEffect, useRef } from 'react';
import { CONTAINER_SCROLL_DELAY } from 'consts';

export function useDelayScroll<T extends HTMLElement>(scroll: boolean, ms: number = CONTAINER_SCROLL_DELAY) {
  const ref = useRef<T>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (scroll) {
      timer = setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, ms);
    }
    return () => clearTimeout(timer);
  }, [scroll, ms]);

  return ref;
}
