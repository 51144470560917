import { Divider, Text, UnstyledButton, Radio } from '@mantine/core';
import { Fragment } from 'react';

export const testIds = {
  getItemOptionId: (id: string) => `singleChoice-option-${id}`,
};

interface SingleChoiceOptionsProps {
  options: { value: string; label: string }[];
  value?: string[];
  reportKey: string;
  onUpdate: (optionValue: string | null) => void;
}

export const SingleChoiceOptions = ({ options, value, reportKey, onUpdate }: SingleChoiceOptionsProps) => (
  <Radio.Group value={value?.[0] ?? undefined}>
    {options?.map(({ value: optionValue, label }) => (
      <Fragment key={reportKey + optionValue}>
        <UnstyledButton
          className='flex items-center w-full'
          onClick={() => onUpdate(optionValue)}
          data-testid={testIds.getItemOptionId(reportKey + optionValue)}
        >
          <Radio value={optionValue} size='md' py='lg' color='green.9' />
          <Text pl='sm'>{label}</Text>
        </UnstyledButton>
        <Divider />
      </Fragment>
    ))}
  </Radio.Group>
);
