import { DatePickerInput, DateValue } from '@mantine/dates';
import { ComboboxItem, MultiSelect, NumberInput, Select, Textarea, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { useUserDateFormat } from 'stores/settings';
import { FlowMetadataField, FlowMetadataFieldType } from '@flow/flow-backend-types';
import dayjs from 'dayjs';

interface RenderMetaFieldProps {
  field?: FlowMetadataField;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string | number | DateValue | ChangeEvent<HTMLElement> | string[]) => void;
  checked?: boolean;
  error?: string | null;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const testIds = {
  regex: /meta-field-.*/,
  getInputTestId: (id: string) => `meta-field-${id}`,
};

export const RenderMetaField = ({ field, value, defaultValue, ...props }: RenderMetaFieldProps) => {
  const { t } = useTranslation();
  const dateFormat = useUserDateFormat();
  const defaultPlaceholder = field?.type === FlowMetadataFieldType.Date ? dateFormat : t('placeholders.text');
  const getInputProps = () => ({
    defaultValue,
    name: field?.title,
    label: field?.title,
    autoComplete: 'off',
    required: field?.required,
    placeholder: field?.placeholder ?? defaultPlaceholder,
    'data-testid': testIds.getInputTestId(field?.id || ''),
    ...props,
  });

  const getComponent = () => {
    switch (field?.type) {
      case FlowMetadataFieldType.String:
        return <TextInput {...getInputProps()} />;
      case FlowMetadataFieldType.Text:
        return <Textarea {...getInputProps()} />;
      case FlowMetadataFieldType.Number:
        return (
          <NumberInput
            {...getInputProps()}
            inputMode='decimal'
            decimalScale={5}
            value={value ? Number(value) : undefined}
            defaultValue={defaultValue ? Number(defaultValue) : undefined}
          />
        );
      case FlowMetadataFieldType.Dropdown:
        return (
          <Select
            {...getInputProps()}
            searchable
            value={value}
            defaultValue={defaultValue}
            data={field.options?.map<ComboboxItem>((option) => ({ value: option, label: option }))}
          />
        );
      case FlowMetadataFieldType.MultiselectDropdown:
        return (
          <MultiSelect
            {...getInputProps()}
            searchable
            placeholder={value?.length ? '' : (field?.placeholder ?? defaultPlaceholder)}
            classNames={{ input: '!flex items-center' }}
            withAsterisk={field.required}
            defaultValue={defaultValue ? [defaultValue] : undefined}
            data={field.options?.map<ComboboxItem>((option) => ({ value: option, label: option }))}
          />
        );
      case FlowMetadataFieldType.Date: {
        const { onChange, ...rest } = getInputProps();
        return (
          <DatePickerInput
            {...rest}
            valueFormat={dateFormat}
            defaultValue={defaultValue ? new Date(Number(defaultValue)) : undefined}
            onChange={(date) => onChange?.(dayjs(date).format(dateFormat))}
          />
        );
      }
      default:
        return null;
    }
  };

  return getComponent();
};
