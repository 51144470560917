import { VoiceStatus } from './voice.types';

export const ONE_SECOND = 1000;
export const SECONDS_BEFORE_MINUTE = 59;
export const MINUTES_BEFORE_HOUR = 59;

export const VOICE_MODE_LOCAL_STORAGE_KEY = 'aiola-voice-mode';

export const VOICE_TRANSITIONS = {
  [VoiceStatus.CLOSED]: [VoiceStatus.LISTENING, VoiceStatus.PAUSED, VoiceStatus.CLOSED],
  [VoiceStatus.LISTENING]: [VoiceStatus.PAUSED, VoiceStatus.CLOSED],
  [VoiceStatus.PAUSED]: [VoiceStatus.LISTENING, VoiceStatus.CLOSED],
};
