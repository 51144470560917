import { Modal, TypographyStylesProvider, Divider, Box } from '@mantine/core';
import { DynamicPopupBody } from '@flow/flow-backend-types';
import sanitizeHtml from 'sanitize-html';
import { testIds } from './DynamicModal';

interface ModalBodyProps {
  data: DynamicPopupBody;
}

export const ModalBody = ({ data }: ModalBodyProps) => {
  const sanitizedContent = () => ({
    __html: sanitizeHtml(data.content, { allowedAttributes: { '*': ['style'] } }),
  });
  return (
    <Modal.Body>
      <Divider />
      <TypographyStylesProvider data-testid={testIds.body.content}>
        {/* eslint-disable-next-line react/no-danger */}
        <Box p='md' dangerouslySetInnerHTML={sanitizedContent()} />
      </TypographyStylesProvider>
    </Modal.Body>
  );
};
