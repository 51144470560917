import { Box, Flex, Title } from '@mantine/core';
import { IconAsterisk } from '@tabler/icons-react';
import { useMemo } from 'react';
import { EventUIData } from 'stores/uiEvent';
import { UIContainer, useContainerEvents } from 'stores/container';
import { RenderEventValue } from 'pages/InspectionPage/components/RenderEvent/RenderEventValue';

export interface ContainerItemSummaryProps {
  container: UIContainer;
  uiEvent: EventUIData | undefined;
  notApplicable?: boolean;
}

export const testIds = {
  summaryTestIdRegex: /container-summary-summary/,
  summaryContainer: (containerId: string) => `container-summary-summary-${containerId}`,
  summaryTitle: (containerId: string) => `container-summary-title-${containerId}`,
  summaryMandatory: 'container-summary-mandatory',
};

export const ContainerItemSummary = ({ container, uiEvent, notApplicable }: ContainerItemSummaryProps) => {
  const getContainerEvents = useContainerEvents();
  const hasMandatoryEvents = useMemo(
    () => getContainerEvents(container).some(({ isMandatory }) => isMandatory),
    [container, getContainerEvents],
  );
  return (
    <Flex mih={32} justify='space-between' align='center' h='100%' data-testid={testIds.summaryContainer(container.id)}>
      <Title order={5} fw={400} data-testid={testIds.summaryTitle(container.id)}>
        {hasMandatoryEvents && (
          <IconAsterisk
            color='var(--mantine-color-red-4)'
            size={12}
            className='mr-1'
            data-testid={testIds.summaryMandatory}
          />
        )}
        {container.title}
      </Title>
      <Box w={80} className='shrink-0'>
        <RenderEventValue notApplicable={notApplicable} containerId={container.id} uiEvent={uiEvent} />
      </Box>
    </Flex>
  );
};
