import ILoggerProvider, { LogPayload, MetaData, Severity, UserLoggerData } from './logger.types';
import CoralogixLoggerProvider from './providers/CoralogixLoggerProvider';

/**
 * Send client logs to a preset series of log providers.
 * The logger is initialized with the user data and client metadata on app startup.
 *
 * @example
 * logger.info('App initialized');
 * logger.warn('User in danger', { tags: ['init'] });
 * logger.error('Could not sync', { tags: ['offline'], count: 3 });
 */
class Logger {
  private _metaData: MetaData = {} as MetaData;
  private providers: ILoggerProvider[] = [];

  constructor(providers: ILoggerProvider[] = []) {
    this.providers = providers;
  }

  public info = (message: string, payload?: LogPayload) => {
    this.log(Severity.INFO, message, payload);
  };

  public warn = (message: string, payload?: LogPayload) => {
    this.log(Severity.WARN, message, payload);
  };

  public error = (message: string, payload?: LogPayload) => {
    this.log(Severity.ERROR, message, payload);
  };

  public set metaData(metaData: MetaData) {
    this._metaData = metaData;
  }

  public setUser(user: UserLoggerData) {
    try {
      this.providers.forEach((provider: ILoggerProvider) => provider.setUser(user));
    } catch (error) {
      if (import.meta.env.MODE !== 'test') {
        console.error(`Could not set user, error: ${error}`);
      }
    }
  }

  private log(LogLevel: Severity, message: string, payload: LogPayload = {}) {
    try {
      this.providers.forEach((provider: ILoggerProvider) =>
        provider.log(LogLevel, { message, meta: this._metaData, ...payload }),
      );
    } catch (error) {
      if (import.meta.env.MODE !== 'test') {
        console.error(`Could not send logs, error: ${error}`);
      }
    }
  }
}

export const logger = new Logger([CoralogixLoggerProvider]);
