import { BiEvent } from '@flow/flow-backend-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';

export const espionageApi = {
  postReports: async (events: BiEvent[]): Promise<boolean> => {
    const { data } = await hermes.post(`${config.getApiUrl()}/bi-events`, events);
    return !!data;
  },
};
