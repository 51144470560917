import { useCallback, useEffect } from 'react';

export const PX_FROM_EDGE_NAVIGATION = 20;

/** Prevent swiping back animation based on touch coordinates */
export const usePreventNavigationSwipes = () => {
  const preventNavigateGesture = useCallback((e: TouchEvent) => {
    const { clientX } = e.touches[0];
    // Prevent the default navigation gesture when swiping from the edge of the screen
    if (clientX <= PX_FROM_EDGE_NAVIGATION || clientX >= window.innerWidth - PX_FROM_EDGE_NAVIGATION) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('touchstart', preventNavigateGesture, { passive: false });

    return () => {
      document.body.removeEventListener('touchstart', preventNavigateGesture);
    };
  }, []);
};
