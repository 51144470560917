import { VoiceStatus, useVoiceStore } from 'stores/voice';
import { useEffect } from 'react';
import { useFlowStore } from 'stores/flow';
import { useAuthStore } from 'stores/auth';
import { VoiceWrapper } from './VoiceWrapper';
import { VoiceError } from './VoiceError/VoiceError';
import { Standby } from './Standby/Standby';
import { Listening } from './Listening/Listening';
import { Deactivated } from './Deactivated/Deactivated';

const voiceComponentModes = {
  [VoiceStatus.CLOSED]: <Deactivated />,
  [VoiceStatus.PAUSED]: <Standby />,
  [VoiceStatus.LISTENING]: <Listening />,
};

export const InspectionVoice = () => {
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const { sessionId } = useAuthStore(['sessionId']);
  const { status, voiceError, initiateAudioSocket, reset, updateStreamMetadata } = useVoiceStore([
    'status',
    'voiceError',
    'initiateAudioSocket',
    'updateStreamMetadata',
    'reset',
  ]);

  useEffect(() => {
    if (sessionId) updateStreamMetadata({ flowSessionId: sessionId });
  }, [sessionId]);

  useEffect(() => {
    initiateAudioSocket();
    return reset;
  }, [currentExecutionId]);

  return <VoiceWrapper error={!!voiceError}>{!voiceError ? voiceComponentModes[status] : <VoiceError />}</VoiceWrapper>;
};
