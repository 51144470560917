import {
  ContainerId,
  ContainerTemplate,
  EventBinding,
  ListOfValuesValidation,
  ListOfValuesValidationValues,
  RangeValidation,
  ReportedEvent,
  ReportedValue,
  Validation,
  GeneratedSource,
} from '@flow/flow-backend-types';
import { exists } from 'utils';
import { INVALID_COLOR, VALID_COLOR } from 'consts';
import { ContainerEventsToValidationMap, ContainerTemplatesMap } from 'stores/container';
import { ListOfValuesMultiSelectionValue } from '@jargonic/event-definition-types';
import { ReportedEventsMap } from 'stores/report';
import { getReportCollectionKey, pullLastReport } from 'stores/report/report.utils';
import { EventFilter, EventUIData } from './uiEvent.types';

export function validateNumber(value?: number, { min, max }: RangeValidation = {}) {
  const [hasValue, hasMin, hasMax] = [exists(value), exists(min), exists(max)];
  if (!hasValue) return undefined;
  if (hasMin && hasMax) {
    const isBetween = max! > min!;
    return isBetween ? value! >= min! && value! <= max! : value! > min! || value! < max!;
  }
  if (hasMin) return value! >= min!;
  if (hasMax) return value! <= max!;
  return true;
}

export function validateListOfValue(
  value?: ReportedValue,
  { validValues, invalidValues }: ListOfValuesValidation = {},
) {
  if (!exists(value)) return ListOfValuesValidationValues.NO_VALIDATION;
  if (validValues?.includes(value!)) return ListOfValuesValidationValues.VALID;
  if (invalidValues?.includes(value!)) return ListOfValuesValidationValues.INVALID;
  return ListOfValuesValidationValues.NO_VALIDATION;
}

export function validateMultiSelect(
  values?: ReportedValue[],
  { validValues, invalidValues }: ListOfValuesValidation = {},
) {
  if (!exists(values)) return ListOfValuesValidationValues.NO_VALIDATION;
  if (values?.some((value) => invalidValues?.includes(value!))) return ListOfValuesValidationValues.INVALID;
  if (values?.some((value) => validValues?.includes(value!))) return ListOfValuesValidationValues.VALID;
  return ListOfValuesValidationValues.NO_VALIDATION;
}

export function getNumberColor(validation?: RangeValidation, value?: number) {
  const validationStatus = validateNumber(value, validation);
  if (exists(validationStatus)) return validationStatus ? VALID_COLOR : INVALID_COLOR;
  return undefined;
}

export function getDateValidationColor(value?: Date, validation?: RangeValidation) {
  if (!value || !validation) return undefined;
  const timestamp = value.getTime();
  const isValid = validateNumber(timestamp, validation);
  return isValid ? VALID_COLOR : INVALID_COLOR;
}

export function getSingleChoiceValueColor(
  validation?: ListOfValuesValidation,
  reportedValue?: ReportedValue,
  baseColor?: string,
) {
  const validationStatus = validateListOfValue(reportedValue, validation);
  if (!validation || validationStatus === ListOfValuesValidationValues.NO_VALIDATION) return baseColor;
  return validationStatus === ListOfValuesValidationValues.VALID ? VALID_COLOR : INVALID_COLOR;
}

export function getMultiChoiceValueColor(
  validation?: ListOfValuesValidation,
  reportedValues?: ReportedValue[],
  baseColor?: string,
) {
  const validationStatus = validateMultiSelect(reportedValues, validation);
  if (!validation || validationStatus === ListOfValuesValidationValues.NO_VALIDATION) return baseColor;
  return validationStatus === ListOfValuesValidationValues.VALID ? VALID_COLOR : INVALID_COLOR;
}

export function isEventValid(
  uiEvent: EventUIData,
  containerId: ContainerId,
  reports: ReportedEventsMap,
  validation?: Validation,
) {
  const lastReport = pullLastReport(reports, containerId, uiEvent.eventId);
  if (!lastReport || !validation) return undefined;

  if (uiEvent.type === 'NumericEvent' || uiEvent.type === 'DateEvent') {
    const numValue = Number(lastReport.reportedValue);
    return validateNumber(numValue, validation);
  }

  if (uiEvent.type === 'ButtonsEvent' || uiEvent.type === 'DropdownEvent') {
    const validity = validateListOfValue(lastReport.reportedValue, validation);
    if (validity === ListOfValuesValidationValues.NO_VALIDATION) return undefined;
    return validity === ListOfValuesValidationValues.VALID;
  }

  if (uiEvent.type === 'MultiSelectEvent') {
    const reportKey = getReportCollectionKey(containerId, uiEvent.eventId);
    const aggregatedValues = aggregateMultiSelectReports(reports[reportKey]);
    const validity = validateMultiSelect(aggregatedValues, validation);
    if (validity === ListOfValuesValidationValues.NO_VALIDATION) return undefined;
    return validity === ListOfValuesValidationValues.VALID;
  }

  return true;
}

export const createUiEventFilters = (
  uiEvents: Record<string, EventUIData>,
  containerTemplates?: ContainerTemplatesMap,
): EventFilter[] => {
  const templates = Object.values(containerTemplates ?? {});
  return Object.values(uiEvents)
    .filter((event) => event.filterable)
    .map((event) => ({
      eventId: event.eventId,
      title: event.title,
      values: 'eventValues' in event.elementData ? event.elementData.eventValues : [],
      hasValidations: !!templates.find((template) => template.uiEvents?.eventValidations?.[event.eventId]),
    }));
};

export const getContainerValidations = (
  containerTemplate: ContainerTemplate,
  validations: Record<string, Validation>,
): ContainerEventsToValidationMap => {
  const containerEventValidationsIds = containerTemplate.uiEvents?.eventValidations ?? {};
  return (
    Object.fromEntries(
      Object.keys(containerEventValidationsIds).map((eventId) => [
        eventId,
        validations[containerEventValidationsIds[eventId]],
      ]),
    ) ?? {}
  );
};

export function isEventVisibleByBinding(
  eventBinding?: EventBinding,
  triggerValue?: ReportedValue | ReportedValue[],
  triggerIsValid?: boolean,
) {
  if (!eventBinding) return true;
  const triggerValues = [triggerValue].flat().filter(exists);
  if (!triggerValues.length) return false;
  if (eventBinding.anyValue) return true;
  if (eventBinding.validValue && triggerIsValid) return true;
  if (eventBinding.invalidValue && triggerIsValid === false) return true;
  return triggerValues.some((value) => eventBinding.values.includes(value));
}

export const sortEventsByRow = (ev1: EventUIData, ev2: EventUIData) => ev1.row - ev2.row;

export function aggregateMultiSelectReports(reports: ReportedEvent[] = []) {
  const valuesSet = new Set<string>();
  reports.forEach(({ reportedValue }) => {
    if (!reportedValue) return;
    try {
      const { add = [], remove = [] } = JSON.parse(reportedValue) as ListOfValuesMultiSelectionValue;
      add.forEach((item) => valuesSet.add(item));
      remove.forEach((item) => valuesSet.delete(item));
    } catch {
      console.error('Failed to parse multi select reported value', reportedValue);
    }
  });
  return Array.from(valuesSet);
}

export function appendTextReports(reports: ReportedEvent[] = []) {
  const result: ReportedValue[] = [];
  let lastContextId: string | undefined;

  for (const report of reports.toReversed()) {
    const { generatedSource, reportedValue, eventContextId } = report;

    if (generatedSource === GeneratedSource.UI) {
      result.unshift(reportedValue ?? '');
      break;
    }

    if (eventContextId && eventContextId !== lastContextId) {
      result.unshift(reportedValue ?? '');
      lastContextId = eventContextId;
    }
  }
  return result.join(' ');
}
