import { FailedAction, PendingAction } from '@flow/flow-backend-types';

export enum DeviceOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export interface FailedActionWithReason {
  action: PendingAction;
  reason: FailedAction;
}
