import { Text } from '@mantine/core';
import { IconAlarm, IconChevronRight, IconCircleCheck, IconXboxX, IconMailPause } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutionStatus } from 'stores/flow';

interface StatusLabelProps {
  status: ExecutionStatus;
  isUserJoined: boolean;
  isJoinable: boolean;
}

export const testIds = {
  labelStatus: (status: string) => `label-status-${status}`,
  labelIcon: (status: string) => `label-icon-${status}`,
};

export const StatusLabel = ({ status, isUserJoined, isJoinable }: StatusLabelProps) => {
  const { t } = useTranslation();

  const getStatusProps = (executionStatus: ExecutionStatus) => {
    switch (executionStatus) {
      case 'done':
        return { text: t('flows.statuses.completed'), Icon: IconCircleCheck, color: 'teal.6' };
      case 'expired':
        return { text: t('flows.statuses.expired'), Icon: IconAlarm, color: 'red.5' };
      case 'cancelled':
        return { text: t('flows.statuses.canceled'), Icon: IconXboxX, color: 'red.5' };
      case 'pending':
        return { text: t('flows.statuses.pending'), Icon: IconMailPause, color: 'orange.4' };
      default:
        return isUserJoined
          ? { text: t('flows.action.continue'), Icon: IconChevronRight, color: isJoinable ? 'blue.5' : 'gray.4' }
          : { text: t('flows.action.join'), Icon: IconChevronRight, color: isJoinable ? 'blue.5' : 'gray.4' };
    }
  };

  const renderStatusLabel = useMemo(() => getStatusProps(status), [status, isUserJoined, isJoinable]);

  return (
    renderStatusLabel && (
      <Text
        className='flex items-center gap-1'
        c={renderStatusLabel.color}
        fw={600}
        data-testid={testIds.labelStatus(status)}
      >
        {renderStatusLabel.text}
        <renderStatusLabel.Icon size={18} data-testid={testIds.labelIcon(status)} />
      </Text>
    )
  );
};
