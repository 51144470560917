import { AudioMetadata } from 'services/audioSocket';

export { VoiceMode } from 'services/audioSocket';

export enum VoiceStatus {
  CLOSED = 'CLOSED',
  LISTENING = 'LISTENING',
  PAUSED = 'PAUSED',
}

export interface CountUpTime {
  hours: number;
  minutes: number;
  seconds: number;
}

export enum VoiceErrorReason {
  UserPermissionDenied = 'User permission denied',
  SystemPermissionDenied = 'System permission denied',
  NetworkError = 'Network error',
  Other = 'Other',
}

export interface MicrophonePermission {
  permitted: boolean;
  error?: VoiceErrorReason;
}

export type AudioSocketUrlParams = Pick<
  AudioMetadata,
  'userId' | 'flowSessionId' | 'flowId' | 'executionId' | 'languageCode' | 'voiceMode' | 'version'
> &
  Partial<Pick<AudioMetadata, 'frameFormat'>>;
