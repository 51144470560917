import { Collapse } from '@mantine/core';
import { PropsWithChildren, useEffect, useState } from 'react';
import cn from 'classnames';
import classes from './HiddenContentCollapse.module.css';

const DEFAULT_ANIMATION_DURATION = 150;

interface HiddenContentCollapseProps {
  open: boolean;
  animationDuration?: number;
  animationFinished?: () => void;
}

export const testIds = {
  container: 'collapse-container',
};

export const HiddenContentCollapse = ({
  open,
  animationDuration,
  animationFinished,
  children,
}: PropsWithChildren<HiddenContentCollapseProps>) => {
  const [keepChildShown, setKeepChildShown] = useState(open);
  const showChild = open || keepChildShown;

  useEffect(() => {
    if (open) {
      setKeepChildShown(true);
    }
  }, [open]);

  const unmountContent = () => {
    if (!open) setKeepChildShown(false);
    animationFinished?.();
  };

  return (
    <Collapse
      className={cn(classes.collapse)}
      in={open}
      animateOpacity={false}
      transitionTimingFunction='linear'
      transitionDuration={animationDuration ?? DEFAULT_ANIMATION_DURATION}
      onTransitionEnd={unmountContent}
      data-testid={testIds.container}
    >
      {showChild && children}
    </Collapse>
  );
};
