import { User, PutSessionResponse, GetSessionResponse } from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';
import { redirectTo } from 'utils';

const AUTH_PATH = 'auth';
const flowApiUrl = config.getApiUrl();
const authApiUrl = config.getAuthApiUrl();

export const authApi = {
  login: () => {
    redirectTo(
      config.authHost ? `${authApiUrl}/${AUTH_PATH}` : `${window.location.origin}${config.authPath}/${AUTH_PATH}`,
    );
  },
  logout: async () => {
    await hermes.get(`${flowApiUrl}/users/logout`);
  },
  fetchCurrentUser: async () => {
    const { data } = await hermes.get<User | undefined>(`${flowApiUrl}/users/profile`);
    return data;
  },
  createSession: async () => {
    const { data } = await hermes.put<PutSessionResponse | undefined>(`${flowApiUrl}/users/session`, {});
    return data;
  },
  getSession: async () => {
    const { data } = await hermes.get<GetSessionResponse | undefined>(`${flowApiUrl}/users/session`);
    return data;
  },
};
