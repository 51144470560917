import { GenerationMethod } from '@flow/flow-backend-types';
import { ReportTriggerArgs } from 'components';
import { exists } from 'utils';
import { useContainerById } from 'stores/container';
import { useCurrentUser } from 'stores/auth';
import { aggregateMultiSelectReports, useUIEventById } from 'stores/uiEvent';
import { useReportStore } from './report.store';
import { getReportCollectionKey, pullLastReport } from './report.utils';
import { ReportDynamicData } from './report.types';

export function useReporter({
  executionId,
  containerId,
  eventId,
}: {
  executionId: string;
  containerId: string;
  eventId: string;
}) {
  const user = useCurrentUser();
  return useReportStore((state) => ({
    lastReport: pullLastReport(state.reports, containerId, eventId),
    triggerReport: (args: ReportTriggerArgs, valid?: boolean) => {
      if (user?.userId) {
        const reportSlice: ReportDynamicData = {
          ...args,
          userId: user?.userId,
          containerId,
          eventDefId: eventId,
          flowExecutionId: executionId,
          generationMethod: GenerationMethod.USER_ACTION,
        };
        state.send([reportSlice]);
      }
      if (exists(valid)) {
        // TODO send validation reports
      }
    },
  }));
}

export function useContainerReports(containerId: string) {
  const container = useContainerById(containerId);
  const reportedEvents = useReportStore((state) => state.reports);
  const imageReports =
    reportedEvents[getReportCollectionKey(containerId, container?.uiEvents?.imageEventId ?? '')] ?? [];
  const applicabilityReports =
    reportedEvents[getReportCollectionKey(containerId, container?.uiEvents?.applicabilityEventId ?? '')] ?? [];
  const eventsReports =
    container?.uiEvents?.eventIds?.flatMap(
      (eventId) => reportedEvents[getReportCollectionKey(containerId, eventId)] ?? [],
    ) ?? [];
  return [...imageReports, ...applicabilityReports, ...eventsReports];
}

export function useLastReportedValue(containerId: string, eventId: string) {
  const { reports } = useReportStore(['reports']);
  const event = useUIEventById(eventId);

  if (event?.type === 'MultiSelectEvent') {
    const reportKey = getReportCollectionKey(containerId, eventId);
    const reportCollection = reports[reportKey];
    if (!reportCollection) return undefined;
    return aggregateMultiSelectReports(reportCollection);
  }

  return pullLastReport(reports, containerId, eventId)?.reportedValue;
}
