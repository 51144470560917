export async function getImageBase64(imageUrl: string): Promise<string> {
  const res = await fetch(imageUrl);
  const blob = await res.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
}

export async function getImageSize(imageUrl: string): Promise<[number, number]> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const { height: sh, width: sw } = window.screen;
      const { height: ih, width: iw } = img;
      const ratio = Math.min(sh / ih, sw / iw);
      resolve([iw * ratio, ih * ratio]);
    };
    img.src = imageUrl;
  });
}

export interface ImageData {
  url: string;
  width: number;
  height: number;
  base64: string;
  error: boolean;
}

export async function getImageData(imageUrls: string[]): Promise<ImageData[]> {
  return Promise.all(
    imageUrls.map(async (url) => {
      try {
        const base64 = await getImageBase64(url);
        const [width, height] = await getImageSize(url);
        return {
          url,
          width,
          height,
          base64,
          error: false,
        };
      } catch (e) {
        return {
          url,
          width: 0,
          height: 0,
          base64: '',
          error: true,
        };
      }
    }),
  );
}
