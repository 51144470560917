import { Divider, Text, UnstyledButton, Checkbox } from '@mantine/core';
import { Fragment } from 'react';

export const testIds = {
  getItemOptionId: (id: string) => `multiChoice-option-${id}`,
};

interface MultiChoiceOptionsProps {
  options: { value: string; label: string }[];
  reportKey: string;
  values?: string[];
  onUpdate: (optionValue: string) => void;
}

export const MultiChoiceOptions = ({ options, reportKey, values, onUpdate }: MultiChoiceOptionsProps) => (
  <Checkbox.Group value={values ?? []}>
    {options?.map(({ value, label }) => (
      <Fragment key={reportKey + value}>
        <UnstyledButton
          className='flex items-center w-full'
          onClick={() => onUpdate(value)}
          data-testid={testIds.getItemOptionId(reportKey + value)}
        >
          <Checkbox value={value} size='md' py='lg' color='teal.6' />
          <Text pl='sm'>{label}</Text>
        </UnstyledButton>
        <Divider />
      </Fragment>
    ))}
  </Checkbox.Group>
);
