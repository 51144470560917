import { useTimeout } from '@mantine/hooks';
import { useEffect } from 'react';
import { ExecutionId } from '@flow/flow-backend-types';
import { flowsApi } from 'stores/flow/flow.api';
import { useOnline } from 'stores/network';
import { useFlowStore } from 'stores/flow';

export function useExecutionExists(executionId?: ExecutionId) {
  const online = useOnline();
  const { clear, start } = useTimeout(pollExecution, 1000);
  const { currentExecution, setCurrentExecution } = useFlowStore(['currentExecution', 'setCurrentExecution']);
  async function pollExecution([isOnline, id]: [boolean, string]) {
    const retry = () => start(true, id);
    if (isOnline) {
      const execution = await flowsApi.getExecutionById(id);
      if (execution) setCurrentExecution(execution);
      else retry();
    } else retry();
  }

  useEffect(() => {
    if (executionId && !currentExecution) {
      start(online, executionId);
    }
    return () => {
      setCurrentExecution(undefined);
      clear();
    };
  }, [online, executionId]);
}
