import { Drawer, Select, Title } from '@mantine/core';
import { IconWorld } from '@tabler/icons-react';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { modalManager } from 'services/modalManager';
import { SettingsKeys, dateFormatOptions, languageOptions, useSettingsStore } from 'stores/settings';
import { ROUTES } from 'routes/routes.config';
import { AppInfo } from './AppInfo/AppInfo';

export const testIds = {
  drawerContent: 'settings-drawer-content',
  settingsListContainer: 'settings-page-list-container',
  cancelButton: 'settings-page-cancel',
  saveButton: 'settings-page-save',
  languageInputLabel: 'settings-page-language-input-label',
  languageInput: 'settings-page-language-input',
  dateInputLabel: 'settings-page-date-input-label',
  dateInput: 'settings-page-date-input',
  closeDrawerButton: 'settings-page-close-drawer',
  option: 'settings-page-option',
};

interface SettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const SettingsDrawer = ({ open, onClose }: SettingsDrawerProps) => {
  const spy = useSpy();
  const { getCurrentLanguage, updateSettings, settings } = useSettingsStore([
    'settings',
    'updateSettings',
    'getCurrentLanguage',
  ]);
  const selectedDateFormat = settings[SettingsKeys.DATE_FORMAT];
  const { t } = useTranslation();

  useEffect(() => {
    if (open) spy(names.Settings.SettingsDrawer);
  }, [open]);

  const handleChange = (key: SettingsKeys) => (value: string | null) => {
    updateSettings({ [key]: value });
  };

  const handleLanguageChange = (value: string | null) => {
    const onConfirm = () => {
      spy(names.Settings.ChangeLanguage, value, { previous_language: settings[SettingsKeys.LANGUAGE] });
      handleChange(SettingsKeys.LANGUAGE)(value);
      spy(names.Settings.LanguagePopup, BiEventValueStatus.CHANGE_LANG);
      window.location.replace(ROUTES.ROOT);
    };
    if (value !== getCurrentLanguage()) {
      modalManager.info({
        icon: IconWorld,
        title: t('settings.languageChange.title'),
        message: t('settings.languageChange.confirmationMessage'),
        labels: { confirm: t('settings.languageChange.confirmButton'), cancel: t('common.cancel') },
        onConfirm,
        onCancel: () => spy(names.Settings.LanguagePopup, BiEventValueStatus.CANCEL),
      });
    }
  };

  return (
    <Drawer.Root position='bottom' size='90%' opened={open} onClose={onClose}>
      <Drawer.Overlay />
      <Drawer.Content bg='gray.0' data-testid={testIds.drawerContent}>
        <Drawer.Header bg='gray.0'>
          <Title order={4}>{t('settings.title')}</Title>
          <Drawer.CloseButton data-testid={testIds.closeDrawerButton} />
        </Drawer.Header>
        <Drawer.Body className='flex flex-col h-[90%]'>
          <div className='flex flex-col flex-1 gap-4'>
            <Select
              size='lg'
              checkIconPosition='right'
              name={SettingsKeys.LANGUAGE}
              value={settings[SettingsKeys.LANGUAGE]}
              label={t('settings.fields.language')}
              labelProps={{ 'data-testid': testIds.languageInputLabel }}
              data={Object.entries(languageOptions).map(([value, label]) => ({
                value,
                label,
                'data-testid': testIds.option,
              }))}
              allowDeselect={false}
              onChange={handleLanguageChange}
              data-testid={testIds.languageInput}
            />
            <Select
              size='lg'
              checkIconPosition='right'
              name={SettingsKeys.DATE_FORMAT}
              value={selectedDateFormat}
              label={t('settings.fields.dateFormat')}
              labelProps={{ 'data-testid': testIds.dateInputLabel }}
              data={Object.entries(dateFormatOptions).map(([value, label]) => ({
                value,
                label,
                'data-testid': testIds.option,
              }))}
              onChange={handleChange(SettingsKeys.DATE_FORMAT)}
              data-testid={testIds.dateInput}
              allowDeselect={false}
            />
          </div>
          <AppInfo />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
