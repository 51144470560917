import { useEffect, useRef, useCallback } from 'react';
import { config } from 'services/config';
import { useAppStore } from 'stores/app';
import { useOnline } from 'stores/network';

export const useSyncPendingActions = () => {
  const online = useOnline();
  const { syncPendingActions } = useAppStore(['syncPendingActions']);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSync = useCallback(async () => {
    if (online) await syncPendingActions();
    timeoutRef.current = setTimeout(handleSync, config.pendingSyncInterval);
  }, [online]);

  useEffect(() => {
    handleSync();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [handleSync]);
};
