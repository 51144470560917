import { Box, Input, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

interface InformationProps extends PropsWithChildren {
  label: string;
  textColor?: string;
}

export const testIds = {
  container: 'information-container',
  label: 'information-label',
  text: 'information-text',
};

export const Information = ({ label, children, textColor, ...props }: InformationProps) => (
  <Box data-testid={testIds.container} {...props}>
    <Input.Label data-testid={testIds.label}>{label}</Input.Label>
    <Text color={textColor} data-testid={testIds.text} truncate>
      {children}
    </Text>
  </Box>
);
