import { PutFocusRequest } from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';

const apiUrl = config.getApiUrl();

export const focusApi = {
  updateFocus: async (focusData: PutFocusRequest) => {
    const { status } = await hermes.put<PutFocusRequest>(`${apiUrl}/focus`, focusData);
    return status;
  },
  removeFocus: async (flowExecutionId: string) => {
    const { status } = await hermes.delete(`${apiUrl}/focus?flowExecutionId=${flowExecutionId}`);
    return status;
  },
};
