import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestAudioPermissions,
} from 'mic-check/lib/requestMediaPermissions';
import { config } from 'services/config';
import { nanoid } from 'nanoid';
import { AUDIO_PARAMS } from 'services/recorder/recorder.const';
import { AudioMetadata } from 'services/audioSocket';
import { VoiceMode } from 'services/audioSocket/audioSocket.types';
import { VOICE_TRANSITIONS, VOICE_MODE_LOCAL_STORAGE_KEY } from './voice.const';
import { AudioSocketUrlParams, MicrophonePermission, VoiceErrorReason, VoiceStatus } from './voice.types';

const { AUDIO_ENCODING, SAMPLE_RATE } = AUDIO_PARAMS;

export const canTransition = (current: VoiceStatus, next: VoiceStatus): boolean =>
  VOICE_TRANSITIONS[current]?.includes(next) ?? false;

export async function getMicrophonePermission(): Promise<MicrophonePermission> {
  try {
    await requestAudioPermissions();
    console.info('Microphone permissions %cgranted', 'color: skyblue; font-weight: bold;');
    return { permitted: true };
  } catch (err) {
    const { type, message } = err as MediaPermissionsError;

    const errorMessage =
      type === MediaPermissionsErrorType.UserPermissionDenied
        ? VoiceErrorReason.UserPermissionDenied
        : VoiceErrorReason.SystemPermissionDenied;

    console.error('Microphone permissions %cdenied', 'color: red; font-weight: bold;', message);

    return { permitted: false, error: errorMessage };
  }
}

export function getSavedVoiceMode(): VoiceMode {
  const savedMode = localStorage.getItem(VOICE_MODE_LOCAL_STORAGE_KEY) as VoiceMode | null;
  if (!savedMode) return VoiceMode.FREE_SPEECH;
  const modes = Object.values(VoiceMode);
  return modes.includes(savedMode) ? savedMode : VoiceMode.FREE_SPEECH;
}

export function saveVoiceMode(mode: VoiceMode) {
  localStorage.setItem(VOICE_MODE_LOCAL_STORAGE_KEY, mode);
}

export function getOldMetadata(url?: string) {
  const oldMetadata = new URLSearchParams(url)?.get('metadata');
  if (typeof oldMetadata === 'string') {
    return JSON.parse(oldMetadata);
  }
  return null;
}

export function getWebSocketUrl(params: AudioSocketUrlParams) {
  const uri = config.audioWsHost ?? `${window.location.origin.replace(/^http/, 'ws')}`;
  const path = config.audioPath;

  const metadata: AudioMetadata = {
    audioEncoding: AUDIO_ENCODING,
    languageCode: params.languageCode,
    sampleRate: SAMPLE_RATE,
    userId: params.userId,
    streamId: nanoid(),
    flowId: params.flowId,
    executionId: params.executionId,
    version: params.version,
    voiceMode: params.voiceMode,
    flowSessionId: params.flowSessionId,
    frameFormat: params.frameFormat ?? 'debug',
  };

  return { uri, path, query: metadata };
}
