import { PutReportedEventRequest, ReportedEvent, UserId } from '@flow/flow-backend-types';

/**
 * Report object should be constructed in pieces from various parts of the app.
 */
export type ReportDynamicData = Pick<
  PutReportedEventRequest,
  | 'containerId'
  | 'eventDefId'
  | 'extraDetails'
  | 'generationMethod'
  | 'flowExecutionId'
  | 'reportedValue'
  | 'reportedValueType'
> & { userId: UserId };

export enum ImageReportValue {
  CREATE = 'created',
  EDIT = 'edited',
  DELETE = 'deleted',
}

export type ReportCollectionKey = string; // 'containedId:eventId'

export type ReportedEventsMap = Record<ReportCollectionKey, ReportedEvent[]>;
export type ReportValidityMap = Record<ReportCollectionKey, boolean | undefined>;
