import { ActionIcon, ActionIconProps, Button, ElementProps, FileButton, Flex, Modal } from '@mantine/core';
import { IconFileUpload } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toaster } from 'services/toaster';
import { useAuthStore, useCurrentUser } from 'stores/auth';
import { useFlowStore } from 'stores/flow';
import { useSettingsStore } from 'stores/settings';
import { getWebSocketUrl } from 'stores/voice/voice.utils';
import { ACCEPTED_AUDIO_FORMATS, decodeAudioFile, streamAudioData } from 'utils/audio.utils';
import { config } from 'services/config';
import { noop } from 'utils';
import { names, useSpy } from 'services/espionage';
import { useDisclosure } from '@mantine/hooks';
import { modalManager } from 'services/modalManager';
import { Hermes, HermesSocket } from '@aiola/frontend';
import { ClientEvents, ServerEvents, VoiceMode } from 'services/audioSocket';

const SOCKET_TIMEOUT = 10000;
const { maxFileSize } = config;

interface AudioStreamerProps extends ActionIconProps, ElementProps<'button', keyof ActionIconProps> {}

export const testIds = {
  upload: {
    input: 'audio-streamer-input',
    button: 'audio-streamer-button',
  },
  modal: {
    cancel: 'audio-streamer-cancel',
  },
};

export const AudioStreamer = (props: AudioStreamerProps) => {
  const { t } = useTranslation();
  const spy = useSpy();
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false, {
    onOpen: () => spy(names.AiolaWidget.UploadModalOpen),
    onClose: () => spy(names.AiolaWidget.UploadModalClosed),
  });
  const [webSocket, setWebSocket] = useState<HermesSocket<ServerEvents, ClientEvents> | null>(null);
  const { flows, executions, currentExecutionId } = useFlowStore(['flows', 'executions', 'currentExecutionId']);
  const { getCurrentLanguage } = useSettingsStore(['getCurrentLanguage']);
  const { sessionId } = useAuthStore(['sessionId']);
  const currentUser = useCurrentUser();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const resetRef = useRef<() => {}>(null);

  const handleFileChange = async (file: File | null) => {
    if (!file) return;

    if (file.size > maxFileSize * 1024 * 1024) {
      toaster.error({
        title: t('voice.errors.fileTooLarge'),
        message: t('voice.errors.fileExceedsLimit', { limit: maxFileSize }),
      });
    } else {
      modalManager.info({
        title: t('voice.uploadModal.title'),
        message: t('voice.uploadModal.message', { fileName: file.name }),
        labels: {
          confirm: t('common.confirm'),
          cancel: t('common.cancel'),
        },
        onConfirm: async () => {
          openModal();
          await streamAudioBuffer(file);
        },
      });
    }
    resetRef?.current?.();
  };

  const handleCancel = () => {
    spy(names.AiolaWidget.CancelUpload);
    resetSocket();
    closeModal();
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    resetRef.current?.();
  };

  const resetSocket = () => {
    if (webSocket) {
      webSocket.close();
      setWebSocket(null);
    }
  };

  const streamAudioBuffer = async (file: File) => {
    resetSocket();

    const audioBuffer = await decodeAudioFile(file);
    if (!audioBuffer) {
      console.error('Failed to decode the audio file.');
      return;
    }

    const flow = flows[executions[currentExecutionId!].flowRef.id];
    const { uri, path, query } = getWebSocketUrl({
      flowSessionId: sessionId ?? '',
      flowId: flow.id,
      version: flow.activeVersion,
      languageCode: getCurrentLanguage(),
      voiceMode: VoiceMode.FREE_SPEECH,
      userId: currentUser?.userId ?? '',
      executionId: currentExecutionId ?? '',
      frameFormat: 'raw',
    });

    const ws = Hermes.socket<ServerEvents, ClientEvents>(uri, path, { query });
    setWebSocket(ws);

    // Calculate the timeout based on the file size
    const fileSizeInMB = file.size / 1024 / 1024;
    const dynamicTimeout = fileSizeInMB * SOCKET_TIMEOUT;

    ws.on('connect', () => {
      console.log('WebSocket connection established.');
      streamAudioData(audioBuffer, ws);
    });

    ws.on('disconnect', () => {
      console.log('WebSocket connection closed.');
      closeModal();
    });

    ws.on('transcript', () => {
      // Reset the timer each time a message is received
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (ws) ws.close();
        closeModal();
      }, dynamicTimeout);
    });
  };

  useEffect(
    () =>
      // Clean up WebSocket and timeout on component unmount
      () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        if (webSocket) webSocket.close();
      },
    [webSocket],
  );

  return (
    <div>
      <FileButton
        accept={ACCEPTED_AUDIO_FORMATS}
        inputProps={{
          // @ts-ignore
          'data-testid': testIds.upload.input,
        }}
        resetRef={resetRef}
        onChange={handleFileChange}
      >
        {({ onClick, ...btnProps }) => (
          <ActionIcon
            size='xl'
            bg='none'
            onClick={() => {
              spy(names.AiolaWidget.UploadFile);
              onClick();
            }}
            data-testid={testIds.upload.button}
            {...{ ...btnProps, ...props }}
          >
            <IconFileUpload />
          </ActionIcon>
        )}
      </FileButton>
      <Modal
        title={t('voice.streamingFile')}
        centered
        size='xl'
        opened={modalOpened}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={noop}
      >
        <Flex justify='flex-end'>
          <Button color='red' onClick={handleCancel} data-testid={testIds.modal.cancel}>
            {t('common.cancel')}
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};
