import { FlowMetadataField, FlowMetadataFieldType } from '@flow/flow-backend-types';
import i18n from 'services/i18n';

const MIN_TEXT_LENGTH = 1;

export function getInitialValues(fields: FlowMetadataField[]) {
  return Object.fromEntries(fields.map((field) => [field.id, field.defaultValue ?? '']));
}

export function getValidationSchema(values: Record<string, string>, fields: FlowMetadataField[]) {
  return fields.reduce<Record<string, string | null>>((acc, field) => {
    const value = values[field.id];
    switch (field.type) {
      case 'String':
      case 'Text':
        acc[field.id] = field.required && value.trim().length < MIN_TEXT_LENGTH ? i18n.t('validation.tooShort') : null;
        break;
      case 'Number':
        acc[field.id] = field.required && !value ? i18n.t('validation.enterNumber') : null;
        break;
      case 'Dropdown':
        acc[field.id] = field.required && !value ? i18n.t('validation.selectOption') : null;
        break;
      case 'Date':
        acc[field.id] = field.required && !value ? i18n.t('validation.selectDate') : null;
        break;
      default:
        break;
    }
    return acc;
  }, {});
}

export function transformValues(values: Record<string, string | number | string[]>, fields: FlowMetadataField[]) {
  return fields.reduce<Record<string, string>>((acc, field) => {
    const value = values[field.id];
    switch (field.type) {
      case FlowMetadataFieldType.String:
      case FlowMetadataFieldType.Text:
        acc[field.id] = (value as string).trim();
        break;
      case FlowMetadataFieldType.Number:
        acc[field.id] = value.toString();
        break;
      case FlowMetadataFieldType.MultiselectDropdown:
        acc[field.id] = JSON.stringify(value);
        break;
      default:
        acc[field.id] = `${values[field.id]}`;
        break;
    }
    return acc;
  }, {});
}
