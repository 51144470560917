import { useMemo } from 'react';
import { useInspectionMetaStore } from './inspectionMeta.store';
import { MetaType } from './inspectionMeta.const';

export function useFlowMeta(metaType: MetaType, flowId: string = '', executionId: string = '') {
  const { flowMeta, preValues, postValues } = useInspectionMetaStore((state) => ({
    flowMeta: state.flowsMetaMap[flowId],
    preValues: executionId ? (state.executionPreFieldValuesMap[executionId] ?? {}) : {},
    postValues: executionId ? (state.executionPostFieldValuesMap[executionId] ?? {}) : {},
  }));

  const uniqueIdentifierFieldId = flowMeta?.uniqueIdentifierFieldId ?? '';

  const metaFields = useMemo(() => {
    if (!flowMeta) return [];
    const metaByFieldsType = metaType === MetaType.PRE ? flowMeta.preInspectionFields : flowMeta.postInspectionFields;
    const sortedMetaFields = [...(metaByFieldsType ?? [])];
    return sortedMetaFields.sort((a, b) => Number(a.order) - Number(b.order));
  }, [flowMeta, metaType]);

  return {
    metaFields,
    preValues,
    postValues,
    uniqueIdentifierFieldId,
  };
}
