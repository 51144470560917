import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import { DateFormat } from 'interfaces';
import { exists } from 'utils';

import 'dayjs/locale/en';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/zh-cn';

dayjs.extend(durationPlugin);
dayjs.extend(relativeTimePlugin);

dayjs.locale('en-us', dayjs.Ls.en, true);

const MORNING_RANGE = 12;
const AFTERNOON_RANGE = 18;

export const enum DayPeriods {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export const defaultFormat: DateFormat = {
  order: ['M', 'D', 'Y'],
  separator: '/',
};

/**
 * @returns a value that corresponds to a key in the translation file
 */
export function getCurrentPeriodOfDay(date: Date = new Date()): DayPeriods {
  const currentHour = date.getHours();
  if (currentHour < MORNING_RANGE) return DayPeriods.MORNING;
  if (currentHour < AFTERNOON_RANGE) return DayPeriods.AFTERNOON;
  return DayPeriods.EVENING;
}

const placeholderMap = new Map([
  ['D', 'dd'],
  ['M', 'mm'],
  ['Y', 'yyyy'],
]);

export function formatPlaceholder(format: DateFormat = defaultFormat) {
  return format.order
    .map((key) => placeholderMap.get(key))
    .join(format.separator)
    .toUpperCase();
}

export function formatDate(value?: number, format: DateFormat = defaultFormat) {
  const formatString = formatPlaceholder(format);
  if (!exists(value)) return formatString;
  return dayjs(value).format(formatString);
}

export function getValueFromFormattedDate(formattedDate: string, format: DateFormat = defaultFormat) {
  const parts = formattedDate.split(format.separator);
  if (parts.length !== format.order.length) {
    console.error('Invalid date format');
    return null;
  }
  const partMap = format.order.reduce((acc, key, index) => acc.set(key, Number(parts[index])), new Map());
  const date = new Date(partMap.get('Y'), partMap.get('M') - 1, partMap.get('D'));
  return date.getUTCMilliseconds();
}
