import { EventId } from '@flow/flow-backend-types';
import { Button, Collapse, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useSetState } from '@mantine/hooks';
import { IconPlus, IconX } from '@tabler/icons-react';
import { EventUIData, useChildEvents } from 'stores/uiEvent';
import { UIContainer } from 'stores/container';
import { exists } from 'utils';
import { ChildEvent } from './ChildEvent';

interface ChildEventsAccordionProps {
  open: boolean;
  uiEvent: EventUIData;
  container: UIContainer;
  notApplicable?: boolean;
  toggleOpen: (open: boolean) => void;
}

export const testIds = {
  summaryBtn: 'summary-btn',
  childEventsTitle: 'child-events-title',
  noChildEvents: 'no-child-events',
};

export const ChildEventsAccordion = ({
  open,
  uiEvent,
  container,
  notApplicable = false,
  toggleOpen,
}: ChildEventsAccordionProps) => {
  const { t } = useTranslation();
  const childEvents = useChildEvents(uiEvent.eventId);
  const [visibility, setVisibility] = useSetState<Record<EventId, boolean | null>>({});

  const handleShow = (eventId: EventId) => (hasValue: boolean) => setVisibility({ [eventId]: hasValue });
  const handleHide = (eventId: EventId) => () => setVisibility({ [eventId]: null });
  const visibleCount = Object.values(visibility).filter((v) => exists(v)).length;
  const valueCount = Object.values(visibility).filter((v) => v === true).length;
  const showChildren = visibleCount > 0;

  /** Due to a bug with `Collapse`; calculate key to force `Collapse` rerender, based on current visibly children. */
  const visibilityKey = Object.entries(visibility)
    .filter(([, v]) => exists(v))
    .map(([id]) => id)
    .join('');

  return (
    <Stack className='rounded' gap={0} bg={showChildren ? 'gray.1' : undefined}>
      {showChildren ? (
        <Button
          variant='transparent'
          justify='space-between'
          color='gray.7'
          p={8}
          rightSection={open ? <IconX size={20} /> : <IconPlus size={20} />}
          onClick={() => toggleOpen(!open)}
          data-testid={testIds.summaryBtn}
        >
          <Text size='sm' c='gray.6' fw={500} data-testid={testIds.childEventsTitle}>
            {t('inspection.container.events.childEventsTitle')} ({valueCount}/{visibleCount})
          </Text>
        </Button>
      ) : (
        <Text c='gray.5' fw={500} data-testid={testIds.noChildEvents}>
          {t('inspection.container.events.noChildEvents')}
        </Text>
      )}
      <Collapse key={visibilityKey} in={open}>
        <Stack gap={20} p={8} hidden={!showChildren}>
          {childEvents.map((childEvent) => (
            <ChildEvent
              key={childEvent.eventId}
              container={container}
              uiEvent={childEvent}
              disabled={notApplicable}
              onHide={handleHide(childEvent.eventId)}
              onShow={handleShow(childEvent.eventId)}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
