import { useMemo } from 'react';
import { getReportCollectionKey, useReportStore } from 'stores/report';
import { pullLastReport } from 'stores/report/report.utils';
import { appendTextReports, EventUIData, useUiEventStore } from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';
import { exists } from 'utils';

export function useContextualUiEvent(): {
  uiEvent?: EventUIData;
  value?: string;
  resetContext?: () => void;
} {
  const { reportingContext, close } = useVoiceStore(['reportingContext', 'close']);
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const { reports } = useReportStore(['reports']);

  return useMemo(() => {
    if (!exists(reportingContext)) return {};
    const { containerId, eventDefinitionId } = reportingContext;
    const uiEvent = uiEvents[eventDefinitionId];
    const reportKey = getReportCollectionKey(containerId, eventDefinitionId);
    return {
      uiEvent,
      value:
        uiEvent?.type === 'AppendTextEvent'
          ? appendTextReports(reports[reportKey])
          : (pullLastReport(reports, containerId, eventDefinitionId)?.reportedValue as string),
      resetContext: () => close(),
    };
  }, [reportingContext, uiEvents, reports]);
}
