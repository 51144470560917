import { useEffect } from 'react';

export function useWakeLock(isActive = false) {
  useEffect(() => {
    let wakeLock: WakeLockSentinel | null = null;
    // eslint-disable-next-line consistent-return
    (async () => {
      try {
        if ('wakeLock' in navigator && isActive) {
          wakeLock = await navigator.wakeLock.request('screen');
          wakeLock.addEventListener('release', () => console.log('WL: released'));

          document.addEventListener('visibilitychange', async () => {
            if (wakeLock !== null && document.visibilityState === 'visible') {
              wakeLock = await navigator.wakeLock.request('screen');
              console.log('WL: activated - visibilitychange');
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();

    return () => {
      wakeLock?.release().then(() => {
        wakeLock = null;
      });
    };
  }, [isActive]);
}
