import { createBrowserRouter, redirect } from 'react-router-dom';
import { AppLayout } from 'layouts';
import { FlowsPage, InspectionPage, PreInspectionPage, ReviewPage, SummaryPage } from 'pages';
import { InspectionLayout } from 'layouts/InspectionLayout/InspectionLayout';
import { config } from 'services/config';
import { ROUTES } from './routes.config';

export const protectedRoutes = createBrowserRouter(
  [
    {
      path: '/',
      element: <AppLayout />,
      children: [
        { path: '/', loader: () => redirect(ROUTES.FLOWS) },
        { path: ROUTES.FLOWS, element: <FlowsPage /> },
        {
          element: <InspectionLayout />,
          children: [
            { path: ROUTES.PRE_INSPECTION(), element: <PreInspectionPage /> },
            { path: ROUTES.INSPECTION(), element: <InspectionPage /> },
            { path: ROUTES.REVIEW_INSPECTION(), element: <ReviewPage /> },
            { path: ROUTES.SUMMARY_INSPECTION(), element: <SummaryPage /> },
          ],
        },
        { path: '*', loader: () => redirect(ROUTES.ROOT) },
      ],
    },
  ],
  { basename: config.baseUrl },
);
