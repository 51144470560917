// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';

import { PropsWithChildren } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { SVGDefs, theme } from 'assets';
import { ModalsProvider } from '@mantine/modals';
import { ZIndex } from 'consts';
import { I18nextProvider } from 'react-i18next';
import i18n from 'services/i18n';

export const AppProviders = ({ children }: PropsWithChildren) => (
  <MantineProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <ModalsProvider modalProps={{ zIndex: ZIndex.Modals }}>
        <SVGDefs />
        {children}
        <Notifications position='top-center' zIndex={ZIndex.Notifications} />
      </ModalsProvider>
    </I18nextProvider>
  </MantineProvider>
);
