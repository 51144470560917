import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { ActionIcon, Divider, Flex, ScrollArea, Text, Title, useMantineTheme } from '@mantine/core';
import { IconPlayerPause } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { useVoiceStore } from 'stores/voice';
import useDisableButton from 'hooks/useDisableButton';

export const testIds = {
  pauseBtn: 'pause-button',
  itemInspected: 'item-inspected',
  transcript: 'transcript',
};

export const Listening = () => {
  const { colors, primaryColor } = useMantineTheme();
  const spy = useSpy();
  const { transcript, close } = useVoiceStore(['transcript', 'close']);
  const [disabled, disableButton] = useDisableButton(1000);
  const bottomRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  const onClose = () => {
    disableButton();
    close();
  };

  useEffect(() => spy(names.AiolaWidget.ChangeStatus, BiEventValueStatus.RECORDING), []);
  useEffect(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), [transcript]);

  return (
    <Flex className='flex-1' align='center' h='100%' gap={28}>
      <ActionIcon
        bg='white'
        size={48}
        radius='xl'
        variant='filled'
        className='animate-slow-ripple shadow-md'
        disabled={disabled}
        onClick={onClose}
        data-testid={testIds.pauseBtn}
      >
        <IconPlayerPause color={colors[primaryColor][5]} />
      </ActionIcon>
      <Flex direction='column' h='100%' w='100%'>
        <Title order={5} c='white' fw={700} data-testid={testIds.itemInspected}>
          {t('voice.itemInspected')}
        </Title>
        <Divider color='white' opacity={0.5} w='90%' />
        <ScrollArea type='scroll'>
          <Text size='lg' data-testid={testIds.transcript}>
            {transcript}
            <span ref={bottomRef} />
          </Text>
        </ScrollArea>
      </Flex>
    </Flex>
  );
};
