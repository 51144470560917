import { ContainerId, EventId } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { UIContainer, useContainerStore } from 'stores/container';
import { getReportCollectionKey, useReportStore } from 'stores/report';
import { pullLastReport } from 'stores/report/report.utils';
import { useUiEventStore } from './uiEvent.store';
import { EventUIData } from './uiEvent.types';
import { aggregateMultiSelectReports, isEventVisibleByBinding, sortEventsByRow } from './uiEvent.utils';

export const useGetMainEvent = () => {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  return (container: UIContainer): EventUIData | undefined => uiEvents[container.uiEvents?.mainEventId ?? ''];
};

export function useUIEventById(eventId?: string) {
  return useUiEventStore((state) => (eventId ? state.uiEvents[eventId] : undefined));
}

export const useContainerUiEvents = (eventIds: string[]) => {
  const { uiEvents } = useUiEventStore(['uiEvents']);

  return useMemo(() => {
    const childIdsSet = eventIds.reduce<Set<string>>((acc, eventId) => {
      const event = uiEvents[eventId];
      if (event?.childrenIds) event.childrenIds.forEach((childId) => acc.add(childId));
      return acc;
    }, new Set());

    return eventIds
      .map((eventId) => uiEvents[eventId])
      .filter((event) => !!event && !childIdsSet.has(event.eventId))
      .sort(sortEventsByRow);
  }, [eventIds]);
};

export const useGetValidationByEventId = (containerId: string, eventId: string) => {
  const validations = useUiEventStore((state) => state.validations);
  const containers = useContainerStore((state) => state.containers);
  const eventValidations = containers[containerId]?.uiEvents?.eventValidations;
  const validationId = eventValidations?.[eventId];
  return validationId ? validations?.[validationId] : undefined;
};

export function useChildEvents(eventId: EventId): EventUIData[] {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const parentEvent = useUIEventById(eventId);

  return useMemo(() => {
    if (!parentEvent || !parentEvent.childrenIds) return [];
    return parentEvent.childrenIds
      .map((childId) => uiEvents[childId])
      .filter((childEvent) => !!childEvent)
      .sort(sortEventsByRow);
  }, [parentEvent]);
}

/** Figure out if a **child event** should be visible or hidden.
 * Always returns `true` for parents with children.
 * @param containerId Temporary necessity to enforce in-container triggers. */
export function useEventVisibility(eventId: EventId, containerId?: ContainerId) {
  const { visibilityBindings, uiEvents } = useUiEventStore(['visibilityBindings', 'uiEvents']);
  const { reports, validity } = useReportStore(['reports', 'validity']);
  const event = useUIEventById(eventId);

  return useMemo(() => {
    if (!event) return false;
    const binding = visibilityBindings[event.visibilityBindingId!];
    if (!binding) return true;
    const { show: binds } = binding;
    return binds.some((bind) => {
      const triggerContainerId = 'containerId' in bind ? (bind.containerId as string) : containerId;
      if (!triggerContainerId) return false;
      const reportKey = getReportCollectionKey(triggerContainerId, bind.triggerEventId);
      const triggerValue =
        uiEvents[bind.triggerEventId]?.type === 'MultiSelectEvent'
          ? aggregateMultiSelectReports(reports[reportKey] ?? [])
          : pullLastReport(reports, triggerContainerId, bind.triggerEventId)?.reportedValue;
      const triggerIsValid = validity[reportKey];
      return isEventVisibleByBinding(bind, triggerValue, triggerIsValid);
    });
  }, [reports, validity]);
}
