import { config } from 'services/config';
import { createStoreHook, HermesStatusCode } from '@aiola/frontend';
import { create } from 'zustand';
import { DevtoolsOptions, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { hermes } from 'services/hermes';

const PING_URL = `${config.getApiUrl()}/${config.flowApiPrefix}/health`;

const devtoolsOptions: DevtoolsOptions = {
  name: 'network',
  store: 'network',
  enabled: process.env.NODE_ENV === 'development',
};

interface NetworkState {
  online: boolean;
}

interface NetworkInternalState {
  consecutiveFailures: number;
  consecutiveSuccesses: number;
}

interface NetworkInternalActions {
  incrementSuccesses: () => void;
  incrementFailures: () => void;
  resetSuccesses: () => void;
  resetFailures: () => void;
}

interface NetworkActions {
  checkConnection: () => Promise<void>;
  isServerReachable: () => Promise<boolean>;
  setOnline: (online: boolean) => void;
  reset: () => void;
}

const initialState: NetworkState = {
  online: navigator.onLine,
};

const initialInternalState: NetworkInternalState = {
  consecutiveFailures: 0,
  consecutiveSuccesses: 0,
};

type NetworkStoreState = NetworkState & NetworkActions & { internal: NetworkInternalState & NetworkInternalActions };

export const networkStore = create(
  devtools(
    immer<NetworkStoreState>((set, get) => ({
      ...initialState,
      setOnline: (online: boolean) => {
        set({ online });
      },
      isServerReachable: async () => {
        try {
          const response = await hermes.get(PING_URL, {
            config: { method: 'HEAD' },
          });
          return response.status === HermesStatusCode.Ok;
        } catch (error) {
          return false;
        }
      },
      checkConnection: async () => {
        const { setOnline, isServerReachable, internal } = get();
        const { incrementSuccesses, incrementFailures, resetSuccesses, resetFailures } = internal;

        if (!navigator.onLine) {
          setOnline(false);
          resetFailures();
          resetSuccesses();
          return;
        }

        const serverReachable = await isServerReachable();
        if (serverReachable) {
          incrementSuccesses();
          resetFailures();
        } else {
          incrementFailures();
          resetSuccesses();
        }
      },
      internal: {
        ...initialInternalState,
        incrementSuccesses: () => {
          set((state) => {
            const newSuccesses = state.internal.consecutiveSuccesses + 1;
            if (newSuccesses >= config.successPingCountThreshold) {
              state.online = true;
              state.internal.consecutiveSuccesses = 0;
              state.internal.consecutiveFailures = 0;
            } else {
              state.internal.consecutiveSuccesses = newSuccesses;
            }
          });
        },
        incrementFailures: () => {
          set((state) => {
            const newFailures = state.internal.consecutiveFailures + 1;
            if (newFailures >= config.failurePingCountThreshold) {
              state.online = false;
              state.internal.consecutiveSuccesses = 0;
              state.internal.consecutiveFailures = 0;
            } else {
              state.internal.consecutiveFailures = newFailures;
            }
          });
        },
        resetSuccesses: () => {
          set((state) => {
            state.internal.consecutiveSuccesses = 0;
          });
        },
        resetFailures: () => {
          set((state) => {
            state.internal.consecutiveFailures = 0;
          });
        },
      },
      reset: () => {
        set({ ...initialState, ...initialInternalState });
      },
    })),
    devtoolsOptions,
  ),
);

export const useNetworkStore = createStoreHook<NetworkStoreState>({ store: networkStore, useShallow });
