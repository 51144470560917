import { useEffect, useState } from 'react';
import { Flex, Badge, Text, Affix, Transition } from '@mantine/core';
import { IconWifi, IconWifiOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNetworkStore } from 'stores/network';
import { OFFLINE_BANNER, DEFAULT_TRANSITION_DURATION } from 'consts';
import { usePrevious } from '@mantine/hooks';

const { OFFLINE_COLOR, CONNECTION_RESTORED_COLOR, CONNECTION_RESTORED_DURATION } = OFFLINE_BANNER;

export const testIds = {
  offlineBanner: 'offline-banner',
};

export const OfflineBanner = () => {
  const { t } = useTranslation();
  const { online } = useNetworkStore(['online']);
  const [bannerData, setBannerData] = useState({
    show: false,
    text: '',
    color: OFFLINE_COLOR,
  });
  const previousOnlineStatus = usePrevious(online);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const connectionRestored = previousOnlineStatus === false && online;

    if (!online) {
      setBannerData({ show: true, text: t('offline.banner.offlineMode'), color: OFFLINE_COLOR });
    } else if (connectionRestored) {
      setBannerData({
        show: true,
        text: t('offline.banner.connectionRestored'),
        color: CONNECTION_RESTORED_COLOR,
      });
      timeoutId = setTimeout(() => {
        setBannerData((prev) => ({ ...prev, show: false }));
      }, CONNECTION_RESTORED_DURATION);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [online]);

  return (
    <Affix position={{ top: 10, left: 100, right: 100 }}>
      <Flex w='100%' h='100%' direction='column' align='center'>
        <Transition duration={DEFAULT_TRANSITION_DURATION} transition='slide-down' mounted={bannerData.show}>
          {(styles) => (
            <Badge
              style={{ ...styles }}
              data-testid={testIds.offlineBanner}
              leftSection={online ? <IconWifi /> : <IconWifiOff />}
              className='rounded-full'
              color={bannerData.color}
              py='md'
              px='xs'
            >
              <Text className='normal-case' size='tiny' fw={500}>
                {bannerData.text}
              </Text>
            </Badge>
          )}
        </Transition>
      </Flex>
    </Affix>
  );
};
