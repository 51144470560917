import { Divider, Stack } from '@mantine/core';
import { ImageEvent } from 'components';
import { useContainerUiEvents } from 'stores/uiEvent/uiEvent.selectors';
import { UIContainer } from 'stores/container';
import { ReportedValue } from '@flow/flow-backend-types';
import { exists } from 'utils';
import { useSetState } from '@mantine/hooks';
import { RenderEvent } from '../RenderEvent/RenderEvent';
import { ContainerItemFooter } from './ContainerItemFooter';
import { ChildEventsAccordion } from '../ChildEventsAccordion/ChildEventsAccordion';

interface ContainerDetailsProps {
  container: UIContainer;
  notApplicable: boolean;
}

export const testIds = {
  getContainerById: (containerId: string) => `container-details-${containerId}`,
};

export const ContainerDetails = ({ container, notApplicable }: ContainerDetailsProps) => {
  const [openedParents, setOpenedParents] = useSetState<Record<string, boolean>>({});
  const containerEvents = useContainerUiEvents(container.uiEvents?.eventIds ?? []);

  const handleReportValueChange = (eventId: string) => (value?: ReportedValue) => {
    const childIds = containerEvents.find((event) => event.eventId === eventId)?.childrenIds;
    if (exists(value) && exists(childIds) && childIds.length > 0) setOpenedParents({ [eventId]: true });
  };

  const toggleOpenedParentEventId = (eventId: string) => (open: boolean) => setOpenedParents({ [eventId]: open });

  return (
    <Stack h='100%' justify='space-between' data-testid={testIds.getContainerById(container.id)}>
      <Stack gap={20}>
        {containerEvents.map((uiEvent) => (
          <Stack key={uiEvent.eventId} gap={4}>
            <RenderEvent
              container={container}
              uiEvent={uiEvent}
              disabled={notApplicable}
              onReportValueChange={handleReportValueChange(uiEvent.eventId)}
            />
            {uiEvent.childrenIds && (
              <ChildEventsAccordion
                open={openedParents[uiEvent.eventId]}
                uiEvent={uiEvent}
                container={container}
                notApplicable={notApplicable}
                toggleOpen={toggleOpenedParentEventId(uiEvent.eventId)}
              />
            )}
          </Stack>
        ))}
        {container.uiEvents?.imageEventId && <ImageEvent disabled={notApplicable} />}
      </Stack>
      <Stack gap='tn' mt='md'>
        <Divider />
        <ContainerItemFooter container={container} />
      </Stack>
    </Stack>
  );
};
