import { MEDIA_CONSTRAINTS, AUDIO_PARAMS } from './recorder.const';
import { AudioFrame } from './recorder.types';

const { SAMPLE_RATE } = AUDIO_PARAMS;

export class Recorder {
  private static instance: Recorder;
  private audioContext: AudioContext;
  private mediaStream?: MediaStream;
  private audioProcessorWorklet?: AudioWorkletNode;
  private _active: boolean = false;

  private constructor() {
    this.audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
    this.audioContext.suspend();
  }

  public static getInstance(): Recorder {
    if (!Recorder.instance) Recorder.instance = new Recorder();
    return Recorder.instance;
  }

  get active() {
    return this._active;
  }

  async start(audioHandler: (audioFrame: AudioFrame) => void) {
    if (this.active) return;
    const { audioContext } = this;

    if (audioContext.state !== 'suspended') return;

    await audioContext.resume();

    this.mediaStream = await navigator.mediaDevices.getUserMedia(MEDIA_CONSTRAINTS);
    const micNode = audioContext.createMediaStreamSource(this.mediaStream);
    await audioContext.audioWorklet.addModule('/worklet/audio-processor.js');

    this.audioProcessorWorklet = new AudioWorkletNode(audioContext, 'audio-processor');
    this.audioProcessorWorklet.port.onmessage = (event) => {
      audioHandler(event.data);
    };

    micNode.connect(this.audioProcessorWorklet).connect(audioContext.destination);
    this._active = true;
  }

  async stop() {
    if (!this.active) return;
    this.mediaStream?.getTracks().forEach((track) => track.stop());

    const { audioProcessorWorklet } = this;

    if (audioProcessorWorklet) {
      audioProcessorWorklet.port.postMessage('stop');
      audioProcessorWorklet.disconnect();
      this.audioProcessorWorklet = undefined;
    }

    // Suspend the AudioContext to effectively pause the recording process
    await this.audioContext.suspend();
    this._active = false;
  }
}
