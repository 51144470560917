import { ActionIcon, Flex, Title, useMantineTheme } from '@mantine/core';
import { IconMicrophone } from '@tabler/icons-react';
import useDisableButton from 'hooks/useDisableButton';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useVoiceStore } from 'stores/voice';
import { config } from 'services/config';
import { useFlowStore } from 'stores/flow';
import { AudioStreamer } from '../../AudioStreamer/AudioStreamer';

export const testIds = {
  recordBtn: 'record-btn',
  activate: 'activate',
};

export const Deactivated = () => {
  const { primaryColor, colors } = useMantineTheme();
  const { currentExecution } = useFlowStore(['currentExecution']);
  const spy = useSpy();
  const activate = useVoiceStore((state) => state.activate);
  const [disabled, disableButton] = useDisableButton(1000);
  const { t } = useTranslation();

  const onActivate = () => {
    disableButton();
    spy(names.AiolaWidget.Activated);
    activate();
  };

  return (
    <Flex className='flex-1' align='center' gap={20}>
      <ActionIcon
        bg='white'
        size={48}
        radius='xl'
        variant='filled'
        className='shadow-md'
        disabled={disabled || !currentExecution}
        onClick={onActivate}
        data-testid={testIds.recordBtn}
      >
        <IconMicrophone color={colors[primaryColor][5]} />
      </ActionIcon>
      <Title order={6} className='whitespace-pre-wrap' data-testid={testIds.activate}>
        {currentExecution ? t('voice.activate') : t('common.waitingForServer')}
      </Title>
      {config.fileStreaming && <AudioStreamer pos='absolute' bottom={5} right={5} />}
    </Flex>
  );
};
