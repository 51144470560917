import { ContainerId, EventId } from '@flow/flow-backend-types';
import { AUDIO_PARAMS } from 'services/recorder/recorder.const';

export enum VoiceMode {
  FREE_SPEECH = 'free-speech',
  TAP_SPEECH = 'focused',
}

export interface TranscriptData {
  transcript: string;
  provider_name: string;
  transcript_file_path: string;
  audio_file_path: string;
  asr_model_version: string;
  asr_model_path: string;
}

export interface AudioMetadata {
  userId: string;
  flowSessionId: string;
  flowId: string;
  executionId: string;
  streamId: string;
  languageCode: string;
  sampleRate: number;
  audioEncoding: (typeof AUDIO_PARAMS)['AUDIO_ENCODING'];
  version: string;
  voiceMode: VoiceMode;
  frameFormat: 'raw' | 'debug';
}

export interface ServerEvents {
  transcript: (data: TranscriptData) => void;
  error: (data: string) => void;
}

export interface ReportingContext {
  containerId: ContainerId;
  eventDefinitionId: EventId;
}

export interface VoicePacket {
  id: number;
  timestamp: number;
  audioFrame: ArrayBuffer;
}

export type VoicePacketWithContext = VoicePacket & ReportingContext;

export interface ClientEvents {
  binary_data: (data: VoicePacket) => void;
  binary_data_with_context: (data: VoicePacketWithContext) => void;
  metadata_update: (data: Partial<AudioMetadata>) => void;
}
