import { useEffect } from 'react';
import { useAuthStore } from 'stores/auth';
import { getAgentInformation, getScreenResolution } from 'services/espionage/espionage.utils';
import { config } from 'services/config';
import mixpanel from './mixpanel';

export function useMixpanel() {
  const { currentUser: user } = useAuthStore(['currentUser']);

  useEffect(() => {
    if (user) {
      mixpanel.identify(user.userId);
      mixpanel.people.set({
        $name: user.name,
        $username: user.preferredUsername || user.name,
      });

      mixpanel.register({
        user_id: user.userId,
        user_name: user.name,
        tenant_id: config.customerId,
        screen_resolution: getScreenResolution(),
        ...getAgentInformation(),
      });
    }

    return () => {
      mixpanel.reset();
    };
  }, [user]);
}
