import Dexie, { Table } from 'dexie';
import {
  BiEvent,
  Container,
  Flow,
  FlowRef,
  PendingAction,
  PutReportedEventRequest,
  RenderModel,
} from '@flow/flow-backend-types';
import { Execution } from 'stores/flow';
import { orderContainers } from 'stores/container';
import { recordize } from '@aiola/frontend';
import { config } from 'services/config';
import { DatabaseTables, DynamicContainersTable, InspectionMetaTable, PendingActionPayload } from './db.types';
import { createPendingAction } from './db.utils';
import { dbSchemaV1, dbSchemaV2, dbSchemaV3 } from './schemas';
import { migrateToV2, migrateToV3 } from './migrations';

const DB_NAME = 'flow-app-cache';

/**
 * Database - A class for handling and structuring the database tables.
 *
 * @class
 * @description The Database class abstracts the interactions and structuring of various
 *              database tables.
 *              It utilizes Dexie.js as the underlying IndexedDB wrapper for seamless
 *              asynchronous interactions.
 * @example
 * import { Database } from 'path-to-database';
 * const dbInstance = new Database();
 *
 * const flows = dbInstance.flows.toArray();
 *
 * const flowData = await flows.get("some_flow_id");
 */

export class Database extends Dexie implements DatabaseTables {
  flows!: Table<Flow, string>;
  flowMetadata!: Table<InspectionMetaTable, string>;
  executions!: Table<Execution, string>;
  reportedEvents!: Table<PutReportedEventRequest, string>;
  renderModels!: Table<RenderModel, string>;
  pendingActions!: Table<PendingAction, string>;
  pendingBiEvents!: Table<BiEvent>;
  dynamicContainers!: Table<DynamicContainersTable, string>;

  constructor() {
    super(DB_NAME);
    this.version(1).stores(dbSchemaV1);
    this.version(2).stores(dbSchemaV2).upgrade(migrateToV2);
    this.version(3).stores(dbSchemaV3).upgrade(migrateToV3);
  }

  storePendingAction(action: PendingActionPayload) {
    return this.pendingActions.add(createPendingAction(action));
  }

  storePendingActionBulk(actions: PendingActionPayload[]) {
    return this.pendingActions.bulkAdd(actions.map(createPendingAction));
  }

  listPendingActions() {
    return this.pendingActions.toCollection().offset(0).limit(config.pendingActionBatchLimit).sortBy('timestamp');
  }

  async clearPendingActions(actionIdsToClear: string[]) {
    return this.pendingActions.bulkDelete(actionIdsToClear);
  }

  async storeRenderModel(renderModel: RenderModel, flowExecutionId?: string) {
    if (renderModel.dynamicContainerTemplates?.length && flowExecutionId) {
      await this.storeDynamicContainers(flowExecutionId, Object.values(renderModel.containers));
    }
    return this.renderModels.put(renderModel);
  }

  getRenderModel(flowRef: FlowRef, flowExecutionId?: string) {
    return this.transaction('rw', this.renderModels, this.dynamicContainers, async () => {
      const renderModel = await this.renderModels.get({ flowId: flowRef.id, version: flowRef.version });
      if (renderModel?.dynamicContainerTemplates?.length && flowExecutionId) {
        const containers = await this.dynamicContainers.where('flowExecutionId').equals(flowExecutionId).toArray();
        renderModel.containers = recordize(orderContainers(containers));
        renderModel.rootContainerIds = containers.map(({ id }) => id);
      }
      return renderModel;
    });
  }

  storeDynamicContainers(flowExecutionId: string, containers: Container[]) {
    return this.dynamicContainers.bulkPut(containers.map((container) => ({ ...container, flowExecutionId })));
  }

  getExecutionData(executionId: string) {
    return this.transaction('r', this.executions, this.reportedEvents, async () => {
      const execution = await this.executions.get(executionId);
      const reportedEvents = await this.reportedEvents.where({ flowExecutionId: executionId }).toArray();
      return { execution, reportedEvents };
    });
  }

  deleteFlowData(flowIds: string[]) {
    return this.transaction('rw', this.flows, this.flowMetadata, this.renderModels, async () => {
      await this.flows.bulkDelete(flowIds);
      await this.flowMetadata.where('id').anyOf(flowIds).delete();
      await this.renderModels.where('flowId').anyOf(flowIds).delete();
    });
  }

  deleteExecutionData(executionIds: string[]) {
    return this.transaction('rw', this.executions, this.reportedEvents, async () => {
      await this.executions.bulkDelete(executionIds);
      await this.reportedEvents.where('flowExecutionId').anyOf(executionIds).delete();
    });
  }

  getFlows() {
    return this.flows.toArray();
  }

  getExecutions() {
    return this.executions.toArray();
  }
}
