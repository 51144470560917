import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { Validation, ValidationId } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { focusStore } from 'stores/focus';
import { ContainerTemplatesMap } from 'stores/container';
import { EventFilter, FilterValue, UIEventsMap, VisibilityBindingsMap } from './uiEvent.types';
import { createUiEventFilters } from './uiEvent.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'uiEvents',
  store: 'uiEvents',
  enabled: process.env.NODE_ENV === 'development',
};

interface UiEventState {
  uiEvents: UIEventsMap;
  editingEventId: string | undefined;
  filterValues: FilterValue[];
  validations: Record<ValidationId, Validation>;
  visibilityBindings: VisibilityBindingsMap;
  filters: EventFilter[];
}

interface UiEventActions {
  setUIEvents: (uiEvents: UIEventsMap, containerTemplates?: ContainerTemplatesMap) => void;
  setEditingEventId: (uiEventId: string | undefined) => void;
  setFilterValues: (values: FilterValue[]) => void;
  setValidations: (validation?: Record<ValidationId, Validation>) => void;
  setVisibilityBindings: (visibilityBindings?: VisibilityBindingsMap) => void;
  reset: () => void;
}

const uiEventInitialState: UiEventState = {
  uiEvents: {},
  editingEventId: undefined,
  filterValues: [],
  validations: {},
  visibilityBindings: {},
  filters: [],
};

export const uiEventStore = create(
  devtools(
    immer<UiEventState & UiEventActions>((set, get) => ({
      ...uiEventInitialState,
      setUIEvents: (uiEvents, containerTemplates) => {
        const filters = createUiEventFilters(uiEvents, containerTemplates);
        set({ uiEvents, filters });
      },
      setEditingEventId: (uiEventId) => {
        if (get().editingEventId !== uiEventId) set({ editingEventId: uiEventId });
      },
      setFilterValues: (values) => {
        set({ filterValues: values });
        focusStore.getState().blurContainer();
      },
      setValidations: (validations?: Record<ValidationId, Validation>) => {
        set({ validations });
      },
      setVisibilityBindings: (visibilityBindings) => {
        set({ visibilityBindings });
      },
      reset: () => {
        set(uiEventInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useUiEventStore = createStoreHook({ store: uiEventStore, useShallow });
