import { DynamicPopup } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { flowStore } from 'stores/flow';

interface DynamicModalState {
  modalLayout: DynamicPopup | null;
  isOpen: boolean;
  timerId: NodeJS.Timeout | null;
}

interface DynamicModalActions {
  openDynamicModal: (layout: DynamicPopup) => void;
  closeDynamicModal(): void;
  reset: () => void;
}

const initialState: DynamicModalState = {
  modalLayout: null,
  isOpen: false,
  timerId: null,
};

export const dynamicModalStore = create(
  immer<DynamicModalState & DynamicModalActions>((set, get) => ({
    ...initialState,
    openDynamicModal: (layout: DynamicPopup) => {
      const { timerId, closeDynamicModal } = get();
      const { currentExecutionId } = flowStore.getState();
      if (timerId) clearTimeout(timerId);

      if (layout) {
        const { timeout, flowExecutionId } = layout;
        if (flowExecutionId === currentExecutionId || !flowExecutionId) {
          const newTimerId = timeout ? setTimeout(closeDynamicModal, timeout) : null;
          set({ modalLayout: layout, isOpen: true, timerId: newTimerId });
        }
      }
    },
    closeDynamicModal: () => {
      const { timerId } = get();
      if (timerId) clearTimeout(timerId);
      set({ isOpen: false, timerId: null });
    },
    reset: () => {
      const { timerId } = get();
      if (timerId) clearTimeout(timerId);
      set(initialState);
    },
  })),
);

export const useDynamicModalStore = createStoreHook<DynamicModalState & DynamicModalActions>({
  store: dynamicModalStore,
  useShallow,
});
