import { Flex } from '@mantine/core';

export const testIds = {
  loader: 'loader',
};

export const Loader = () => (
  <Flex w='100%' h='100%' justify='center' align='center'>
    <div className='flex min-h-[40px] items-end' data-testid={testIds.loader}>
      <div
        style={{ backgroundColor: '#2A644F' }}
        className='w-5 h-5 mx-[5px] rounded-full animate-[cycle-up_1.5s_0.6s_linear_infinite]'
      />
      <div
        style={{ backgroundColor: '#2A644F' }}
        className='w-5 h-5 mx-[5px] rounded-full animate-[cycle-up_1.5s_0.4s_linear_infinite]'
      />
      <div
        style={{ backgroundColor: '#2A644F' }}
        className='w-5 h-5 mx-[5px] rounded-full animate-[cycle-up_1.5s_0.2s_linear_infinite]'
      />
    </div>
  </Flex>
);
