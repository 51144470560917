import { SkeletonList } from 'components';
import { useAppStore } from 'stores/app';
import { useContainerStore } from 'stores/container';
import { useDynamicContainers } from 'pages/InspectionPage/InspectionPage.hooks';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { useTranslation } from 'react-i18next';
import { ContainerList } from '../ContainerList/ContainerList';
import { ContainersListEmptyState } from '../ContainersListEmptyState/ContainersListEmptyState';

export interface InspectionListContentProps {
  collapseContainerDetails?: boolean;
  displayContainerContent?: boolean;
  showEmptyState?: boolean;
}

export const InspectionListContent = ({
  collapseContainerDetails,
  displayContainerContent,
  showEmptyState = false,
}: InspectionListContentProps) => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const { inspectionDataLoading } = useAppStore(['inspectionDataLoading']);
  const { rootContainerIds, openTemplatesList } = useContainerStore(['rootContainerIds', 'openTemplatesList']);
  const { isDynamicContainers, hasMultipleDynamicContainers, createNewDynamicContainer } =
    useDynamicContainers(executionId);
  const { t } = useTranslation();

  if (inspectionDataLoading) return <SkeletonList height={450} px={4} itemHeight={64} gap={20} />;

  if (rootContainerIds.length) {
    return (
      <ContainerList
        collapseContainerDetails={collapseContainerDetails}
        displayContainerContent={displayContainerContent}
        containerIds={rootContainerIds}
      />
    );
  }

  if (isDynamicContainers && showEmptyState) {
    const onAddContainer = () => {
      if (hasMultipleDynamicContainers) openTemplatesList();
      else createNewDynamicContainer();
    };
    return (
      <ContainersListEmptyState
        title={t('inspection.emptyContainerList.title')}
        text={t('inspection.emptyContainerList.text')}
        addContainer={onAddContainer}
      />
    );
  }

  return null;
};
