import { Flex, ActionIcon, Title, Text, useMantineTheme, Image } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { BiEventValueStatus } from '@flow/flow-backend-types';
import { logoLoopDark } from 'assets';
import { names, useSpy } from 'services/espionage';
import classes from './ContainersListEmptyState.module.css';

export const testIds = {
  emptyContainerListStub: 'empty-container-list-stub',
  emptyStateCreateDynamicContainerBtn: 'empty-state-create-dynamic-container-btn',
  emptyStateImg: 'empty-state-img',
  emptyStateTitle: 'empty-state-title',
  emptyStateText: 'empty-state-text',
};
interface ContainersListEmptyStateProps {
  title: string;
  text: string;
  addContainer?: () => void;
}

export const ContainersListEmptyState = ({ title, text, addContainer }: ContainersListEmptyStateProps) => {
  const { colors } = useMantineTheme();
  const spy = useSpy();

  const onClickAddContainer = () => {
    spy(names.InspectionList.NewDynamicItemContainer, BiEventValueStatus.DC_EMPTY_STATE);
    addContainer?.();
  };

  return (
    <Flex
      className='flex-1'
      justify='center'
      align='center'
      direction='column'
      gap={20}
      data-testid={testIds.emptyContainerListStub}
    >
      <Image w={80} src={logoLoopDark} data-testid={testIds.emptyStateImg} />
      <Title size={20} fw='bold' ta='center' data-testid={testIds.emptyStateTitle}>
        {title}
      </Title>
      <Text fw={500} ta='center' w={212} data-testid={testIds.emptyStateText}>
        {text}
      </Text>
      {addContainer && (
        <ActionIcon
          variant='default'
          size='xl'
          radius='xl'
          className={classes.newDynamicContainerBtn}
          onClick={onClickAddContainer}
          data-testid={testIds.emptyStateCreateDynamicContainerBtn}
        >
          <IconPlus color={colors.blue[6]} size={44} />
        </ActionIcon>
      )}
    </Flex>
  );
};
