import { getImageUrl } from 'services/api';
import { ReportedImage } from 'stores/uiEvent';
import { getImageBase64, getImageSize } from 'utils';

export interface ReportedImageData {
  id: string;
  originalId: string;
  originalUrl: string;
  originalBase64: string;
  editedId?: string;
  editedUrl?: string;
  editedBase64?: string;
  width: number;
  height: number;
  error: boolean;
}

/**
 * Get complete image data for a reported image
 */
export async function getReportedImageData(
  reportedImage: ReportedImage,
  executionId: string,
  containerId: string,
): Promise<ReportedImageData> {
  const { id, original: originalId, edited: editedId } = reportedImage;
  const originalUrl = getImageUrl(originalId, executionId, containerId);

  try {
    const [originalBase64, [width, height]] = await Promise.all([
      getImageBase64(originalUrl),
      getImageSize(originalUrl),
    ]);

    const result: ReportedImageData = {
      id,
      originalId,
      originalUrl,
      originalBase64,
      width,
      height,
      error: false,
    };

    if (editedId) {
      const editedUrl = getImageUrl(editedId, executionId, containerId);
      const editedBase64 = await getImageBase64(editedUrl);
      Object.assign(result, { editedId, editedUrl, editedBase64 });
    }

    return result;
  } catch {
    return { id, error: true } as ReportedImageData;
  }
}

export async function resolveImagesData(
  images: ReportedImage[],
  imageData: ReportedImageData[],
  executionId: string,
  containerId: string,
): Promise<ReportedImageData[]> {
  return Promise.all(
    images.map(async (image) => {
      const existingDataForImage = imageData.find((data) => data.id === image.id);
      if (!existingDataForImage) return getReportedImageData(image, executionId, containerId);

      const editedId = image.edited;
      const editedIdIsRemoved = !editedId && !!existingDataForImage.editedId;
      if (editedIdIsRemoved) {
        return {
          ...existingDataForImage,
          editedId: undefined,
          editedUrl: undefined,
          editedBase64: undefined,
        };
      }

      const hasNewEditedId = !!editedId && editedId !== existingDataForImage.editedId;
      const isMissingEditedBase64 = !!editedId && !existingDataForImage.editedBase64;
      const shouldGetEditedData = hasNewEditedId || isMissingEditedBase64;
      if (!shouldGetEditedData) return existingDataForImage;

      const editedUrl = getImageUrl(editedId!, executionId, containerId);
      try {
        const editedBase64 = await getImageBase64(editedUrl);
        return {
          ...existingDataForImage,
          editedId,
          editedUrl,
          editedBase64,
        };
      } catch {
        return { ...existingDataForImage, error: true };
      }
    }),
  );
}

export function imageDataToReport(imageData: ReportedImageData[]): ReportedImage[] {
  return imageData.map(({ id, originalId, editedId }) => ({
    id,
    original: originalId,
    edited: editedId,
  }));
}
