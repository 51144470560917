import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAppStore } from 'stores/app';

/** Forces navigation by setting the state, then navigating */
export function useForceNavigate(): ReturnType<typeof useNavigate> {
  const navigate = useNavigate();
  const { setNavigationLock } = useAppStore(['setNavigationLock']);
  return useCallback((...args) => {
    setNavigationLock(false);
    navigate(...(args as Parameters<NavigateFunction>));
  }, []);
}
