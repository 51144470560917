import { Database } from './db.class';

/**
 * This is a singleton instance of the database.
 * @class Database
 *
 * @example
 * import { db } from 'services/db';
 *
 * const { data } = await db.flows.toArray();
 */

export const db = new Database();
