import { ReportedEvent } from '@flow/flow-backend-types';
import { Box, Divider, Flex, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useUiEventStore } from 'stores/uiEvent';
import { useFlowStore } from 'stores/flow';
import { useLogItemProps } from './LogItem.hooks';

interface LogItemProps {
  reportedEvent: ReportedEvent;
}

export const testIds = {
  itemContainerPrefix: 'itemContainer',
  itemContainer: (id: string) => `${testIds.itemContainerPrefix}-${id}`,
  itemIcon: (id: string) => `itemIcon-${id}`,
  reportUserName: (id: string) => `reportUserName-${id}`,
  reportTime: (id: string) => `reportTime-${id}`,
  itemName: (id: string) => `itemName-${id}`,
  itemText: (id: string) => `itemText-${id}`,
};

export const LogItem = ({ reportedEvent }: LogItemProps) => {
  const { id, eventDefId, flowExecutionId, userId, reportedValue } = reportedEvent;
  const event = useUiEventStore((state) => state.uiEvents[eventDefId]);
  const execution = useFlowStore((state) => state.executions[flowExecutionId]);
  const reportedUser = execution?.workedOn.find((user) => user.userId === userId);
  const creationTime = dayjs(reportedEvent.creationDateClientTime).format('HH:mm');
  const { Icon, name, value, removed, error } = useLogItemProps(event, reportedValue);

  return (
    <>
      <Flex gap='xs' align='start' p='xs' data-testid={testIds.itemContainer(id)}>
        <Icon data-testid={testIds.itemIcon(id)} />
        <Flex direction='column' flex={1}>
          <Flex flex={1} justify='space-between'>
            <Text c='blue' data-testid={testIds.reportUserName(id)}>
              {`${reportedUser?.givenName} ${reportedUser?.familyName}`}
            </Text>
            <Text fw={500} data-testid={testIds.reportTime(id)}>
              {creationTime}
            </Text>
          </Flex>
          <Box>
            <Text span c={error ? 'red' : undefined} fw={500} data-testid={testIds.itemName(id)}>
              {name}
            </Text>
            <Text span c={removed ? 'red' : undefined} data-testid={testIds.itemText(id)}>
              {value}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Divider />
    </>
  );
};
