import { ContainerId } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { getContainerValidations, useUiEventStore } from 'stores/uiEvent';
import { useFocusData } from 'stores/focus';
import { useReportStore } from 'stores/report';
import { exists } from 'utils';
import { useContainerStore } from './container.store';
import { FilteredContainer, UIContainer } from './container.types';
import { countFilteredRelatedContainers } from './container.utils';

export const useContainerEvents = () => {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  return (container: UIContainer) =>
    container.uiEvents?.eventIds
      ?.map((eventId) => uiEvents[eventId])
      .filter(exists)
      .sort((ev1, ev2) => ev1.row - ev2.row) ?? [];
};

export const useFilteredContainers = (containerIds: string[]): FilteredContainer[] => {
  const { reports } = useReportStore(['reports']);
  const { containers, containerTemplates } = useContainerStore(['containers', 'containerTemplates']);
  const { filterValues, uiEvents, validations } = useUiEventStore(['filterValues', 'uiEvents', 'validations']);

  return useMemo(
    () =>
      containerIds
        .map((containerId) => {
          const container = containers[containerId];
          if (!exists(container)) return undefined;
          const containerUiEvents = container.uiEvents?.eventIds?.map((eventId) => uiEvents[eventId]) ?? [];
          const containerValidations = getContainerValidations(containerTemplates[container.templateId], validations);
          const filteredContainersAmount = countFilteredRelatedContainers({
            containers,
            container,
            filterValues,
            containerUiEvents,
            uiEvents,
            containerValidations,
            containerTemplates,
            validations,
            reports,
          });
          return { container, filteredContainersAmount };
        })
        .filter((item) => !!item?.filteredContainersAmount) as FilteredContainer[],
    [containers, reports, filterValues, containerIds, uiEvents, validations, containerTemplates],
  );
};

export function useContainerById(containerId?: string) {
  return useContainerStore((state) => (containerId ? state.containers[containerId] : undefined));
}

export const useFocusedContainer = () => {
  const { focusedContainerId } = useFocusData();
  return useContainerById(focusedContainerId);
};

export function useContainerTemplate(containerId: ContainerId) {
  const container = useContainerById(containerId);
  return useContainerStore((state) => state.containerTemplates[container?.templateId ?? '']);
}
