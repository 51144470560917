import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FlowItem } from 'pages/FlowsPage/components/FlowItem/FlowItem';
import { ExecutionItem } from 'pages/FlowsPage/components/ExecutionItem/ExecutionItem';
import { FlowCollection, useFlowStore } from 'stores/flow';
import { EmptyState } from 'components';

interface FlowListProps {
  collection: FlowCollection;
  onClickFlowItem: (flowId: string, flowVersion: string) => void;
  onClickExecutionItem: (executionId: string) => void;
}

export const testIds = {
  getListTestId: (title: string) => `flow-list-${title}`,
};

export const FlowList = ({ collection: flowSection, onClickFlowItem, onClickExecutionItem }: FlowListProps) => {
  const { t } = useTranslation();
  const handleClickFlowItem = (flowId: string, version: string) => () => onClickFlowItem(flowId, version);
  const handleClickExecutionItem = (executionId: string) => () => onClickExecutionItem(executionId);
  const getFlowById = useFlowStore((state) => state.getFlowById);
  const hasItems = !!flowSection.flows?.length || !!flowSection.executions?.length;

  return (
    <Flex direction='column' gap='tn' pt='sm' pb='xl' data-testid={testIds.getListTestId(flowSection.title)}>
      <EmptyState
        visible={!hasItems}
        highlight={t('flows.sections.noFlows.highlightedPhrase', { adj: flowSection.title })}
        highlightColor='green'
      >
        {t('flows.sections.noFlows.phrase', { adj: flowSection.title })}
      </EmptyState>
      {flowSection.flows?.map((flow) => (
        <FlowItem key={flow.id} flow={flow} onClick={handleClickFlowItem(flow.id, flow.activeVersion)} />
      ))}
      {flowSection.executions?.map((execution) => (
        <ExecutionItem
          key={execution.id}
          execution={execution}
          title={getFlowById(execution.flowRef.id)?.name}
          onClick={handleClickExecutionItem(execution.id)}
        />
      ))}
    </Flex>
  );
};
