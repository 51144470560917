import { Divider, Drawer, FileButton, Flex, Text } from '@mantine/core';
import { IconCamera, IconFolder } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import classes from './ImageUploadDrawer.module.css';

interface ImageUploadDrawerProps {
  opened: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
}

export const testIds = {
  drawer: 'image-upload-drawer',
  camera: {
    input: 'image-upload-camera-input',
    button: 'image-upload-camera-button',
  },
  upload: {
    input: 'image-upload-upload-input',
    button: 'image-upload-upload-button',
  },
};

export const ImageUploadDrawer = ({ opened, onClose, onUpload }: ImageUploadDrawerProps) => {
  const { t } = useTranslation();

  const onChange = (file: File | null) => {
    if (!file) return;
    onUpload?.(file);
    onClose();
  };

  return (
    <Drawer
      classNames={classes}
      size={230}
      position='bottom'
      opened={opened}
      title={t('flows.images.addPhoto')}
      withCloseButton={false}
      onClose={onClose}
      data-testid={testIds.drawer}
    >
      <FileButton
        accept='image/*'
        capture
        onChange={onChange}
        // @ts-ignore
        inputProps={{ 'data-testid': testIds.camera.input }}
      >
        {(props) => (
          <Flex gap='lg' py='lg' px='sm' w='100%' align='center' data-testid={testIds.camera.button} {...props}>
            <IconCamera size={24} strokeWidth={1.6} />
            <Text size='lg'>{t('common.camera')}</Text>
          </Flex>
        )}
      </FileButton>
      <Divider />
      <FileButton
        accept='image/*'
        onChange={onChange}
        // @ts-ignore
        inputProps={{ 'data-testid': testIds.upload.input }}
      >
        {(props) => (
          <Flex py='lg' px='sm' gap='lg' w='100%' align='center' data-testid={testIds.upload.button} {...props}>
            <IconFolder size={24} strokeWidth={1.6} />
            <Text size='lg'>{t('flows.images.browse')}</Text>
          </Flex>
        )}
      </FileButton>
    </Drawer>
  );
};
