import { useCallback, useEffect, useRef } from 'react';
import { config } from 'services/config';
import { syncPendingBiEvents } from 'services/espionage';
import { useOnline } from 'stores/network';

export const useSyncBiEvents = () => {
  const online = useOnline();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSync = useCallback(async () => {
    if (online) await syncPendingBiEvents();
    timeoutRef.current = setTimeout(handleSync, config.biEventsSyncInterval);
  }, [online]);

  useEffect(() => {
    handleSync();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [handleSync]);
};
