import { modals } from '@mantine/modals';
import { MantineColor } from '@mantine/core';
// eslint-disable-next-line import/no-unresolved
import { ModalSettings, OpenConfirmModal } from '@mantine/modals/lib/context';
import { ModalProps } from './modalManager.types';
import { DefaultModalContent } from './DefaultModalContent';

type ModalDefaultProps = Pick<
  OpenConfirmModal,
  'centered' | 'groupProps' | 'cancelProps' | 'confirmProps' | 'closeButtonProps' | 'styles'
>;

export function getDefaultModalProps(confirmColor: MantineColor): ModalDefaultProps {
  return {
    centered: true,
    groupProps: {
      gap: 0,
      grow: true,
      mx: 'auto',
      style: { flexDirection: 'column-reverse' },
    },
    cancelProps: {
      variant: 'subtle',
      size: 'md',
      miw: 210,
      maw: '100%',
    },
    confirmProps: {
      variant: 'gradient',
      gradient: { from: `${confirmColor}.3`, to: `${confirmColor}.7` },
      size: 'md',
      miw: 210,
      maw: '100%',
    },
    closeButtonProps: { size: 'md' },
    styles: {
      header: { paddingBottom: 0 },
      close: { color: 'black' },
    },
  };
}

export const MODAL_TEST_ID = 'aiola-modal';

export class ModalManager {
  warning(props: ModalProps) {
    const { icon: Icon, title, message, id: modalId, ...rest } = props;
    modals.openConfirmModal({
      ...getDefaultModalProps('red'),
      modalId,
      children: <DefaultModalContent Icon={Icon} color='red' title={title} message={message} />,
      ...rest,
    });
  }

  info(props: ModalProps) {
    const { icon: Icon, title, message, id: modalId, ...rest } = props;
    modals.openConfirmModal({
      ...getDefaultModalProps('blue'),
      modalId,
      children: <DefaultModalContent Icon={Icon} color='blue' title={title} message={message} />,
      ...rest,
    });
  }

  custom(props: ModalSettings) {
    modals.open(props);
  }

  close(id: string) {
    modals.close(id);
  }
}
