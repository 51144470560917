import { notifications } from '@mantine/notifications';
import { IconExclamationMark } from '@tabler/icons-react';
import { Text } from '@mantine/core';
import { Bread, Toast } from './toaster.types';

export const testIds = {
  toast: 'toast',
  error: {
    title: 'toast-error-title',
    icon: 'toast-error-icon',
  },
};

export class Toaster {
  error(toast: Toast) {
    notifications.show({
      ...toast,
      color: 'red.9',
      icon: <IconExclamationMark data-testid={testIds.error.icon} />,
      title: (
        <Text size='md' fw={600} c='red.9' data-testid={testIds.error.title}>
          {toast.title}
        </Text>
      ),
    });
  }

  success(_toast: Toast) {
    throw new Error('Method not implemented.');
  }

  info(_toast: Toast) {
    throw new Error('Method not implemented.');
  }

  warning(_toast: Toast) {
    throw new Error('Method not implemented.');
  }

  '🍞'(bread: Bread) {
    notifications.show({ ...bread });
  }

  reheat(toast: Toast & { id: string }) {
    notifications.update(toast);
  }

  dispose(id: string) {
    notifications.hide(id);
  }

  clear() {
    notifications.clean();
  }
}
