import { useEffect } from 'react';
import { useSpy } from './espionage.spy';
import { names } from './espionage.names';

export function usePageviewSpy(pageName: string) {
  const spy = useSpy();

  useEffect(() => {
    const page_url = window.location.href;
    spy(names.PageView.LoadingComplete, pageName, { page_url });
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.name === 'first-contentful-paint') spy(names.PageView.RenderingComplete, pageName, { page_url });
      });
    });

    observer.observe({ type: 'paint', buffered: true });
  }, []);
}
