import { Modal, Flex } from '@mantine/core';
import { useDynamicModalStore } from 'stores/dynamicModal';
import { ModalHeader } from './ModalHeader';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';

export const testIds = {
  container: 'dynamic-modal-container',
  header: {
    title: 'dynamic-modal-title',
    xButton: 'dynamic-modal-xButton',
  },
  body: {
    content: 'dynamic-modal-content',
  },
  footer: {},
};

export const DynamicModal = () => {
  const { isOpen, modalLayout, closeDynamicModal } = useDynamicModalStore([
    'isOpen',
    'modalLayout',
    'closeDynamicModal',
  ]);

  return (
    <Modal
      centered
      padding={0}
      withCloseButton={false}
      closeOnClickOutside={false}
      opened={isOpen}
      onClose={closeDynamicModal}
    >
      <Flex direction='column' data-testid={testIds.container}>
        {modalLayout?.header && <ModalHeader data={modalLayout.header} />}
        {modalLayout?.body && <ModalBody data={modalLayout.body} />}
        {modalLayout?.footer && <ModalFooter data={modalLayout.footer} />}
      </Flex>
    </Modal>
  );
};
