import mixpanel from 'mixpanel-browser';
import { config } from 'services/config';

const { mixpanelToken } = config;

mixpanel.init(mixpanelToken, {
  persistence: 'localStorage',
  disable_cookie: true,
});

export default mixpanel;
