import { AudioFrame, Recorder } from 'services/recorder';
import { Hermes, HermesSocket } from '@aiola/frontend';
import { AudioMetadata, ClientEvents, ReportingContext, ServerEvents, VoicePacket } from './audioSocket.types';

export class AudioSocket {
  private static instance: AudioSocket;
  private _context?: ReportingContext;
  private socket?: HermesSocket<ServerEvents, ClientEvents>;
  private recorder?: Recorder;

  onTranscript?: (transcript: string) => void;
  onError?: (error: string) => void;

  private constructor() {}

  public static getInstance(): AudioSocket {
    if (!AudioSocket.instance) AudioSocket.instance = new AudioSocket();
    return AudioSocket.instance;
  }

  private onConnect() {
    console.info('🎙️ Audio websocket connected! 📢');
  }

  private onDisconnect() {
    console.info('🎙️ Audio websocket disconnected! 👋');
  }

  async connectWebSocket({ uri, path, query }: { uri: string; path: string; query: Object }): Promise<HermesSocket> {
    if (this.socket && this.socket.connected) return this.socket;

    const socket = Hermes.socket<ServerEvents, ClientEvents>(uri, path, { query });
    socket.on('connect', this.onConnect);
    socket.on('disconnect', this.onDisconnect);
    socket.on('error', console.error);
    socket.on('connect_error', (error) => this.onError?.(error.toString()));
    socket.on('transcript', ({ transcript }) => this.onTranscript?.(transcript));
    this.socket = socket;

    return socket;
  }

  private async onAudioFrame(audioFrame: AudioFrame) {
    if (!this.socket?.connected) return;
    const packet: VoicePacket = {
      id: audioFrame.bufferId,
      audioFrame: audioFrame.buffer,
      timestamp: audioFrame.timestamp,
    };
    if (this._context) {
      this.socket.emit('binary_data_with_context', {
        ...packet,
        ...this._context,
      });
    } else {
      this.socket.emit('binary_data', packet);
    }
  }

  async startRecording(): Promise<void> {
    if (!this.socket) return;

    this.recorder = Recorder.getInstance();
    await this.recorder.start(this.onAudioFrame.bind(this));
  }

  async stopRecording(): Promise<void> {
    await this.recorder?.stop();
  }

  async disconnectWebSocket(): Promise<void> {
    await this.recorder?.stop();

    return new Promise((resolve) => {
      if (this.socket && this.socket.connected) {
        this.socket.on('disconnect', () => {
          this.onDisconnect();
          resolve();
        });
        this.socket.close();
      } else {
        resolve(); // Resolve immediately if there is no WebSocket connection to close.
      }
    });
  }

  set metadata(metadata: Partial<AudioMetadata>) {
    this.socket?.emit('metadata_update', metadata);
  }

  set context(context: ReportingContext | undefined) {
    this._context = context;
  }
}
